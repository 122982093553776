import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from 'reactstrap';

import Header from 'components/Headers/AlternativeHeader';
import AppInput from 'components/Forms/AppInput';

function AppEnergySupplierList({
  isLoading,
  energySuppliers,
  count,
  page,
  itemsPerPage,
  onSearch,
  onPageClick,
  onEnergySupplierClick,
  onDelete,
  onNewClick
}) {
  const [value, setValue] = useState('');
  const [customFilter, setCustomFilter] = useState('');

  const renderPagination = () => {
    const actualPage = page + 1;
    const totalPages =
      count > 0 && itemsPerPage > 0 ? Math.ceil(count / itemsPerPage) : 0;
    if (totalPages === 0) return null;

    const hasPrev = page > 0;
    const hasNext = actualPage < totalPages;
    const totalNextPages = totalPages - actualPage;
    let numbers = [];

    for (let i = 0, len = totalNextPages + 1; i < len; i++) {
      numbers.push(
        <PaginationItem
          key={`pagination-number-${actualPage + i}`}
          className={i === 0 ? 'active' : ''}
        >
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              onPageClick(page + i, value, customFilter);
            }}
          >
            {actualPage + i}
          </PaginationLink>
        </PaginationItem>
      );

      if (i === 2) break;
    }

    return (
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={hasPrev ? '' : 'disabled'}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasPrev) {
                onPageClick(page - 1, value);
              }
            }}
            tabIndex="-1"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
        {numbers}
        <PaginationItem className={hasNext ? '' : 'disabled'}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasNext) {
                onPageClick(page + 1, value);
              }
            }}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  };

  const renderEnergySuppliers = () => {
    return energySuppliers.map((c, uIndex) => (
      <tr key={`energySuppliers-${uIndex}`}>
        <td className="table-energySupplier">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onEnergySupplierClick(c._id, true);
            }}
          >
            <b>{c.name}</b>
          </a>
        </td>
        <td>{c.email}</td>
        <td>{c.phone}</td>
        <td className="table-actions">
          <a
            className="table-action"
            href="#"
            id="tooltip209424781"
            onClick={(e) => {
              e.preventDefault();
              onEnergySupplierClick(c._id, false);
            }}
          >
            <i className="fas fa-user-edit" />
          </a>
          <UncontrolledTooltip delay={0} target="tooltip209424781">
            Editar Suplidora
          </UncontrolledTooltip>
          <a
            className="table-action table-action-delete"
            href="#"
            id="tooltip12475020"
            onClick={(e) => {
              e.preventDefault();
              onDelete(c._id);
            }}
          >
            <i className="fas fa-trash" />
          </a>
          <UncontrolledTooltip delay={0} target="tooltip12475020">
            Eliminar Suplidora
          </UncontrolledTooltip>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Header name="Suplidoras de energía" onNewClick={onNewClick} />

      <Container fluid className="mt--6">
        <Row>
          <div className="col">
            <Card className="mb-0">
              <Row className="px-3 pt-3">
                <Col md="12" lg="4">
                  <AppInput
                    label="Buscar"
                    type="search"
                    placeholder="Nombre, correo, teléfono..."
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value);
                      onSearch(e.target.value, customFilter);
                    }}
                  />
                </Col>
              </Row>

              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    marginTop: '30px',
                    marginBottom: '30px'
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Spinner />
                </div>
              ) : energySuppliers.length > 0 ? (
                <>
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    striped
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Nombre</th>
                        <th>Correo</th>
                        <th>Teléfono</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>{renderEnergySuppliers()}</tbody>
                  </Table>

                  <CardFooter className="py-4">
                    <nav aria-label="...">{renderPagination()}</nav>
                  </CardFooter>
                </>
              ) : (
                <CardBody className="text-center">
                  <p className="text-muted">No existen suplidoras de energía</p>
                </CardBody>
              )}
            </Card>

            <div className="my-3">
              <small>Total de suplidoras de energía {count}</small>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default AppEnergySupplierList;
