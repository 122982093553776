import React from 'react';

import { Spinner, Table } from 'reactstrap';

import { getName, displayAmount } from 'utils';

function AppARList({ accountsReceivable, onItemClick, isLoading }) {
  const renderAccountsReceivable = () => {
    return accountsReceivable.map((aR, uIndex) => (
      <tr key={`accounts-receivable-${uIndex}`}>
        <td>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();

              onItemClick(aR._id);
            }}
          >
            <b>{displayAmount(aR.balance ? aR.balance / 100 : aR.balance)}</b>
          </a>
        </td>
        <td className="table-customer">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onItemClick(aR._id);
            }}
          >
            <b>{getName(aR.customer)}</b>
          </a>
        </td>
        <td>{aR.customer.alias}</td>
        <td>{aR.customer.responsible}</td>
      </tr>
    ));
  };

  return !isLoading ? (
    <Table
      className="align-items-center table-flush table--records"
      responsive
      striped
    >
      <thead className="thead-light">
        <tr>
          <th>Balance</th>
          <th>Cliente</th>
          <th>Alias</th>
          <th>Responsable</th>
        </tr>
      </thead>
      <tbody>{renderAccountsReceivable()}</tbody>
    </Table>
  ) : (
    <div
      style={{
        width: '100%',
        marginTop: '30px',
        marginBottom: '30px'
      }}
      className="d-flex justify-content-center align-items-center"
    >
      <Spinner />
    </div>
  );
}

export default AppARList;
