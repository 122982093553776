import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col
} from 'reactstrap';

import { moment } from 'utils';
import AlternativeHeader from 'components/Headers/AlternativeHeader';
import AppInput from 'components/Forms/AppInput';
import AppCurrencyInput from 'components/Forms/AppCurrencyInput';
import { ProductKind, ProductCategory } from '../../constants';
import AppCheckbox from 'components/Forms/AppCheckbox';
import usePrevious from 'hooks/use-previous';
import AppSerials from 'components/Serials/AppSerials';

const baseProduct = {
  name: '',
  sku: '',
  category: ProductCategory.None,
  kind: ProductKind.Inventory,

  saleable: true,
  price: '',
  taxeable: false,
  description: '',

  purchaseable: true,
  // preferredSupplier: PopulatedDoc<SupplierDocument & Document>;
  purchasingDescription: '',
  purchasingPrice: '',
  purchasingTaxeable: false,

  reorderPoint: '',
  stock: 0,
  initialStock: '',
  initialStockAsOfDate: '',

  serializable: false,

  weight: '0.00',
  weightUnit: '',
  length: '0.00',
  lengtUnit: '',
  volume: '0.00',
  volumeUnit: '',

  brand: '',
  model: '',
  capacity: ''
};

function AppProductForm({ product, onSave, onClose }) {
  const [form, setForm] = useState({ ...baseProduct });
  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);

  const prevForm = usePrevious(form);

  useEffect(() => {
    if (!prevForm) return;

    if (
      form.kind !== ProductKind.Inventory &&
      prevForm.purchaseable === true &&
      form.purchaseable === false &&
      form.saleable === false
    ) {
      setForm({ ...form, saleable: true });
    }

    if (
      form.kind !== ProductKind.Inventory &&
      prevForm.saleable === true &&
      form.saleable === false &&
      form.purchaseable === false
    ) {
      setForm({ ...form, purchaseable: true });
    }

    if (
      prevForm.kind !== ProductKind.Inventory &&
      form.kind === ProductKind.Inventory
    ) {
      setForm({ ...form, purchaseable: true, saleable: true });
    }
  }, [form]);

  useEffect(() => {
    if (product._id)
      setForm({
        ...product,
        initialStockAsOfDate: product.initialStockAsOfDate
          ? moment(product.initialStockAsOfDate).format('YYYY-MM-DD')
          : ''
      });
  }, []);

  const handleChange = (e) => {
    let newValue = e.target.value;
    let newName;

    if (e.target.type === 'checkbox') {
      newValue = !form[e.target.name];
    } else if (e.target.name === 'sku') {
      newValue = e.target.value.toUpperCase();
    } else if (e.target.name === 'brand') {
      newName = `${newValue} ${form.model || ""} ${`${form.capacity}` + (form.category === ProductCategory.SolarPanels ? "W" : "kW")}`.trim();
    } else if (e.target.name === 'model') {
      newName = `${form.brand} ${newValue || ""} ${`${form.capacity}` + (form.category === ProductCategory.SolarPanels ? "W" : "kW")}`.trim();
    } else if (e.target.name === 'capacity') {
      newName = `${form.brand} ${form.model || ""} ${`${newValue}` + (form.category === ProductCategory.SolarPanels ? "W" : "kW")}`.trim();
    }

    setForm({
      ...form,
      name: newName || form.name,
      [e.target.name]: newValue
    });
  };

  const handleSubmit = async () => {
    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    if (!form.name) {
      newErrors.name = 'Es obligatorio';
    }

    if (form.kind === ProductKind.Inventory) {
      if (form.initialStock === '') {
        newErrors.initialStock = 'Es obligatorio';
      }

      if (form.initialStockAsOfDate === '') {
        newErrors.initialStockAsOfDate = 'Es obligatorio';
      }
    }

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    let tmpForm = {};

    if (product._id) {
      tmpForm = { ...product };
    }

    tmpForm = {
      ...tmpForm,
      ...form,
      price: form.price * 100,
      purchasingPrice: form.purchasingPrice * 100,
      sku: !form.sku ? null : form.sku,
      initialStockAsOfDate: form.initialStockAsOfDate
        ? moment(form.initialStockAsOfDate)
        : ''
    };

    try {
      await onSave(tmpForm);
    } catch (e) {
      setIsSending(false);
    }
  };

  return (
    <>
      <AlternativeHeader
        name={form.name || 'Nuevo producto'}
        parentName="Productos"
        onBackClick={onClose}
      />

      <Container className="mt--6">
        <Row>
          <Col xl={product._id ? 8 : 12}>
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {product._id ? 'Editar' : 'Crear'} producto/servicio
                    </h3>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Form>
                  <Row>
                    <Col sm="6">
                      <AppInput
                        label="Nombre *"
                        id="name"
                        name="name"
                        type="text"
                        disabled={
                          form.category === ProductCategory.SolarPanels ||
                          form.category === ProductCategory.Inverters
                        }
                        value={form.name || ''}
                        error={errors.name}
                        required
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col sm="6">
                      <AppInput
                        label="SKU"
                        id="sku"
                        name="sku"
                        type="text"
                        value={form.sku || ''}
                        error={errors.sku}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col lg="6">
                      <AppInput
                        label="Tipo"
                        id="kind"
                        name="kind"
                        type="select"
                        value={form.kind}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      >
                        {Object.entries(ProductKind).map(([_, v]) => (
                          <option
                            key={`kind-${v}`}
                            value={v}
                            defaultChecked={form.kind === v}
                          >
                            {v}
                          </option>
                        ))}
                      </AppInput>
                    </Col>

                    <Col lg="6">
                      <AppInput
                        label="Categoría"
                        id="category"
                        name="category"
                        type="select"
                        value={form.category}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      >
                        {Object.entries(ProductCategory).map(([_, v]) => (
                          <option
                            key={`category-${v}`}
                            value={v}
                            defaultChecked={form.category === v}
                          >
                            {v}
                          </option>
                        ))}
                      </AppInput>
                    </Col>

                    {form.category === ProductCategory.SolarPanels ||
                    form.category === ProductCategory.Inverters ? (
                      <>
                        <Col sm="6">
                          <AppInput
                            label="Marca"
                            id="brand"
                            name="brand"
                            type="text"
                            value={form.brand || ''}
                            error={errors.brand}
                            required
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => handleChange(e)}
                          />
                        </Col>

                        <Col sm="6">
                          <AppInput
                            label="Modelo"
                            id="model"
                            name="model"
                            type="text"
                            value={form.model || ''}
                            error={errors.model}
                            required
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => handleChange(e)}
                          />
                        </Col>

                        <Col sm="6">
                          <AppInput
                            label={`${(form.category === ProductCategory.SolarPanels ? "Potencia (W)" : "Capacidad (kW)")}`}
                            id="capacity"
                            name="capacity"
                            type="number"
                            value={form.capacity || ''}
                            error={errors.capacity}
                            required
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => handleChange(e)}
                          />
                        </Col>
                      </>
                    ) : null}

                    {form.kind === ProductKind.Inventory ? (
                      <Col lg="12">
                        <AppCheckbox
                          label="Tiene Seriales"
                          id="serializable"
                          name="serializable"
                          checked={form.serializable}
                          onChange={(e) => handleChange(e)}
                        ></AppCheckbox>
                      </Col>
                    ) : null}
                  </Row>

                  {form.kind === ProductKind.Inventory ? (
                    <>
                      <hr className="my-4" />

                      <Row>
                        <Col sm="4">
                          <AppInput
                            label="Cantidad en mano *"
                            id="initialStock"
                            name="initialStock"
                            type="number"
                            value={form.initialStock}
                            error={errors.initialStock}
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => handleChange(e)}
                          />
                        </Col>

                        <Col sm="4">
                          <AppInput
                            label="A partir de la fecha *"
                            id="initialStockAsOfDate"
                            name="initialStockAsOfDate"
                            type="date"
                            value={form.initialStockAsOfDate}
                            error={errors.initialStockAsOfDate}
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => handleChange(e)}
                          />
                        </Col>

                        <Col lg="4">
                          <AppInput
                            label="Punto de reorden"
                            id="reorderPoint"
                            name="reorderPoint"
                            type="number"
                            value={form.reorderPoint || ''}
                            error={errors.reorderPoint}
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => handleChange(e)}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  <hr className="my-4" />

                  <Row>
                    <Col md="6">
                      <h6 className="heading-small text-muted mb-3">
                        Descripción
                      </h6>

                      {form.kind !== ProductKind.Inventory ? (
                        <Row>
                          <Col md="12">
                            <AppCheckbox
                              label="Le vendo este producto/servicio a mis clientes."
                              id="saleable"
                              name="saleable"
                              checked={form.saleable}
                              onChange={(e) => handleChange(e)}
                            ></AppCheckbox>
                          </Col>
                        </Row>
                      ) : null}

                      {form.kind === ProductKind.Inventory || form.saleable ? (
                        <>
                          <AppInput
                            placeholder="Descripción de venta"
                            id="description"
                            name="description"
                            type="text"
                            value={form.description}
                            error={errors.description}
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => handleChange(e)}
                          />

                          <AppCurrencyInput
                            label="Precio"
                            id="price"
                            name="price"
                            placeholder="RD$0.00"
                            step={1}
                            decimalScale={2}
                            value={form.price}
                            error={errors.price}
                            onValueChange={(value, name) =>
                              handleChange({ target: { value, name } })
                            }
                          />

                          <AppCheckbox
                            label="Incluir impuesto"
                            id="taxeable"
                            name="taxeable"
                            checked={form.taxeable}
                            onChange={(e) => handleChange(e)}
                          ></AppCheckbox>
                        </>
                      ) : null}
                    </Col>

                    <Col md="6">
                      <h6 className="heading-small text-muted mb-3">
                        Información de compra
                      </h6>

                      {form.kind !== ProductKind.Inventory ? (
                        <AppCheckbox
                          label="Compro este producto/servicio a un suplidor."
                          id="purchaseable"
                          name="purchaseable"
                          checked={form.purchaseable}
                          onChange={(e) => handleChange(e)}
                        ></AppCheckbox>
                      ) : null}

                      {form.kind === ProductKind.Inventory ||
                      form.purchaseable ? (
                        <>
                          <AppInput
                            placeholder="Descripción de compra"
                            id="purchasingDescription"
                            name="purchasingDescription"
                            type="text"
                            value={form.purchasingDescription}
                            error={errors.purchasingDescription}
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => handleChange(e)}
                          />

                          <AppCurrencyInput
                            label="Precio de compra"
                            id="purchasingPrice"
                            name="purchasingPrice"
                            placeholder="RD$0.00"
                            step={1}
                            decimalScale={2}
                            value={form.purchasingPrice}
                            error={errors.purchasingPrice}
                            onValueChange={(value, name) =>
                              handleChange({ target: { value, name } })
                            }
                          />

                          <AppCheckbox
                            label="Incluir impuesto"
                            id="purchasingTaxeable"
                            name="purchasingTaxeable"
                            checked={form.purchasingTaxeable}
                            onChange={(e) => handleChange(e)}
                          ></AppCheckbox>
                        </>
                      ) : null}
                    </Col>
                  </Row>

                  <Button
                    color="secondary"
                    size="sm"
                    type="button"
                    onClick={onClose}
                  >
                    Cancelar
                  </Button>

                  <Button
                    color="primary"
                    size="sm"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>

          {product._id &&
          product.serializable &&
          product.kind === ProductKind.Inventory ? (
            <Col xl="4">
              <AppSerials productId={product._id} />
            </Col>
          ) : null}
        </Row>
      </Container>
    </>
  );
}

export default AppProductForm;
