import React, { useEffect, useState, useCallback } from 'react';
import moment from 'utils/moment';
import debounce from 'lodash.debounce';
import groupBy from 'lodash.groupby';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from 'reactstrap';

import AccountsReceivableAPI from 'services/api/accountReceivable';
import { getName, displayAmount } from 'utils';
import { TransactionKind } from './../../constants';
import Header from 'components/Headers/AlternativeHeader';
import AppARList from 'components/AccountsReceivable/AppARList';
import AppInput from 'components/Forms/AppInput';

const itemsPerPage = 10;

function AccountsReceivable() {
  const location = useLocation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
  const [displayFilters, setDisplayFilter] = useState(false);
  const [accountsReceivable, setAccountsReceivable] = useState([]);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(location.search).get('page')) - 1 || 0
  );
  const [count, setCount] = useState(0);

  const [transactions, setTransactions] = useState([]);

  const [value, setValue] = useState('');

  const [customFilter, setCustomFilter] = useState('');

  const [selectedDate, setSelectedDate] = useState(moment().startOf('month'));

  useEffect(() => {
    const vParam = new URLSearchParams(location.search).get('v');
    const startDateParam = new URLSearchParams(location.search).get(
      'startDate'
    );
    const endDateParam = new URLSearchParams(location.search).get('endDate');
    const customParam = new URLSearchParams(location.search).get('custom');

    if (vParam) {
      setValue(vParam);
    }

    if (startDateParam) {
      setStartDate(startDateParam);
    }

    if (endDateParam) {
      setEndDate(endDateParam);
    }

    if (customParam) {
      setCustomFilter(customParam);
    }
  }, []);

  useEffect(() => {
    debouncedChangeHandler(value, page, startDate, endDate, customFilter);
  }, [value, page, startDate, endDate, customFilter]);

  useEffect(() => fetchTransactions(), []);

  const debouncedChangeHandler = useCallback(
    debounce(async (value, page, startDate, endDate, customFilter) => {
      try {
        const { accountsReceivable, count } =
          await AccountsReceivableAPI.getAll(
            page,
            itemsPerPage,
            value,
            startDate,
            endDate,
            customFilter
          );
        setAccountsReceivable(accountsReceivable);
        setIsLoading(false);
        setCount(count);
      } catch (e) {
        console.log(e);
      }
    }, 100),
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  const fetchTransactions = async () => {
    setIsLoadingTransactions(true);
    try {
      setTransactions(await AccountsReceivableAPI.getAllTransactions());
      setIsLoadingTransactions(false);
    } catch (e) {
      setIsLoadingTransactions(false);
      console.log(e);
    }
  };

  const handleSearch = (e) => {
    const params = new URLSearchParams(location.search);
    params.delete('v');
    params.append('v', e.target.value);
    history.push({ search: params.toString() });
    setValue(e.target.value);
    setPage(0);
  };

  const handlePageClick = (pageToGo) => {
    const params = new URLSearchParams(location.search);
    params.delete('p');
    params.append('p', pageToGo);
    history.push({ search: params.toString() });
    setPage(pageToGo);
  };

  const handleItemClick = async (id) => {
    try {
      history.push(`/cuentas-por-cobrar/${id}`);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const getAllMilestones = useCallback(() => {
    if (!transactions) return [];
    return transactions
      .reduce(
        (a, b) =>
          b.milestones && b.milestones.length > 0
            ? [
                ...a,
                ...b.milestones
                  .filter((m) => !m.completed)
                  .map((m) => ({ ...m, invoice: b }))
              ]
            : a,
        []
      )
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [transactions]);

  const getExpiredMilestones = useCallback(() => {
    return getAllMilestones().filter((i) =>
      moment(i.date).startOf('day').isBefore(moment().startOf('day'))
    );
  }, [getAllMilestones]);

  const getNextsMilestones = useCallback(() => {
    return getAllMilestones().filter(
      (i) =>
        moment(i.date).isSameOrAfter(moment(selectedDate)) &&
        moment(i.date).isSameOrAfter(moment().startOf('day')) &&
        moment(i.date).isSameOrBefore(moment(selectedDate).endOf('month'))
    );
  }, [getAllMilestones, selectedDate]);

  const getGeneralPendingBalance = () => {
    let amount = 0.0;

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (transactions[i].kind === TransactionKind.Invoice) {
        amount -= transactions[i].total / 100;
      } else {
        amount += transactions[i].total / 100;
      }
    }

    return amount;
  };

  const getTotalInvoiced = () => {
    let amount = 0.0;

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (transactions[i].kind === TransactionKind.Invoice) {
        amount += transactions[i].total / 100;
      }
    }

    return amount;
  };

  const nextMonth = () => {
    const newDate = moment(selectedDate).add('1', 'month').startOf('month');
    setSelectedDate(newDate);
  };

  const prevMonth = () => {
    const newDate = moment(selectedDate)
      .subtract('1', 'month')
      .startOf('month');
    setSelectedDate(newDate);
  };

  const setCurrentMonth = () => {
    setSelectedDate(moment().startOf('month'));
  };

  const displaySelectedDate = () => {
    return selectedDate.format('MMMM YYYY');
  };

  const displayInvoicedTotalChange = () => {
    let amount = 0.0;

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (
        transactions[i].kind === TransactionKind.Invoice &&
        moment(transactions[i].date).isSameOrAfter(moment().startOf('month'))
      ) {
        amount += transactions[i].total / 100;
      }
    }

    if (!amount)
      return (
        <span className="text-gray mr-2">
          <i className="fas fa-minus"></i>
        </span>
      );

    return (
      <span className="text-success mr-2">
        <strong>
          <i className="fa fa-arrow-up" /> {displayAmount(amount)}
        </strong>
      </span>
    );
  };

  const displayBalancePercentageChange = () => {
    let lastAmount = 0.0;
    let amount = 0.0;
    let change = 0.0;
    let difference = 0.0;

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (transactions[i].kind === TransactionKind.Invoice) {
        if (
          moment(transactions[i].date).isSameOrBefore(
            moment().subtract(1, 'month').endOf('month')
          )
        ) {
          lastAmount -= transactions[i].total / 100;
        }

        amount -= transactions[i].total / 100;
      } else {
        if (
          moment(transactions[i].date).isSameOrBefore(
            moment().subtract(1, 'month').endOf('month')
          )
        ) {
          lastAmount += transactions[i].total / 100;
        }

        amount += transactions[i].total / 100;
      }
    }

    difference = amount - lastAmount;
    if (!difference || !lastAmount)
      return (
        <span className="text-gray mr-2">
          <i className="fas fa-minus"></i>
        </span>
      );

    change = (difference / lastAmount) * 100.0;
    return (
      <span className={`text-${change > 0 ? 'danger' : 'success'} mr-2`}>
        <strong>
          <i className={`fa fa-arrow-${change > 0 ? 'up' : 'down'}`} />{' '}
          {change.toFixed(2)}%
        </strong>
      </span>
    );
  };

  const getPrintURL = () => {
    return `${
      process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : ''
    }/api/accounts-receivable?search=${JSON.stringify({
      page,
      itemsPerPage,
      value,
      startDate,
      endDate,
      customFilter,
      isGeneratingReport: true
    })}`;
  };

  const renderPagination = () => {
    const actualPage = page + 1;
    const totalPages =
      count > 0 && itemsPerPage > 0 ? Math.ceil(count / itemsPerPage) : 0;
    if (totalPages === 0) return null;

    const hasPrev = page > 0;
    const hasNext = actualPage < totalPages;
    const totalNextPages = totalPages - actualPage;
    let numbers = [];

    for (let i = 0, len = totalNextPages + 1; i < len; i++) {
      numbers.push(
        <PaginationItem
          key={`pagination-number-${actualPage + i}`}
          className={i === 0 ? 'active' : ''}
        >
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              handlePageClick(page + i);
            }}
          >
            {actualPage + i}
          </PaginationLink>
        </PaginationItem>
      );
      if (i === 2) break;
    }

    return (
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={hasPrev ? '' : 'disabled'}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasPrev) {
                handlePageClick(page - 1, value);
              }
            }}
            tabIndex="-1"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
        {numbers}
        <PaginationItem className={hasNext ? '' : 'disabled'}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasNext) {
                handlePageClick(page + 1, value);
              }
            }}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  };

  return (
    <>
      <Header name="Por cobrar" />

      <Container fluid className="mt--6">
        <Row>
          <Col lg="5" xl="4" className="order-lg-2">
            <Card>
              <CardHeader>
                <h5 className="h3 text-dark mb-0">Vencidos</h5>
              </CardHeader>

              <CardBody>
                {isLoadingTransactions ? (
                  <div
                    style={{
                      width: '100%',
                      marginTop: '30px',
                      marginBottom: '30px'
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Spinner />
                  </div>
                ) : null}

                {!isLoadingTransactions &&
                getExpiredMilestones(getAllMilestones()).length > 0 ? (
                  <ul className="calendar-list">
                    {Object.entries(
                      groupBy(
                        getExpiredMilestones(getAllMilestones()),
                        (milestone) =>
                          milestone.invoice.accountReceivable.customer._id
                      )
                    ).map((m, mIndex) => (
                      <li
                        key={`calendar-payment-pending-${mIndex}`}
                        className="calendar-list-item"
                      >
                        <span className="calendar-list-item__full-date">
                          <strong>{moment(m[1][0].date).format('L')}</strong>
                        </span>

                        <Link
                          id={`calendar-payment-amount-expired-${mIndex}`}
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleItemClick(
                              m[1][0].invoice.accountReceivable._id
                            );
                          }}
                          className="calendar-list-item__name"
                        >
                          {m[1].length > 1 ? (
                            <span>{m[1].length}</span>
                          ) : (
                            ''
                          )}
                          {getName(m[1][0].invoice.accountReceivable.customer)}{' '}
                        </Link>

                        <UncontrolledTooltip
                          delay={0}
                          target={`calendar-payment-amount-expired-${mIndex}`}
                        >
                          {m[1].length > 1
                            ? displayAmount(
                                m[1].reduce((b, a) => b + a.amount, 0)
                              )
                            : displayAmount(m[1][0].amount)}
                        </UncontrolledTooltip>
                      </li>
                    ))}
                  </ul>
                ) : !isLoadingTransactions ? (
                  <p className="text-center text-muted">
                    No existen hitos vencidos
                  </p>
                ) : null}
              </CardBody>
            </Card>

            <Card>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="h3 text-dark mb-0">Próximos</h5>
                </div>

                <div
                  className="calendar-actions_arrows"
                  style={{ fontSize: '16px' }}
                >
                  <button onClick={prevMonth}>
                    <i className="fas fa-arrow-circle-left"></i>
                  </button>
                  <div onClick={setCurrentMonth} style={{ fontSize: '14px' }}>
                    <strong>{displaySelectedDate()}</strong>
                  </div>
                  <button onClick={nextMonth}>
                    <i className="fas fa-arrow-circle-right"></i>
                  </button>
                </div>
              </CardHeader>

              <CardBody>
                {isLoadingTransactions ? (
                  <div
                    style={{
                      width: '100%',
                      marginTop: '30px',
                      marginBottom: '30px'
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Spinner />
                  </div>
                ) : null}

                {!isLoadingTransactions &&
                getNextsMilestones(getAllMilestones()).length > 0 ? (
                  <ul className="calendar-list">
                    {getNextsMilestones(getAllMilestones()).map((m, mIndex) => (
                      <li
                        key={`calendar-payment-${mIndex}`}
                        className="calendar-list-item"
                      >
                        <span className="calendar-list-item__date">
                          <span>{moment(m.date).format('ddd')}</span>{' '}
                          {moment(m.date).format('DD')}
                        </span>

                        <Link
                          id={`calendar-payment-amount-${mIndex}`}
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleItemClick(m.invoice.accountReceivable._id);
                          }}
                        >
                          {getName(m.invoice.accountReceivable.customer)}
                        </Link>

                        <UncontrolledTooltip
                          delay={0}
                          target={`calendar-payment-amount-${mIndex}`}
                        >
                          {displayAmount(m.amount)}
                        </UncontrolledTooltip>
                      </li>
                    ))}
                  </ul>
                ) : !isLoadingTransactions ? (
                  <p className="text-center text-muted">
                    No existen hitos este mes
                  </p>
                ) : null}
              </CardBody>
            </Card>
          </Col>

          <Col className="order-lg-1" lg="7" xl="8">
            <Row>
              <Col md="6" xl="4">
                <Card className="card-stats">
                  <CardBody>
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Balance General
                    </CardTitle>

                    {isLoadingTransactions ? (
                      <div
                        style={{
                          width: '100%',
                          marginTop: '30px',
                          marginBottom: '30px'
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        <span className="h2 font-weight-bold mb-0">
                          {displayAmount(getGeneralPendingBalance())}
                        </span>

                        <p className="mt-3 mb-0 text-sm">
                          {displayBalancePercentageChange()}
                          <br />
                          <span className="text-nowrap">
                            Desde el último mes
                          </span>
                        </p>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="4">
                <Card className="card-stats">
                  <CardBody>
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Total facturado
                    </CardTitle>

                    {isLoadingTransactions ? (
                      <div
                        style={{
                          width: '100%',
                          marginTop: '30px',
                          marginBottom: '30px'
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        <span className="h2 font-weight-bold mb-0">
                          {displayAmount(getTotalInvoiced())}
                        </span>
                        <p className="mt-3 mb-0 text-sm">
                          {displayInvoicedTotalChange()}

                          <br />
                          <span className="text-nowrap">
                            Desde el último mes
                          </span>
                        </p>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Card className="mb-0">
              <Row className="px-3 pt-3">
                <Col md="4">
                  <AppInput
                    label="Buscar"
                    id="name"
                    name="name"
                    type="search"
                    placeholder="nombre, alias, telefono..."
                    value={value}
                    onChange={handleSearch}
                  />
                </Col>

                {displayFilters ? (
                  <Col md="4">
                    <AppInput
                      label="Movimientos desde"
                      id="startDate"
                      name="startDate"
                      type="date"
                      value={startDate}
                      onChange={(e) => {
                        const params = new URLSearchParams(location.search);
                        params.delete('startDate');
                        params.append('startDate', e.target.value);
                        history.push({ search: params.toString() });
                        setStartDate(e.target.value);
                      }}
                    />
                  </Col>
                ) : null}

                <Col md="4">
                  <AppInput
                    label="Movimientos hasta"
                    id="endDate"
                    name="endDate"
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      const params = new URLSearchParams(location.search);
                      params.delete('endDate');
                      params.append('endDate', e.target.value);
                      history.push({ search: params.toString() });
                      setEndDate(e.target.value);
                    }}
                  />
                </Col>

                {displayFilters ? (
                  <Col md="4">
                    <AppInput
                      label="Personalizado"
                      id="customFilter"
                      name="customFilter"
                      type="select"
                      value={customFilter}
                      onChange={(e) => {
                        const params = new URLSearchParams(location.search);
                        params.delete('custom');
                        params.append('custom', e.target.value);
                        history.push({ search: params.toString() });
                        setCustomFilter(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option value="hasPendingBalance">
                        Balance Pendiente
                      </option>
                      <option value="hasNoPendingBalance">
                        Sin Balance Pendiente
                      </option>
                      <option value="hasNoPaymentsAndPendingBalance">
                        Balance pendiente, sin pagos
                      </option>
                    </AppInput>
                  </Col>
                ) : null}

                <Col md="4 d-flex align-items-center">
                  <a
                    href="#"
                    className="text-sm mt-md-0 d-block"
                    onClick={(e) => {
                      e.preventDefault();
                      setDisplayFilter(!displayFilters);
                    }}
                  >
                    {!displayFilters ? 'Más filtros' : 'Ocultar filtros'}
                  </a>
                </Col>
              </Row>

              <AppARList
                isLoading={isLoading}
                accountsReceivable={accountsReceivable}
                onItemClick={handleItemClick}
              />

              <CardFooter className="py-4">
                <nav aria-label="...">{renderPagination()}</nav>
              </CardFooter>
            </Card>

            <div className="my-3 d-flex justify-space-between">
              <small>Total de cuentas {count}</small>
              <small className="ml-auto">
                <a href={getPrintURL()} target="_blank">
                  <i className="fas fa-print"></i> Reporte
                </a>
              </small>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AccountsReceivable;
