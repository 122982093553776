import { formatValue } from 'react-currency-input-field';

import useForm from './useForm';
import validate from './validate';
import moment from './moment';
import { CustomerKind } from '../constants';

const baseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://seerpanel.com'
    : 'http://localhost:5000';

const getName = (model) => {
  if (!model) return '';
  if (model.kind === CustomerKind.Company) return model.name;
  return `${model.firstName || ''} ${model.lastName || ''}`.trim();
};

const formatPhone = (phone) => {
  return (phone || '')
    .replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

const shortenDescription = (str, maxLen, separator = ' ') => {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen));
};

const formatFilename = (filename, folder) => {
  const date = moment().format('YYYYMMDD');
  const randomString = Math.random().toString(36).substring(2, 7);
  const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, '-');
  const newFilename = `${folder}/${date}-${randomString}-${cleanFileName}`;
  return newFilename.substring(0, 60);
};

const getFileObject = (key) => {
  if (!key) return '';

  return `${baseUrl}/api/files/object?key=${key.replace(
    `https://seersolar.s3.amazonaws.com/`,
    ''
  )}`;
};

const parseAmount = (value) => (value / 100).toString();

const displayAmount = (value) => {
  if (!value) {
    return 'RD$ 0.00';
  }

  const amount = value.toFixed(2);

  let total = amount
    .toString()
    .replace(/[^0-9]/g, '')
    .split('');
  total.splice(total.length - 2, 0, '.');
  total = total.join('');

  return formatValue({
    value: total,
    intlConfig: { locale: 'es-DO', currency: 'DOP' },
    prefix: 'RD$ '
  });
};

const getProjectStatusClass = (value) => {
  if (value.includes('PV:')) {
    return 'project-label--blue';
  } else if (value.includes('SV:')) {
    return 'project-label--orange';
  } else if (value.includes('Acuerdo de interconexión:')) {
    return 'project-label--yellow';
  } else if (value.includes('Medidor:')) {
    return 'project-label--purple';
  } else if (value.includes('Arrancado')) {
    return 'project-label--green';
  }
};

const getElectricalUnit = (value) => {
  if (!value) return '';

  if (value >= 1000000) {
    return `${value / 1000000} mW`;
  }

  if (value >= 1000) {
    return `${value / 1000} kW`;
  }

  return `${value} W`;
};

export {
  useForm,
  validate,
  formatPhone,
  isObjectEmpty,
  shortenDescription,
  getName,
  moment,
  formatFilename,
  getFileObject,
  displayAmount,
  parseAmount,
  getProjectStatusClass,
  getElectricalUnit
};
