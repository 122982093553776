import axios, { requestToken } from '../../utils/axios';
import toast from 'react-hot-toast';

const getAll = async (
  page,
  itemsPerPage,
  value,
  startDate,
  endDate,
  customFilter
) => {
  try {
    const response = await axios.get(
      `/api/accounts-receivable?search=${JSON.stringify({
        page,
        itemsPerPage,
        value: encodeURIComponent(value),
        isGeneratingReport: false,
        startDate,
        endDate,
        customFilter
        // hasPendingBalance
      })}`,
      {
        cancelToken: requestToken()
      }
    );
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data)
      return Promise.reject(e.response.data);
    return Promise.reject(e);
  }
};

const getOne = async (id) => {
  try {
    const response = await axios.get(`/api/accounts-receivable/${id}`);
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data)
      return Promise.reject(e.response.data);
    return Promise.reject(e);
  }
};

const getAllTransactions = async () => {
  try {
    const response = await axios.get(`/api/accounts-receivable/transactions`);
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data)
      return Promise.reject(e.response.data);
    return Promise.reject(e);
  }
};

const getTransactions = async (id) => {
  try {
    const response = await axios.get(
      `/api/accounts-receivable/${id}/transactions`
    );
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data)
      return Promise.reject(e.response.data);
    return Promise.reject(e);
  }
};

const getByCustomer = async (id) => {
  try {
    const response = await axios.get(
      `/api/accounts-receivable/customers/${id}`
    );
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data)
      return Promise.reject(e.response.data);
    return Promise.reject(e);
  }
};

const create = async (payload) => {
  try {
    const response = await axios.post(`/api/accounts-receivable`, payload);
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data)
      return Promise.reject(e.response.data);
    return Promise.reject(e);
  }
};

const createTransaction = async (id, payload) => {
  try {
    const response = await axios.post(
      `/api/accounts-receivable/${id}/transactions`,
      payload
    );
    toast.success(response.data.message, {
      style: {
        animation: 'none'
      }
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data)
      return Promise.reject(e.response.data);
    return Promise.reject(e);
  }
};

const updateTransaction = async (arId, tId, payload) => {
  try {
    const response = await axios.patch(
      `/api/accounts-receivable/${arId}/transactions/${tId}`,
      payload
    );
    toast.success(response.data.message, {
      style: {
        animation: 'none'
      }
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data)
      return Promise.reject(e.response.data);
    return Promise.reject(e);
  }
};

const update = async (id, payload) => {
  try {
    const response = await axios.patch(`/api/customers/${id}`, payload);
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data)
      return Promise.reject(e.response.data);
    return Promise.reject(e);
  }
};

const removeTransaction = async (arId, tId) => {
  try {
    const response = await axios.delete(
      `/api/accounts-receivable/${arId}/transactions/${tId}`
    );
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data)
      return Promise.reject(e.response.data);
    return Promise.reject(e);
  }
};

export default {
  getAll,
  getOne,
  getByCustomer,
  create,
  update,
  removeTransaction,
  getAllTransactions,
  getTransactions,
  createTransaction,
  updateTransaction
};
