import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Row,
  Col
} from 'reactstrap';

import { displayAmount, formatFilename, moment, getFileObject } from 'utils';
import AccountsReceivableAPI from 'services/api/accountReceivable';
import FileAPI from 'services/api/file';
import AppCurrencyInput from 'components/Forms/AppCurrencyInput';
import { TransactionKind } from '../../constants';
import AppInput from 'components/Forms/AppInput';

const pdfjs = require('pdfjs-dist/build/pdf');

pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');

const pdfParseContent = async (url, canvasId) => {
  try {
    const loadingTask = pdfjs.getDocument(url);
    const pdfObject = await loadingTask.promise;

    // Fetch the first page
    const pageNumber = 1;
    const page = await pdfObject.getPage(pageNumber);
    const pageContent = await page.getTextContent();

    if (canvasId) {
      // Render
      const scale = 1.5;
      const viewport = page.getViewport({ scale: scale });

      // Prepare canvas using PDF page dimensions
      const canvas = document.getElementById(canvasId);

      if (canvas) {
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        const renderTask = page.render(renderContext);
        await renderTask.promise;
      }
    }

    return pageContent;
  } catch (e) {
    return Promise.reject(e);
  }
};

function AppARInvoiceForm({
  accountReceivableId,
  invoice,
  isCreatingInvoice,
  isEditingInvoice,
  onClose,
  onSuccess
}) {
  const [form, setForm] = useState({
    subtotal: '0.00',
    itbis: '0.00',
    discount: '0.00',
    date: '',
    invoiceNumber: '',
    transactionNote: '',
    fileInvoice: null,
    fileProposal: null,
    fileAgreement: null
  });
  const [errors, setErrors] = useState({});
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [invoiceFileItems, setInvoiceFileItems] = useState(null);
  const invoiceInputRef = useRef(null);
  const [proposalFile, setProposalFile] = useState(null);
  const proposalInputRef = useRef(null);
  const [agreementFile, setAgreementFile] = useState(null);
  const agreementInputRef = useRef(null);
  const [isSending, setIsSending] = useState(false);

  const [invoiceFieldDirty, setInvoiceFieldDirty] = useState(false);
  const [proposalFieldDirty, setProposalFieldDirty] = useState(false);
  const [agreementFieldDirty, setAgreementFieldDirty] = useState(false);

  const [displayMoreFields, setDisplayMoreFields] = useState(false);

  useEffect(() => {
    if (isEditingInvoice) {
      function toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          callback(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      }

      if (invoice.fileInvoice) {
        toDataUrl(getFileObject(invoice.fileInvoice), async function (x) {
          try {
            const file = x;
            const dT =
              new ClipboardEvent('').clipboardData || // Firefox < 62 workaround exploiting https://bugzilla.mozilla.org/show_bug.cgi?id=1422655
              new DataTransfer(); // specs compliant (as of March 2018 only Chrome)
            const fileToSet = new File(
              [file],
              invoice.fileInvoice.replace('invoices/', '')
            );
            const invoiceContent = await pdfParseContent(
              URL.createObjectURL(fileToSet),
              'invoice-preview-canvas'
            );
            setInvoiceFileItems(invoiceContent.items);
            setInvoiceFile(fileToSet);
          } catch (e) {
            console.log(e);
          }
        });
      }

      if (invoice.fileProposal) {
        toDataUrl(getFileObject(invoice.fileProposal), function (x) {
          const file = x;
          const dT =
            new ClipboardEvent('').clipboardData || // Firefox < 62 workaround exploiting https://bugzilla.mozilla.org/show_bug.cgi?id=1422655
            new DataTransfer(); // specs compliant (as of March 2018 only Chrome)
          const fileToSet = new File(
            [file],
            invoice.fileInvoice.replace('invoices/', '')
          );
          setProposalFile(fileToSet);
        });
      }

      if (invoice.fileAgreement) {
        toDataUrl(getFileObject(invoice.fileAgreement), function (x) {
          const file = x;
          const dT =
            new ClipboardEvent('').clipboardData || // Firefox < 62 workaround exploiting https://bugzilla.mozilla.org/show_bug.cgi?id=1422655
            new DataTransfer(); // specs compliant (as of March 2018 only Chrome)
          const fileToSet = new File(
            [file],
            invoice.fileAgreement.replace('payment-agreements/', '')
          );
          setAgreementFile(fileToSet);
        });
      }

      setForm({
        subtotal: (invoice.amount / 100).toString(),
        itbis: (invoice.amountITBIS / 100).toString(),
        discount: (invoice.amountDiscount / 100).toString(),
        date: moment(invoice.date).format('YYYY-MM-DD'),
        invoiceNumber: invoice.invoiceNumber,
        transactionNote: invoice.transactionNote,
        fileInvoice: invoice.fileInvoice,
        fileProposal: invoice.fileProposal,
        fileAgreement: invoice.fileAgreement
      });
    }
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async () => {
    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    if (!form.invoiceNumber) {
      newErrors.invoiceNumber = 'Es obligatorio';
    }

    if (!form.date) {
      newErrors.date = 'Es obligatorio';
    }

    if (!getSubtotal()) {
      newErrors.subtotal = 'Debe ser mayor que 0';
    }

    if (getDiscount() && getDiscount() > getSubtotal()) {
      newErrors.discount = 'Debe ser menor que el Subtotal';
    }

    if (!invoiceFile) {
      alert('Debes adjuntar una factura/cotización');
      newErrors.invoiceFile = true;
    }

    if (!proposalFile) {
      alert('Debes adjuntar una propuesta');
      newErrors.proposalFile = true;
    }

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    let tmpForm = {};

    if (isEditingInvoice) {
      tmpForm = { ...invoice };
    }

    tmpForm = {
      ...tmpForm,
      amount: getSubtotal() * 100,
      amountDiscount: getDiscount() * 100,
      amountITBIS: getItbis() * 100,
      total: (getSubtotal() - getDiscount() + getItbis()) * 100,
      kind: TransactionKind.Invoice,
      invoiceNumber: form.invoiceNumber,
      date: moment(form.date),
      transactionNote: form.transactionNote
    };

    try {
      if (!isEditingInvoice || (isEditingInvoice && proposalFieldDirty)) {
        const proposalFileName = formatFilename(proposalFile.name, 'proposals');
        const proposalSignedRequest = await FileAPI.signS3Upload(
          proposalFileName,
          proposalFile.type
        );
        await FileAPI.uploadToS3(proposalFile, proposalSignedRequest);
        await FileAPI.create({
          key: proposalFileName
        });
        tmpForm.fileProposal = proposalFileName;
      }

      if (!isEditingInvoice || (isEditingInvoice && invoiceFieldDirty)) {
        const invoiceFileName = formatFilename(invoiceFile.name, 'invoices');
        const invoiceSignedRequest = await FileAPI.signS3Upload(
          invoiceFileName,
          invoiceFile.type
        );
        await FileAPI.uploadToS3(invoiceFile, invoiceSignedRequest);
        await FileAPI.create({
          key: invoiceFileName
        });
        tmpForm.fileInvoice = invoiceFileName;
      }

      if (
        (!isEditingInvoice && agreementFile) ||
        (isEditingInvoice && agreementFieldDirty && agreementFile)
      ) {
        const agreementFileName = formatFilename(
          proposalFile.name,
          'payment-agreements'
        );
        const agreementSignedRequest = await FileAPI.signS3Upload(
          agreementFileName,
          agreementFile.type
        );
        await FileAPI.uploadToS3(agreementFile, agreementSignedRequest);
        await FileAPI.create({
          key: agreementFileName
        });
        tmpForm.fileAgreement = agreementFileName;
      }

      if (isEditingInvoice) {
        await AccountsReceivableAPI.updateTransaction(
          accountReceivableId,
          invoice._id,
          tmpForm
        );
      } else {
        await AccountsReceivableAPI.createTransaction(
          accountReceivableId,
          tmpForm
        );
      }

      setForm({
        subtotal: '0.00',
        itbis: '0.00',
        discount: '0.00',
        date: '',
        invoiceNumber: '',
        transactionNote: '',
        fileInvoice: null,
        fileProposal: null,
        fileAgreement: null
      });
      setInvoiceFile(null);
      setInvoiceFileItems(null);
      setProposalFile(null);
      onSuccess();
    } catch (e) {
      console.log(e);
      if (tmpForm.fileProposal) {
        FileAPI.deleteOneByKey(tmpForm.fileProposal);
      }

      if (tmpForm.fileInvoice) {
        FileAPI.deleteOneByKey(tmpForm.fileInvoice);
      }

      setIsSending(false);
    }
  };

  const handleInvoiceFileChange = async (e) => {
    const file = e.target.files[0];
    try {
      if (file) {
        const invoiceContent = await pdfParseContent(
          URL.createObjectURL(file),
          'invoice-preview-canvas'
        );
        setInvoiceFileItems(invoiceContent.items);
        setInvoiceFile(file);
        setInvoiceFieldDirty(true);
        e.target.value = null;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleProposalFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProposalFile(file);
      setProposalFieldDirty(true);
      e.target.value = null;
    }
  };

  const handleAgreementFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAgreementFile(file);
      setAgreementFieldDirty(true);
      e.target.value = null;
    }
  };

  const setFormValuesFromInvoice = () => {
    if (!invoiceFileItems || invoiceFileItems.length === 0) {
      alert('Archivo invalido');
      return;
    }

    const tmpForm = { ...form };

    const invouceNumberIndex = invoiceFileItems.findIndex(
      (i) => i.str === 'Documento'
    );
    const subtotalIndex = invoiceFileItems.findIndex((i) => i.str === 'TOTAL:');
    const itbisIndex = invoiceFileItems.findIndex((i) => i.str === 'ITBIS In:');
    const descuentoIndex = invoiceFileItems.findIndex(
      (i) => i.str === 'Descuento(0%):'
    );

    if (!subtotalIndex) {
      alert('No se encontro el Subtotal');
    } else if (invoiceFileItems[subtotalIndex + 1]) {
      const pageSubtotal = invoiceFileItems[subtotalIndex + 1].str;
      if (pageSubtotal) {
        tmpForm.subtotal = pageSubtotal.replace(/[^0-9.]/g, '');
      }
    }

    if (!itbisIndex) {
      alert('No se encontro el ITBIS');
    } else if (invoiceFileItems[itbisIndex + 1]) {
      const pageItbis = invoiceFileItems[itbisIndex + 1].str;
      if (pageItbis) {
        tmpForm.itbis = pageItbis.replace(/[^0-9.]/g, '');
      }
    }

    if (!descuentoIndex) {
      alert('No se encontro el Descuento');
    } else if (invoiceFileItems[descuentoIndex + 3]) {
      const pageDiscount = invoiceFileItems[descuentoIndex + 3].str;
      if (pageDiscount) {
        tmpForm.discount = pageDiscount.replace(/[^0-9.]/g, '');
      }
    }

    if (!invouceNumberIndex) {
      alert('No se encontro el Número de Documento');
    } else if (invoiceFileItems[invouceNumberIndex - 1]) {
      const invoiceNumber = invoiceFileItems[invouceNumberIndex - 1].str;
      if (invoiceNumber) {
        tmpForm.invoiceNumber = invoiceNumber;
      }
    }

    setForm(tmpForm);
  };

  const getSubtotal = () => {
    if (!form.subtotal) return 0.0;
    return parseFloat(form.subtotal.replace(/[^0-9.]/g, ''));
  };

  const getItbis = () => {
    if (!form.itbis) return 0.0;
    return parseFloat(form.itbis.replace(/[^0-9.]/g, ''));
  };

  const getDiscount = () => {
    if (!form.discount) return 0.0;
    return parseFloat(`${form.discount.replace(/[^0-9.]/g, '')}`);
  };

  const renderView = () => {
    if (!accountReceivableId) return null;

    return (
      <Modal
        fade={false}
        isOpen={isCreatingInvoice || isEditingInvoice}
        size="xl"
        className="modal-dialog-centered modal-secondary"
      >
        <ModalHeader>Factura/Cotización</ModalHeader>

        <ModalBody className="py-0">
          <Row>
            <Col lg="6">
              {!invoiceFile ? (
                <Button
                  style={{
                    width: '100%',
                    minHeight: '687px',
                    marginBottom: '0',
                    backgroundColor: '#f0f0f0'
                  }}
                  className="card d-flex justify-content-center align-items-center w-100 mb-4 mb-lg-0"
                  color="transparent"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    invoiceInputRef.current.click();
                  }}
                >
                  ADJUNTAR FACTURA
                </Button>
              ) : null}

              <canvas
                className="card mb-0"
                style={{
                  width: '100%',
                  minHeight: '687px',
                  display: invoiceFile ? 'block' : 'none'
                }}
                id="invoice-preview-canvas"
              ></canvas>
            </Col>

            <Col lg="6">
              <Card className="mb-0">
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <label
                          htmlFor="voucher"
                          className="form-control-label d-flex justify-content-between"
                        >
                          Factura/Cotización
                          {!invoiceFile ? (
                            <Button
                              className="d-block"
                              size="sm"
                              color="primary"
                              outline
                              onClick={(e) => {
                                e.preventDefault();
                                invoiceInputRef.current.click();
                              }}
                            >
                              CARGAR
                            </Button>
                          ) : (
                            <div className="d-flex">
                              <Button
                                className="d-block"
                                size="sm"
                                color="primary"
                                outline
                                onClick={() => setFormValuesFromInvoice()}
                                disabled={!invoiceFile}
                              >
                                Leer valores
                              </Button>
                              <Button
                                className="d-block"
                                size="sm"
                                color="danger"
                                outline
                                onClick={(e) => {
                                  e.preventDefault();
                                  setInvoiceFile(null);
                                  setInvoiceFileItems(null);
                                }}
                              >
                                ELIMINAR
                              </Button>
                            </div>
                          )}
                        </label>

                        {invoiceFile ? (
                          <small>{invoiceFile.name}</small>
                        ) : (
                          <small className="text-muted">
                            No tienes Factura/Cotización adjunta
                          </small>
                        )}

                        <input
                          ref={invoiceInputRef}
                          hidden
                          type="file"
                          onChange={handleInvoiceFileChange}
                        />
                      </FormGroup>

                      <FormGroup>
                        <label
                          htmlFor="voucher"
                          className="form-control-label d-flex justify-content-between"
                        >
                          Propuesta
                          {!proposalFile ? (
                            <Button
                              className="d-block"
                              size="sm"
                              color="primary"
                              outline
                              onClick={(e) => {
                                e.preventDefault();
                                proposalInputRef.current.click();
                              }}
                            >
                              CARGAR
                            </Button>
                          ) : (
                            <Button
                              className="d-block"
                              size="sm"
                              color="danger"
                              outline
                              onClick={(e) => {
                                e.preventDefault();
                                setProposalFile(null);
                              }}
                            >
                              ELIMINAR
                            </Button>
                          )}
                        </label>

                        {proposalFile ? (
                          <small>{proposalFile.name}</small>
                        ) : (
                          <small className="text-muted">
                            No tienes propuesta adjunta
                          </small>
                        )}

                        <input
                          ref={proposalInputRef}
                          hidden
                          type="file"
                          onChange={handleProposalFileChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="6">
                      <AppInput
                        label="Número de Documento"
                        id="invoiceNumber"
                        name="invoiceNumber"
                        type="text"
                        value={form.invoiceNumber}
                        error={errors.invoiceNumber}
                        onChange={handleChange}
                      />
                    </Col>

                    <Col sm="6">
                      <AppInput
                        label="Fecha"
                        id="date"
                        name="date"
                        type="date"
                        value={form.date}
                        error={errors.date}
                        onChange={handleChange}
                      />
                    </Col>

                    <Col sm="12">
                      <AppCurrencyInput
                        label="Subtotal"
                        id="subtotal"
                        name="subtotal"
                        step={1}
                        decimalScale={2}
                        value={form.subtotal}
                        error={errors.subtotal}
                        onValueChange={(value, name) =>
                          handleChange({
                            target: { value, name }
                          })
                        }
                      />

                      <AppCurrencyInput
                        label="ITBIS"
                        id="itbis"
                        name="itbis"
                        step={1}
                        decimalScale={2}
                        value={form.itbis}
                        error={errors.itbis}
                        onValueChange={(value, name) =>
                          handleChange({
                            target: { value, name }
                          })
                        }
                      />

                      <AppCurrencyInput
                        label="Descuento"
                        id="discount"
                        name="discount"
                        step={1}
                        decimalScale={2}
                        value={form.discount}
                        error={errors.discount}
                        onValueChange={(value, name) =>
                          handleChange({
                            target: { value, name }
                          })
                        }
                      />

                      <hr className="mt-2 mb-3" />

                      <small className="text-muted">Total</small>

                      <div className="font-weight-bold">
                        {displayAmount(
                          getSubtotal() - getDiscount() + getItbis()
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Button
                    size="sm"
                    color="transparent"
                    className="mx-auto d-block"
                    onClick={() => setDisplayMoreFields(!displayMoreFields)}
                  >
                    {displayMoreFields ? 'Mostrar menos' : 'Mostrar más'}
                  </Button>

                  {displayMoreFields ? (
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <label
                            htmlFor="voucher"
                            className="form-control-label d-flex justify-content-between"
                          >
                            Acuerdo de pago
                            {!agreementFile ? (
                              <Button
                                className="d-block"
                                size="sm"
                                color="primary"
                                outline
                                onClick={(e) => {
                                  e.preventDefault();
                                  agreementInputRef.current.click();
                                }}
                              >
                                CARGAR
                              </Button>
                            ) : (
                              <Button
                                className="d-block"
                                size="sm"
                                color="danger"
                                outline
                                onClick={(e) => {
                                  e.preventDefault();
                                  setAgreementFile(null);
                                }}
                              >
                                ELIMINAR
                              </Button>
                            )}
                          </label>

                          {agreementFile ? (
                            <small>{agreementFile.name}</small>
                          ) : (
                            <small className="text-muted">
                              No tienes acuerdo de pago adjunto
                            </small>
                          )}

                          <input
                            ref={agreementInputRef}
                            hidden
                            type="file"
                            onChange={handleAgreementFileChange}
                          />
                        </FormGroup>

                        <label
                          htmlFor="voucher"
                          className="form-control-label d-flex justify-content-between"
                        >
                          Anotaciones
                        </label>

                        <div
                          data-quill-placeholder="Quill WYSIWYG"
                          data-toggle="quill"
                        />
                        <ReactQuill
                          value={form.transactionNote || ''}
                          onChange={(value) => {
                            setForm({
                              ...form,
                              transactionNote: value
                            });
                          }}
                          theme="snow"
                          modules={{
                            toolbar: [
                              ['bold', 'italic'],
                              ['link', 'blockquote', 'code', 'image'],
                              [
                                {
                                  list: 'ordered'
                                },
                                {
                                  list: 'bullet'
                                }
                              ]
                            ]
                          }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          {isSending ? (
            <Button className="new-event--add" color="primary" type="button">
              <Spinner size="sm" />
            </Button>
          ) : (
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              size="sm"
              onClick={handleSubmit}
            >
              Guardar
            </Button>
          )}

          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => {
              setForm({
                subtotal: '0.00',
                itbis: '0.00',
                discount: '0.00',
                date: '',
                invoiceNumber: '',
                transactionNote: '',
                fileInvoice: null,
                fileProposal: null,
                fileAgreement: null
              });
              setErrors({});
              setInvoiceFile(null);
              setInvoiceFileItems(null);
              setProposalFile(null);
              onClose();
            }}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return renderView();
}

export default AppARInvoiceForm;
