import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from 'reactstrap';

import certificationAPI from 'services/api/certification';
import productAPI from 'services/api/product';
import AlternativeHeader from 'components/Headers/AlternativeHeader';
import AppInput from 'components/Forms/AppInput';
import { getFileObject } from 'utils';
import AppActivity from 'components/Activities/AppActivity';
import AppNotes from 'components/Notes/AppNotes';

function AppCertificationView({
  certification,
  onEditClick,
  onClose,
  onProductsUpdate
}) {
  const [products, setProducts] = useState([]);
  const [value, setValue] = useState('');

  const fetchProducts = async (valueToSearch) => {
    try {
      const results = await productAPI.getAll(
        0,
        10,
        valueToSearch && valueToSearch.length > 0 ? valueToSearch : ''
      );
      setProducts(results.products);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const addProduct = async (productId) => {
    try {
      await certificationAPI.addProduct(certification._id, productId);
      await onProductsUpdate();
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const deleteProduct = async (productId) => {
    try {
      await certificationAPI.deleteProduct(certification._id, productId);
      await onProductsUpdate();
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  return (
    <>
      <AlternativeHeader
        name={certification.name}
        parentName="Certificaciones"
        onBackClick={onClose}
        onNewLabel="Editar"
        onNewClick={() => onEditClick(certification._id)}
      />

      <Container fluid className="mt--6">
        <Row>
          <Col md="6" lg="8">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="11">
                    <h3 className="mb-0">Equipos</h3>
                  </Col>
                  <Col xs="1">{certification.products.length}</Col>
                </Row>
              </CardHeader>
              <CardBody tag="ul" className="equipment-selector">
                {certification.products.map((p) => (
                  <li>
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        deleteProduct(p._id);
                      }}
                    >
                      <i class="fas fa-check-circle active"></i> {p.name}
                    </a>
                  </li>
                ))}

                {value &&
                  products
                    .filter(
                      (p) =>
                        !certification.products.find((cP) => cP._id === p._id)
                    )
                    .map((p) => (
                      <li>
                        <a
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            addProduct(p._id);
                          }}
                        >
                          <i class="far fa-circle"></i> {p.name}
                        </a>
                      </li>
                    ))}
              </CardBody>

              <CardFooter className="equipment-selector-form">
                <AppInput
                  id="value"
                  name="value"
                  type="search"
                  value={value}
                  placeholder="Nombre del producto"
                  onChange={(e) => {
                    setValue(e.target.value);
                    fetchProducts(e.target.value);
                  }}
                />
              </CardFooter>
            </Card>

            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="12">
                    <h3 className="mb-0">Certificado</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <a
                      onClick={(e) => {
                        if (!certification.file) e.preventDefault();
                      }}
                      style={{ height: '32px', width: '32px' }}
                      href={
                        certification.file
                          ? getFileObject(certification.file)
                          : '#'
                      }
                      target="_blank"
                      className={`d-block ${
                        certification.file ? 'bg-primary' : 'bg-gray'
                      } text-white d-flex justify-content-center align-items-center rounded`}
                    >
                      <i className="fas fa-file-alt"></i>
                    </a>
                  </Col>
                  <div className="col">
                    <a
                      onClick={(e) => {
                        if (!certification.file) e.preventDefault();
                      }}
                      style={{ height: '32px', width: '32px' }}
                      href={
                        certification.file
                          ? getFileObject(certification.file)
                          : '#'
                      }
                      target="_blank"
                    >
                      <h5 className="mb-0">
                        {certification.file
                          ? certification.file.replace('certifications/', '')
                          : 'Sin certificado'}
                      </h5>
                    </a>
                  </div>
                </Row>
              </CardBody>
            </Card>

            <AppActivity resourceId={certification._id} />
          </Col>

          <Col md="6" lg="4">
            <AppNotes resourceId={certification._id} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AppCertificationView;
