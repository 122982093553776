import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";

import AlternativeHeader from "components/Headers/AlternativeHeader";
import AppInput from "components/Forms/AppInput";
import { moment } from "utils";
import { Representatives, RejectionStatus } from "../../constants";

const provinces = [
  {
    id: 1,
    name: "Pedernales",
    slug: "pedernales",
    is_active: false,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-06-14 14:47:43.64935",
    country_id: 1,
  },
  {
    id: 2,
    name: "Peravia",
    slug: "peravia",
    is_active: true,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-04-27 14:54:53.662499",
    country_id: 1,
  },
  {
    id: 3,
    name: "María Trinidad Sánchez",
    slug: "maria-trinidad-sanchez",
    is_active: true,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-04-27 14:41:24.949622",
    country_id: 1,
  },
  {
    id: 4,
    name: "San José de Ocoa",
    slug: "san-jose-de-ocoa",
    is_active: false,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-06-05 20:52:39.792209",
    country_id: 1,
  },
  {
    id: 5,
    name: "Espaillat",
    slug: "espaillat",
    is_active: true,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2023-04-27 14:44:28.696944",
    country_id: 1,
  },
  {
    id: 6,
    name: "Azua",
    slug: "azua",
    is_active: true,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2023-04-27 14:44:33.559768",
    country_id: 1,
  },
  {
    id: 7,
    name: "Independencia",
    slug: "independencia",
    is_active: false,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2023-06-05 20:54:54.082701",
    country_id: 1,
  },
  {
    id: 8,
    name: "Santiago Rodríguez",
    slug: "santiago-rodriguez",
    is_active: false,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-06-14 14:46:38.221535",
    country_id: 1,
  },
  {
    id: 9,
    name: "Santo Domingo",
    slug: "santo-domingo",
    is_active: true,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2022-08-16 22:47:38.130043",
    country_id: 1,
  },
  {
    id: 10,
    name: "Hermanas Mirabal",
    slug: "hermanas-mirabal",
    is_active: false,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2023-06-05 20:55:23.449763",
    country_id: 1,
  },
  {
    id: 11,
    name: "San Cristóbal",
    slug: "san-cristobal",
    is_active: true,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-04-27 14:41:51.702941",
    country_id: 1,
  },
  {
    id: 12,
    name: "La Romana",
    slug: "la-romana",
    is_active: true,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2022-08-16 22:51:34.167341",
    country_id: 1,
  },
  {
    id: 13,
    name: "Monte Cristi",
    slug: "monte-cristi",
    is_active: false,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-06-05 20:50:39.624467",
    country_id: 1,
  },
  {
    id: 14,
    name: "San Juan",
    slug: "san-juan",
    is_active: true,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-04-27 14:41:45.179938",
    country_id: 1,
  },
  {
    id: 15,
    name: "Sánchez Ramírez",
    slug: "sanchez-ramirez",
    is_active: true,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-04-27 14:41:15.544646",
    country_id: 1,
  },
  {
    id: 16,
    name: "Puerto Plata",
    slug: "puerto-plata",
    is_active: true,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2022-08-16 22:48:38.073744",
    country_id: 1,
  },
  {
    id: 17,
    name: "La Vega",
    slug: "la-vega",
    is_active: true,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2023-04-27 14:44:55.43386",
    country_id: 1,
  },
  {
    id: 18,
    name: "San Pedro de Macorís",
    slug: "san-pedro-de-macoris",
    is_active: true,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-04-27 14:40:38.094298",
    country_id: 1,
  },
  {
    id: 19,
    name: "Barahona",
    slug: "barahona",
    is_active: true,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2023-04-27 14:52:44.638993",
    country_id: 1,
  },
  {
    id: 20,
    name: "Duarte",
    slug: "duarte",
    is_active: true,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2023-05-20 20:53:52.483008",
    country_id: 1,
  },
  {
    id: 21,
    name: "Dajabón",
    slug: "dajabon",
    is_active: false,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2023-06-05 20:54:18.37918",
    country_id: 1,
  },
  {
    id: 22,
    name: "Santiago de los Caballeros",
    slug: "santiago-de-los-caballeros",
    is_active: true,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2022-08-16 22:48:46.14056",
    country_id: 1,
  },
  {
    id: 23,
    name: "El Seibo",
    slug: "el-seibo",
    is_active: false,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2023-06-05 20:55:53.083401",
    country_id: 1,
  },
  {
    id: 24,
    name: "Monseñor Nouel",
    slug: "monsenor-nouel",
    is_active: true,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-04-27 14:43:54.154769",
    country_id: 1,
  },
  {
    id: 25,
    name: "Monte Plata",
    slug: "monte-plata",
    is_active: true,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-04-27 14:44:01.149963",
    country_id: 1,
  },
  {
    id: 26,
    name: "Elías Piña",
    slug: "elias-pina",
    is_active: false,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2023-06-05 20:56:15.116205",
    country_id: 1,
  },
  {
    id: 27,
    name: "Hato Mayor",
    slug: "hato-mayor",
    is_active: false,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2023-06-05 20:55:36.085505",
    country_id: 1,
  },
  {
    id: 28,
    name: "Bahoruco",
    slug: "bahoruco",
    is_active: false,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2023-06-05 20:56:52.611601",
    country_id: 1,
  },
  {
    id: 29,
    name: "Valverde",
    slug: "valverde",
    is_active: false,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2023-06-05 21:01:32.735869",
    country_id: 1,
  },
  {
    id: 30,
    name: "Samaná",
    slug: "samana",
    is_active: true,
    created_at: "2018-08-30 19:46:52.377",
    updated_at: "2022-08-16 22:49:40.04558",
    country_id: 1,
  },
  {
    id: 31,
    name: "La Altagracia",
    slug: "la-altagracia",
    is_active: true,
    created_at: "2018-08-30 19:46:52.376",
    updated_at: "2022-08-16 22:49:10.26268",
    country_id: 1,
  },
  {
    id: 32,
    name: "Distrito Nacional",
    slug: "distrito-nacional",
    is_active: false,
    created_at: "2021-10-31 02:12:58.84899",
    updated_at: "2023-06-14 14:46:54.650819",
    country_id: 1,
  },
  {
    id: 33,
    name: "RD",
    slug: "cerca-de-mi",
    is_active: true,
    created_at: "2022-03-30 15:18:00.63527",
    updated_at: "2023-04-27 14:43:07.618532",
    country_id: 1,
  },
  {
    id: 34,
    name: "Ciudad de México",
    slug: "ciudad-de-mexico",
    is_active: false,
    created_at: "2022-08-16 22:06:56.289175",
    updated_at: "2022-08-16 22:06:56.289175",
    country_id: 2,
  },
  {
    id: 35,
    name: "Nuevo León",
    slug: "nuevo-leon",
    is_active: false,
    created_at: "2022-08-16 22:09:17.694297",
    updated_at: "2022-08-16 22:09:17.694297",
    country_id: 2,
  },
  {
    id: 36,
    name: "Jalisco",
    slug: "jalisco",
    is_active: false,
    created_at: "2022-08-16 22:10:48.555845",
    updated_at: "2022-08-16 22:10:48.555845",
    country_id: 2,
  },
  {
    id: 37,
    name: "Cundinamarca",
    slug: "cundinamarca",
    is_active: false,
    created_at: "2022-08-16 22:17:28.311049",
    updated_at: "2022-08-16 22:17:28.311049",
    country_id: 3,
  },
  {
    id: 38,
    name: "Antioquia",
    slug: "antioquia",
    is_active: false,
    created_at: "2022-08-16 22:17:57.418241",
    updated_at: "2022-08-16 22:17:57.418241",
    country_id: 3,
  },
  {
    id: 39,
    name: "Valle del Cauca",
    slug: "valle-del-cauca",
    is_active: false,
    created_at: "2022-08-16 22:19:42.158789",
    updated_at: "2022-08-16 22:19:42.158789",
    country_id: 3,
  },
  {
    id: 40,
    name: "Ciudad Autónoma de Buenos Aires",
    slug: "ciudad-autonoma-de-buenos-aires",
    is_active: false,
    created_at: "2022-08-16 22:23:06.899667",
    updated_at: "2022-08-16 22:23:06.899667",
    country_id: 4,
  },
  {
    id: 41,
    name: "Provincia de Córdoba",
    slug: "provincia-de-cordoba",
    is_active: false,
    created_at: "2022-08-16 22:24:27.295602",
    updated_at: "2022-08-16 22:24:27.295602",
    country_id: 4,
  },
  {
    id: 42,
    name: "Provincia de Santa Fe",
    slug: "provincia-de-santa-fe",
    is_active: false,
    created_at: "2022-08-16 22:25:25.699521",
    updated_at: "2022-08-16 22:25:25.699521",
    country_id: 4,
  },
  {
    id: 43,
    name: "San Francisco de Macoris",
    slug: "san-francisco-de-macoris",
    is_active: false,
    created_at: "2022-08-16 22:25:25.699521",
    updated_at: "2022-08-16 22:25:25.699521",
    country_id: 4,
  },
];

function AppProjectForm({ project, onSave, onClose, onViewClick }) {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (project._id) {
      const newForm = { ...project };
      if (newForm.noObjectionSubmittedAt) {
        newForm.noObjectionSubmittedAt = moment(
          newForm.noObjectionSubmittedAt
        ).format("YYYY-MM-DD");
      }

      if (newForm.agreementSubmittedAt) {
        newForm.agreementSubmittedAt = moment(
          newForm.agreementSubmittedAt
        ).format("YYYY-MM-DD");
      }

      if (newForm.meterPaymentRequestedAt) {
        newForm.meterPaymentRequestedAt = moment(
          newForm.meterPaymentRequestedAt
        ).format("YYYY-MM-DD");
      }

      if (newForm.meterInstalationRequestedAt) {
        newForm.meterInstalationRequestedAt = moment(
          newForm.meterInstalationRequestedAt
        ).format("YYYY-MM-DD");
      }
      setForm(newForm);
    }
  }, []);

  const handleChange = (e) => {
    let newValue = e.target.value;
    if (e.target.type === "checkbox") newValue = !form[e.target.name];

    setForm({
      ...form,
      [e.target.name]: newValue,
    });
  };

  const handleSave = () => {
    // 1. Información general
    // Se define la distribuidora de energía,
    // el personal que autoriza,
    // el personal encargado del sometimiento, el ingeniero que certifica, se verifica la firma y fecha del sometimiento.
    // Primera visita
    // 2. Anexo A
    // El sistema completa los datos que ya posee y seleccionamos los inversores.
    // 3. Anexo B (Si ya tenemos la carta de no objeción)
    // El sistema completa los datos que ya posee y seleccionamos los inversores.
    // 4. Carta de autorización del cliente & Hoja de descripción
    // El sistema completa los datos que ya posee y se pueden modificar los datos.
    // 5. Finalizado
    // Al finalizar el usuario puede exportar o enviar el documento. Puede elegir las secciones necesarias en la exportación o envío con un formulario de selección.
  };

  const handleInitialSave = async () => {
    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    if (!form.name) {
      newErrors.name = "Es obligatorio";
    }

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    try {
      const payload = { ...form };

      if (payload.noObjectionSubmittedAt) {
        payload.noObjectionSubmittedAt = moment(payload.noObjectionSubmittedAt);
      }

      if (payload.meterPaymentRequestedAt) {
        payload.meterPaymentRequestedAt = moment(
          payload.meterPaymentRequestedAt
        );
      }

      if (payload.meterInstalationRequestedAt) {
        payload.meterInstalationRequestedAt = moment(
          payload.meterInstalationRequestedAt
        );
      }

      if (payload.agreementSubmittedAt) {
        payload.agreementSubmittedAt = moment(payload.agreementSubmittedAt);
      }

      await onSave(payload);
      setIsSending(false);
    } catch (e) {
      setIsSending(false);
      console.log(e);
      /* handle error */
    }
  };

  return (
    <>
      <AlternativeHeader
        name={form.name || "Nuevo proyecto"}
        parentName="Proyectos"
        onBackClick={onClose}
        onNewLabel="Visualizar"
        onNewClick={project._id ? () => onViewClick(project._id, true) : null}
      />

      <Container className="mt--6">
        <Row>
          <Col className="order-xl-1" lg="8">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {project._id ? "Editar" : "Crear"} proyecto
                    </h3>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Form>
                  <Row>
                    <Col sm="12">
                      <AppInput
                        label="Nombre"
                        id="name"
                        name="name"
                        type="text"
                        placeholder={form ? form.name : ""}
                        value={form.name || ""}
                        error={errors.name}
                        required
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col sm="12">
                      <AppInput
                        label="Representante"
                        id="representative"
                        name="representative"
                        type="select"
                        value={form.representative}
                        error={errors.representative}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      >
                        <option value="">Representante del proyecto</option>
                        {Object.values(Representatives).map((rep, repIndex) => (
                          <option
                            key={`project-representatives-${repIndex}`}
                            value={rep}
                          >
                            {rep}
                          </option>
                        ))}
                      </AppInput>
                    </Col>

                    <Col sm="12">
                      <AppInput
                        label="Número de contrato"
                        id="energySupplierContract"
                        name="energySupplierContract"
                        type="text"
                        value={form.energySupplierContract || ""}
                        error={errors.energySupplierContract}
                        required
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col sm="12">
                      <AppInput
                        label="Número de medidor"
                        id="energySupplierMeterNumber"
                        name="energySupplierMeterNumber"
                        type="text"
                        value={form.energySupplierMeterNumber || ""}
                        error={errors.energySupplierMeterNumber}
                        required
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col sm="12">
                      <AppInput
                        label="Zona"
                        id="zone"
                        name="zone"
                        type="select"
                        value={form.zone}
                        error={errors.zone}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      >
                        <option value="">Seleccionar la zona</option>
                        {provinces.map((province) => (
                          <option key={`project-zones-${province.id}`} value={province.name}>
                            {province.name}
                          </option>
                        ))}
                      </AppInput>
                    </Col>

                    <Col sm="12">
                      <AppInput
                        label="Ubicación Nave (URL)"
                        id="locationUrl"
                        name="locationUrl"
                        type="text"
                        value={form.locationUrl || ""}
                        error={errors.locationUrl}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col sm="12">
                      <AppInput
                        label="Ubicación Poste (URL)"
                        id="posteLocationUrl"
                        name="posteLocationUrl"
                        type="text"
                        value={form.posteLocationUrl || ""}
                        error={errors.posteLocationUrl}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col sm="12">
                      <AppInput
                        label="Drone Deploy (URL)"
                        id="droneDeployUrl"
                        name="droneDeployUrl"
                        type="text"
                        value={form.droneDeployUrl || ""}
                        error={errors.droneDeployUrl}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col sm="12">
                      <AppInput
                        label="Fecha de emisión carta de no objeción"
                        id="noObjectionSubmittedAt"
                        name="noObjectionSubmittedAt"
                        type="date"
                        value={form.noObjectionSubmittedAt}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col sm="12">
                      <AppInput
                        label="Fecha de entrega acuerdo de interconexión"
                        id="agreementSubmittedAt"
                        name="agreementSubmittedAt"
                        type="date"
                        value={form.agreementSubmittedAt}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col sm="12">
                      <AppInput
                        label="Medidor: Fecha de emisión de solicitud de pago"
                        id="meterPaymentRequestedAt"
                        name="meterPaymentRequestedAt"
                        type="date"
                        value={form.meterPaymentRequestedAt}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col sm="12">
                      <AppInput
                        label="Medidor: Solicitud de instalación"
                        id="meterInstalationRequestedAt"
                        name="meterInstalationRequestedAt"
                        type="date"
                        value={form.meterInstalationRequestedAt}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      />
                    </Col>

                    <Col sm="12">
                      <AppInput
                        label="Estado de rechazo"
                        id="rejectionStatus"
                        name="rejectionStatus"
                        type="select"
                        value={form.rejectionStatus}
                        error={errors.rejectionStatus}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleChange(e)}
                      >
                        {Object.values(RejectionStatus).map((rep, repIndex) => (
                          <option
                            key={`project-representatives-${repIndex}`}
                            value={rep}
                          >
                            {rep}
                          </option>
                        ))}
                      </AppInput>
                    </Col>
                  </Row>

                  <Button
                    color="primary"
                    type="button"
                    onClick={handleInitialSave}
                    className="mt-3"
                  >
                    Guardar
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AppProjectForm;
