/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { UserRole } from './constants';
import Home from 'views/Home';
import Login from 'views/auth/Login';
import Users from 'views/Users';
import Files from 'views/Files';
import Customers from 'views/Customers';
import Products from 'views/Products';
import Projects from 'views/Projects';
import Certifications from 'views/Certifications';
import DataSheets from 'views/DataSheets';
import AccountsReceivableIndex from 'views/accountsReceivable/Index';
import AccountsReceivableShow from 'views/accountsReceivable/Show';
import EnergySuppliers from 'views/EnergySuppliers';

const routes = [
  {
    name: 'Inicio',
    icon: 'ni ni-shop text-primary',
    path: '/',
    component: Home,
    layout: '',
  },
  {
    name: 'Cliente por cobrar',
    icon: 'fas fa-hand-holding-usd',
    path: '/cuentas-por-cobrar/:id',
    component: AccountsReceivableShow,
    layout: '',
    hideFromSidebar: true,
    roles: [
      UserRole.Admin,
      UserRole.AdminReadOnly,
      UserRole.Secretary,
      UserRole.Accountant,
    ],
  },
  {
    name: 'Proyectos',
    icon: 'ni ni-sound-wave text-red',
    path: '/proyectos',
    component: Projects,
    layout: '',
    roles: [UserRole.Admin, UserRole.AdminReadOnly, UserRole.GeneralSupervisor],
  },
  {
    name: 'Por cobrar',
    icon: 'fas fa-hand-holding-usd text-gray',
    path: '/cuentas-por-cobrar',
    component: AccountsReceivableIndex,
    layout: '',
    roles: [
      UserRole.Admin,
      UserRole.AdminReadOnly,
      UserRole.Secretary,
      UserRole.Accountant,
    ],
  },
  {
    name: 'Clientes',
    icon: 'ni ni-single-02 text-gray',
    path: '/clientes',
    component: Customers,
    layout: '',
    roles: [
      UserRole.Admin,
      UserRole.AdminReadOnly,
      UserRole.GeneralSupervisor,
      UserRole.Secretary,
    ],
  },
  {
    collapse: true,
    name: 'Equipos',
    icon: 'fas fa-boxes text-gray',
    state: 'inventoryCollapse',
    roles: [UserRole.Admin, UserRole.AdminReadOnly, UserRole.GeneralSupervisor],
    views: [
      {
        name: 'Inventario',
        path: '/productos',
        miniName: 'I',
        component: Products,
        layout: '',
        roles: [UserRole.Admin, UserRole.AdminReadOnly, UserRole.GeneralSupervisor]
      },
      {
        name: 'Certificaciones',
        path: '/certificaciones',
        miniName: 'C',
        component: Certifications,
        layout: '',
        roles: [UserRole.Admin, UserRole.AdminReadOnly, UserRole.GeneralSupervisor]
      },
      {
        name: 'Data Sheets',
        path: '/data-sheets',
        miniName: 'DS',
        component: DataSheets,
        layout: '',
        roles: [UserRole.Admin, UserRole.AdminReadOnly, UserRole.GeneralSupervisor]
      },
    ],
  },
  {
    collapse: true,
    name: 'Empresa',
    icon: 'ni ni-building text-gray',
    state: 'companyCollapse',
    roles: [
      UserRole.Admin,
      UserRole.AdminReadOnly,
      UserRole.Secretary,
      UserRole.Accountant,
    ],
    views: [
      {
        name: 'Usuarios',
        path: '/usuarios',
        miniName: 'U',
        component: Users,
        layout: '',
        roles: [
          UserRole.Admin,
          UserRole.AdminReadOnly,
          UserRole.Secretary,
          UserRole.Accountant,
        ],
      },
      {
        path: '/archivos',
        name: 'Archivos',
        miniName: 'F',
        component: Files,
        layout: '',
        roles: [UserRole.Admin, UserRole.AdminReadOnly],
      },
    ],
  },
  {
    collapse: true,
    name: 'Recursos',
    icon: 'ni ni-folder-17 text-gray',
    state: 'resourcesCollapse',
    roles: [UserRole.Admin, UserRole.AdminReadOnly],
    views: [
      {
        name: 'Suplidoras de Energía',
        path: '/suplidoras-de-energia',
        miniName: 'SE',
        component: EnergySuppliers,
        layout: '',
        roles: [UserRole.Admin, UserRole.AdminReadOnly],
      },
    ],
  },
  {
    path: '/ingresar',
    name: 'Ingresar',
    miniName: 'L',
    component: Login,
    layout: '/auth',
    hideFromSidebar: true,
  },
];

export default routes;
