import React, { useEffect, useState } from 'react';

import { formatFilename } from 'utils';
import fileAPI from 'services/api/file';
import customerAPI from 'services/api/customer';
import AppCustomerList from 'components/Customers/AppCustomerList';
import AppCustomerForm from 'components/Customers/AppCustomerForm';
import AppCustomerView from 'components/Customers/AppCustomerView';

function Customers({ location, history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(location.search).get('page')) - 1 || 0
  );
  const [count, setCount] = useState(0);
  const [isViewing, setIsViewing] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => fetchCustomers(), []);

  useEffect(() => {
    if (!new URLSearchParams(location.search).get('id')) {
      handleClose();
    }
  }, [location.search]);

  useEffect(() => {
    if (!customer) {
      return;
    }

    if (customer._id) {
      setCustomers(
        customers.map((u) => (u._id === customer._id ? { ...customer } : u))
      );
      if (!new URLSearchParams(location.search).get('id')) {
        const params = new URLSearchParams();
        params.append('id', customer._id);
        history.push({ search: params.toString() });
      }
    }

    if (customer.avatar) setAvatar(customer.avatar);
    else setAvatar(null);
  }, [customer]);

  const fetchCustomers = async (pageToGo, valueToSearch, customFilter) => {
    setIsLoading(true);
    try {
      const { customers, count } = await customerAPI.getAll(
        pageToGo >= 0 ? pageToGo : page,
        itemsPerPage,
        valueToSearch && valueToSearch.length > 0 ? valueToSearch : '',
        customFilter && customFilter.length > 0 ? customFilter : ''
      );
      setCustomers(customers);
      setCount(count);

      const userId = new URLSearchParams(location.search).get('id');
      if (userId && userId !== 'undefined') {
        setCustomer(await customerAPI.getOne(userId));
        setIsViewing(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      /* handle error */
      console.log(e);
    }
  };

  const handleSearch = (valueToSearch, customFilter) => {
    fetchCustomers(0, valueToSearch, customFilter);
    setPage(0);
  };

  const handlePageClick = (pageToGo, value, customFilter) => {
    setPage(pageToGo);
    fetchCustomers(pageToGo, value, customFilter);
  };

  const handleCustomerClick = async (id, toView) => {
    try {
      setCustomer(await customerAPI.getOne(id));
      setIsViewing(!!toView);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleClose = async () => {
    try {
      setCustomer(null);
      setIsViewing(false);

      if (new URLSearchParams(location.search).get('id')) {
        const params = new URLSearchParams();
        params.delete('id');
        history.push({ search: params.toString() });
      }
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    const fileName = formatFilename(file.name, 'avatars');

    try {
      const signedRequest = await fileAPI.signS3Upload(fileName, file.type);
      await fileAPI.uploadToS3(file, signedRequest);
      await fileAPI.create({
        key: fileName
      });
      if (customer._id) {
        await customerAPI.update(customer._id, { avatar: fileName });
        fileAPI.deleteOneByKey(customer.avatar);
        setCustomer(await customerAPI.getOne(customer._id));
      }
      setAvatar(fileName);
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const handleDocumentFieldChange = async (e, name) => {
    const file = e.target.files[0];
    const fileName = formatFilename(file.name, 'documents-customer');

    try {
      const signedRequest = await fileAPI.signS3Upload(fileName, file.type);
      await fileAPI.uploadToS3(file, signedRequest);
      await fileAPI.create({
        key: fileName
      });

      await customerAPI.update(customer._id, {
        [name]: fileName
      });
      setCustomer(await customerAPI.getOne(customer._id));

      e.target.value = null;
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const handleDocumentDelete = async (name) => {
    try {
      await fileAPI.deleteOneByKey(customer[name]);
      await customerAPI.update(customer._id, { [name]: '' });
      setCustomer({ ...customer, [name]: '' });
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const handleSave = async (payload) => {
    try {
      if (customer._id) {
        await customerAPI.update(customer._id, payload);
        // if (new URLSearchParams(location.search).get('id')) {
        //   const params = new URLSearchParams();
        //   params.delete('id');
        //   history.push({ search: params.toString() });
        // }
        // setCustomer(await customerAPI.getOne(customer._id));
        setIsViewing(false);
        // setCustomer(null);
        setPage(0);
        // setAvatar(null);
        await fetchCustomers(0);
      } else {
        await customerAPI.create(payload);
        await fetchCustomers(0);
        setCustomer(null);
        setPage(0);
        setAvatar(null);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('¿Seguro quieres eliminar este usuario?')) return;
    if (!window.confirm('¡Después de este punto, no hay vuelta atrás!')) return;
    try {
      await customerAPI.deleteOne(id);
      fetchCustomers(0);
      setPage(0);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleNewClick = () => {
    setCustomer({ phones: [] });
  };

  const renderView = () => {
    if (!customer) {
      return (
        <AppCustomerList
          isLoading={isLoading}
          customers={customers}
          count={count}
          page={page}
          itemsPerPage={itemsPerPage}
          onSearch={handleSearch}
          onPageClick={handlePageClick}
          onCustomerClick={handleCustomerClick}
          onDelete={handleDelete}
          onNewClick={handleNewClick}
        />
      );
    }

    if (isViewing) {
      return (
        <AppCustomerView
          customer={customer}
          onClose={handleClose}
          onEditClick={handleCustomerClick}
          onDocumentFieldChange={handleDocumentFieldChange}
          onDocumentDelete={handleDocumentDelete}
        />
      );
    }

    return (
      <AppCustomerForm
        customer={customer}
        avatar={avatar}
        onClose={handleClose}
        onSave={handleSave}
        onAvatarChange={handleAvatarChange}
        onViewClick={handleCustomerClick}
      />
    );
  };

  return renderView();
}

export default Customers;
