import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from 'reactstrap';

import Header from 'components/Headers/AlternativeHeader';
import { getName } from 'utils';
import { displayAmount } from 'utils';
import { ProductCategory } from '../../constants';
import AppInput from 'components/Forms/AppInput';

function AppProductList({
  isLoading,
  products,
  count,
  page,
  itemsPerPage,
  onSearch,
  onPageClick,
  onProductClick,
  onDelete,
  onNewClick
}) {
  const [value, setValue] = useState('');
  const [category, setCategory] = useState('');

  const renderPagination = () => {
    const actualPage = page + 1;
    const totalPages =
      count > 0 && itemsPerPage > 0 ? Math.ceil(count / itemsPerPage) : 0;
    if (totalPages === 0) return null;

    const hasPrev = page > 0;
    const hasNext = actualPage < totalPages;
    const totalNextPages = totalPages - actualPage;
    let numbers = [];

    for (let i = 0, len = totalNextPages + 1; i < len; i++) {
      numbers.push(
        <PaginationItem
          key={`pagination-number-${actualPage + i}`}
          className={i === 0 ? 'active' : ''}
        >
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              onPageClick(page + i, value, category);
            }}
          >
            {actualPage + i}
          </PaginationLink>
        </PaginationItem>
      );
      if (i === 2) break;
    }

    return (
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={hasPrev ? '' : 'disabled'}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasPrev) {
                onPageClick(page - 1, value);
              }
            }}
            tabIndex="-1"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
        {numbers}
        <PaginationItem className={hasNext ? '' : 'disabled'}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasNext) {
                onPageClick(page + 1, value);
              }
            }}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  };

  const renderProducts = () => {
    return products.map((c, uIndex) => (
      <tr key={`products-${uIndex}`}>
        <td className="table-product">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onProductClick(c._id, true);
            }}
          >
            <b>{c.name}</b>
          </a>
        </td>
        <td>{c.sku}</td>
        <td>{c.kind}</td>
        <td>{displayAmount(c.price / 100)}</td>
        <td>{displayAmount(c.purchasingPrice / 100)}</td>
        <td>{c.stock}</td>
        <td>{c.reorderPoint || ''}</td>
        <td className="table-actions">
          <a
            className="table-action"
            href="#"
            id="tooltip209424781"
            onClick={(e) => {
              e.preventDefault();
              onProductClick(c._id, false);
            }}
          >
            <i className="fas fa-user-edit" />
          </a>
          <UncontrolledTooltip delay={0} target="tooltip209424781">
            Editar Producto
          </UncontrolledTooltip>
          <a
            className="table-action table-action-delete"
            href="#"
            id="tooltip12475020"
            onClick={(e) => {
              e.preventDefault();
              onDelete(c._id);
            }}
          >
            <i className="fas fa-trash" />
          </a>
          <UncontrolledTooltip delay={0} target="tooltip12475020">
            Eliminar Producto
          </UncontrolledTooltip>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Header name="" parentName="Inventario" onNewClick={onNewClick} />

      <Container fluid className="mt--6">
        <Row>
          {!value ? <></> : null}

          {true ? (
            <Col>
              <Card className="mt-0">
                <Row className="px-3 py-3">
                  <Col md="12" lg="4">
                    <Input
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        paddingLeft: '8px',
                        paddingRight: '8px'
                      }}
                      type="search"
                      placeholder="Buscar..."
                      value={value}
                      onChange={(e) => {
                        setValue(e.target.value);
                        onSearch(e.target.value, category);
                      }}
                    />
                  </Col>

                  <Col md="3" lg="2">
                    <Link
                      to={``}
                      className={`mb-0 mt-3 mt-md-0 inventory-categories-item ${
                        !category ? 'inventory-categories-item--active' : ''
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setCategory('');
                        onSearch(e.target.value, '');
                      }}
                    >
                      <img
                        alt="..."
                        className="inventory-categories-item__img"
                        src={require('assets/img/icons/dial.png').default}
                      />

                      <span>Todos</span>
                    </Link>
                  </Col>

                  <Col md="3" lg="2">
                    <Link
                      to={``}
                      className={`mb-0 mt-3 mt-md-0 inventory-categories-item ${
                        category === ProductCategory.SolarPanels
                          ? 'inventory-categories-item--active'
                          : ''
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setCategory(ProductCategory.SolarPanels);
                        onSearch(value, ProductCategory.SolarPanels);
                      }}
                    >
                      <img
                        alt="..."
                        className="inventory-categories-item__img"
                        src={
                          require('assets/img/icons/solar-panels.png').default
                        }
                      />

                      <span>Paneles</span>
                    </Link>
                  </Col>

                  <Col md="3" lg="2">
                    <Link
                      to={``}
                      className={`mb-0 mt-3 mt-md-0 inventory-categories-item ${
                        category === ProductCategory.Inverters
                          ? 'inventory-categories-item--active'
                          : ''
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setCategory(ProductCategory.Inverters);
                        onSearch(value, ProductCategory.Inverters);
                      }}
                    >
                      <img
                        className="inventory-categories-item__img"
                        src={require('assets/img/icons/generator.png').default}
                      />

                      <span>Inversores</span>
                    </Link>
                  </Col>

                  <Col md="3" lg="2">
                    <AppInput
                      bsSize="md"
                      id="customFilter"
                      name="customFilter"
                      type="select"
                      value={category}
                      onChange={(e) => {
                        e.preventDefault();
                        setCategory(e.target.value);
                        onSearch(value, e.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option value={ProductCategory.Materials}>
                        Materiales
                      </option>
                    </AppInput>
                  </Col>

                  {/* <Col md="3" lg="2"> */}
                  {/*   <Input */}
                  {/*     className="mt-3 mt-md-0" */}
                  {/*     style={{ */}
                  {/*       border: '1px solid rgba(0, 0, 0, 0.05)', */}
                  {/*       paddingLeft: '8px', */}
                  {/*       paddingRight: '8px' */}
                  {/*     }} */}
                  {/*     type="select" */}
                  {/*     onChange={(e) => { */}
                  {/*       setCategory(e.target.value); */}
                  {/*       onSearch(value, e.target.value); */}
                  {/*     }} */}
                  {/*   > */}
                  {/*     <option value="">Otras categorías</option> */}
                  {/*     <option value="1">Materiales</option> */}
                  {/*   </Input> */}
                  {/* </Col> */}
                </Row>

                {isLoading ? (
                  <div
                    style={{
                      width: '100%',
                      marginTop: '30px',
                      marginBottom: '30px'
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Spinner />
                  </div>
                ) : products.length > 0 ? (
                  <>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                      striped
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>Nombre</th>
                          <th>SKU</th>
                          <th>Tipo</th>
                          <th>Precio de venta</th>
                          <th>Costo</th>
                          <th>Disponibles</th>
                          <th>Punto de reorden</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>{renderProducts()}</tbody>
                    </Table>

                    <CardFooter className="py-4">
                      <nav aria-label="...">{renderPagination()}</nav>
                    </CardFooter>
                  </>
                ) : (
                  <CardBody className="text-center">
                    <p className="text-muted">No existen productos</p>
                  </CardBody>
                )}
              </Card>
            </Col>
          ) : null}

          <div className="col-12">
            <div className="my-3">
              <small>Total de productos {count}</small>
            </div>
          </div>
        </Row>

        <Row></Row>
      </Container>
    </>
  );
}

export default AppProductList;
