/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from 'chart.js';
// react plugin used to create charts
import { Line, Bar } from 'react-chartjs-2';
// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';

import withAuth from 'hoc/withAuth';
import CardsHeader from 'components/Headers/CardsHeader.js';
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from 'variables/charts.js';
import AccountsReceivableAPI from 'services/api/accountReceivable';
import { TransactionKind } from '../constants';
import { useAuth } from 'hooks/use-auth';
import moment from 'utils/moment';

function Home() {
  const { isAdmin, isAdminReadOnly } = useAuth();
  const [transactions, setTransactions] = React.useState([]);
  const [dataSet, setDataSet] = React.useState(null);
  const [dataSetTwo, setDataSetTwo] = React.useState(null);
  const [isLoadingTransactions, setIsLoadingTransactions] =
    React.useState(true);

  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState('data1');
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === 'data1' ? 'data2' : 'data1');
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  React.useEffect(() => fetchTransactions(), []);

  const fetchTransactions = async () => {
    setIsLoadingTransactions(true);
    try {
      let results = (await AccountsReceivableAPI.getAllTransactions()).sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
      setTransactions(results);

      let dataOne = {
        labels: [],
        datasets: [
          {
            label: 'Cobrar',
            data: []
          }
        ]
      };

      let dataTwo = {
        labels: [],
        datasets: [
          {
            label: 'Ventas',
            data: []
          }
        ]
      };

      for (let i = 5, len = 0; i >= len; i--) {
        let amount = 0.0;
        let toMonth = moment()
          .endOf('month')
          .subtract(i, 'month')
          .endOf('month');
        let out = [];

        // Sums transactions that are lower than that month
        for (let j = 0, len = results.length; j < len; j++) {
          if (!moment(results[j].date).isSameOrBefore(toMonth.endOf('month')))
            continue;

          if (results[j].kind === TransactionKind.Invoice) {
            amount += results[j].total / 100000000;
          }
        }

        for (let j = 0, len = results.length; j < len; j++) {
          if (
            moment(results[j].date).isSameOrAfter(toMonth.startOf('month')) &&
            moment(results[j].date).isSameOrBefore(toMonth.endOf('month')) &&
            results[j].kind === TransactionKind.Invoice
          ) {
            out.push(results[j]._id);
          }
        }

        // Add total to chart list
        dataOne.labels.push(toMonth.format('MMM'));
        dataTwo.labels.push(toMonth.format('MMM'));
        dataOne.datasets[0].data.push(amount.toFixed(2));
        dataTwo.datasets[0].data.push(out.length);
      }

      setDataSet(dataOne);
      setDataSetTwo(dataTwo);
      setIsLoadingTransactions(false);
    } catch (e) {
      setIsLoadingTransactions(false);
      console.log(e);
    }
  };

  return (
    <>
      <CardsHeader name="Inicio" parentName="Panel" />

      <Container fluid className="mt--6">
        {isAdmin() || isAdminReadOnly() ? (
          <Row>
            <Col xl="8">
              <Card className="bg-neutral">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-dark text-uppercase ls-1 mb-1">
                        Vista general
                      </h6>
                      <h5 className="h3 text-dark mb-0">
                        Facturación a la fecha
                      </h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {dataSet ? (
                    <div className="chart">
                      <Line
                        data={dataSet}
                        options={chartExample1.options}
                        id="chart-sales-dark"
                        className="chart-canvas"
                      />
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Rendimiento
                      </h6>

                      <h5 className="h3 mb-0">Ventas totales</h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {dataSetTwo ? (
                    <div className="chart">
                      <Bar
                        data={dataSetTwo}
                        options={chartExample2.options}
                        className="chart-canvas"
                        id="chart-bars"
                      />
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}
      </Container>
    </>
  );
}

export default withAuth(Home);
