import axios, { requestToken } from '../../utils/axios';
import toast from 'react-hot-toast';

const getAll = async (productId, page, itemsPerPage, value) => {
  try {
    const response = await axios.get(
      `/api/products/${productId}/serials?search=${JSON.stringify({
        page,
        itemsPerPage,
        value
      })}`,
      {
        cancelToken: requestToken()
      }
    );
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const create = async (serialId, payload) => {
  try {
    const response = await axios.post(
      `/api/products/${serialId}/serials`,
      payload
    );
    toast.success(response.data.message, {
      style: {
        animation: 'none'
      }
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const deleteOne = async (id) => {
  try {
    const response = await axios.delete(`/api/serials/${id}`);
    toast.success(response.data.message, {
      style: {
        animation: 'none'
      }
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

export default {
  getAll,
  create,
  deleteOne
};
