import React, { forwardRef } from 'react';

import { FormGroup, Input } from 'reactstrap';

const AppInput = (props, ref) => {
  const { label, error, isTableInput, hasError, bsSize, style, ...remainingProps } = props;

  return (
    <FormGroup className={`${isTableInput ? 'form-group--table' : ''}`.trim()} style={style}>
      {label && remainingProps.type !== 'checkbox' ? (
        <label htmlFor={remainingProps.name} className="form-control-label">
          {label}
        </label>
      ) : null}
      <div style={{ position: 'relative' }}>
        <Input
          bsSize={bsSize || "sm"}
          {...remainingProps}
          ref={ref}
          className={`${hasError || error ? 'has-error' : ''} ${
            error ? 'with-label' : ''
          } ${props.className ? props.className : ''}`}
        />
      </div>
      {error && <span className="form-error__label">{error}</span>}
    </FormGroup>
  );
};

export default forwardRef(AppInput);
