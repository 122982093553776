import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Spinner,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Label
} from 'reactstrap';

import moment from 'utils/moment';
import Header from 'components/Headers/AlternativeHeader';
import { getFileObject } from '../../utils';
import AppInput from 'components/Forms/AppInput';

function AppFileList({
  isLoading,
  files,
  count,
  page,
  itemsPerPage,
  onPageClick,
  onFileDelete,
  onSearch
}) {
  const [value, setValue] = useState('');

  const renderPagination = () => {
    const actualPage = page + 1;
    const totalPages =
      count > 0 && itemsPerPage > 0 ? Math.ceil(count / itemsPerPage) : 0;
    if (totalPages === 0) return null;

    const hasPrev = page > 0;
    const hasNext = actualPage < totalPages;
    const totalNextPages = totalPages - actualPage;
    let numbers = [];

    for (let i = 0, len = totalNextPages + 1; i < len; i++) {
      numbers.push(
        <PaginationItem
          key={`pagination-number-${actualPage + i}`}
          className={i === 0 ? 'active' : ''}
        >
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              onPageClick(page + i, value);
            }}
          >
            {actualPage + i}
          </PaginationLink>
        </PaginationItem>
      );
      if (i === 2) break;
    }

    return (
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={hasPrev ? '' : 'disabled'}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasPrev) {
                onPageClick(page - 1, value);
              }
            }}
            tabIndex="-1"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>

        {numbers}

        <PaginationItem className={hasNext ? '' : 'disabled'}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasNext) {
                onPageClick(page + 1, value);
              }
            }}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  };

  const getFileIcon = (s3Key) => {
    const kind = s3Key.split('/');

    switch (kind[0]) {
      case 'documents-customer':
        return <i className="fas fa-folder"></i>;
      case 'credit-notes':
        return <i className="fas fa-file-invoice-dollar"></i>;
      case 'vouchers':
        return <i className="fas fa-receipt"></i>;
      case 'proposals':
        return <i className="fas fa-scroll"></i>;
      case 'avatars':
        return <i className="fas fa-user-circle"></i>;
      case 'invoices':
        return <i className="fas fa-file-invoice"></i>;
      case 'documents-user':
        return <i className="fas fa-user-shield"></i>;
      default:
        return <i className="fas fa-question"></i>;
    }
  };

  const getFileName = (s3Key) => {
    const kind = s3Key.split('/');
    return kind[kind.length - 1];
  };

  const renderItems = () => {
    return files.map((f, uIndex) => (
      <tr key={`files-${uIndex}`}>
        <td className="table-file">
          <a
            href={getFileObject(f.s3Key)}
            target="_blank"
            style={{ fontSize: '20px' }}
            className="avatar bg-default rounded-square mr-3 page-link"
          >
            {getFileIcon(f.s3Key)}
            {/* <img */}
            {/*   alt="..." */}
            {/*   className="avatar rounded-square mr-3" */}
            {/*   src={getFileObject(f.s3Key)} */}
            {/* /> */}
          </a>
        </td>
        <td>
          <a href={getFileObject(f.s3Key)} target="_blank">
            <strong>{getFileName(f.s3Key)}</strong>
          </a>
        </td>
        <td>{moment(f.createdAt).format('ddd D MMM, h:mm a')}</td>
        <td className="table-actions">
          <a
            className="table-action table-action-delete"
            href="#"
            id="tooltip209424781"
            onClick={(e) => {
              e.preventDefault();
              onFileDelete(f._id);
            }}
          >
            <i className="fas fa-trash" />
          </a>
          <UncontrolledTooltip delay={0} target="tooltip209424781">
            Eliminar Archivo
          </UncontrolledTooltip>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Header name="Archivos" />

      <Container fluid className="mt--6">
        <Row>
          <Col>
            <Card className="mb-0">
              <Row className="px-3 pt-3">
                <Col md="3">
                  <AppInput
                    label="Tipo de archivo"
                    type="select"
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value);
                      onSearch(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    <option value="invoices">Facturas</option>
                    <option value="vouchers">Vouchers</option>
                    <option value="credit-notes">Nota de Crédito</option>
                    <option value="proposals">Propuestas</option>
                    <option value="documents-customer">
                      Documentos de clientes
                    </option>
                    <option value="avatars">Avatars de usuarios</option>
                  </AppInput>
                </Col>
              </Row>

              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    marginTop: '30px',
                    marginBottom: '30px'
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Spinner />
                </div>
              ) : files.length > 0 ? (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  striped
                >
                  <thead className="thead-light">
                    <tr>
                      <th>Preview</th>
                      <th>Nombre</th>
                      <th>Creación</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{renderItems()}</tbody>
                </Table>
              ) : (
                <CardBody className="text-center">
                  <p className="text-muted">No existen archivos</p>
                </CardBody>
              )}

              <CardFooter className="py-4">
                <nav aria-label="...">{renderPagination()}</nav>
              </CardFooter>
            </Card>

            <div className="my-3">
              <small>Total de archivos {count}</small>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AppFileList;
