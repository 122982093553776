import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Col,
  Row,
  Media,
  Form,
  Input,
  UncontrolledTooltip,
  Spinner,
  Badge
} from 'reactstrap';
import moment from 'utils/moment';

import { useAuth } from 'hooks/use-auth';
import { getFileObject, getName } from 'utils';
import { NotificationKind, TransactionKind } from '../../constants';
import AccountsReceivableAPI from 'services/api/accountReceivable';
import NotesAPI from 'services/api/notes';

function AppNotes({ resourceId }) {
  const { user, isAdmin } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const commentsRef = useRef(null);

  useEffect(() => fetchNotes(), []);

  const fetchNotes = async () => {
    try {
      const result = await NotesAPI.getAll(page, resourceId);
      setNotes(result.notes);
      setCount(result.count);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      /* handle error */
    }
  };

  const renderNotes = () => {
    if (isLoading) return '';

    if (notes.length === 0) {
      return (
        <p className="text-muted text-center m-0 p-0">No existen comentarios.</p>
      );
    }

    return notes.map((n) => (
      <Media key={n._id} className="media-comment">
        <img
          alt="..."
          className="avatar avatar-md media-comment-avatar rounded-circle"
          src={
            n.user && n.user.avatar && n.user.avatar !== 'noProfileImage.jpg'
              ? getFileObject(n.user.avatar)
              : '/noProfileImage.jpg'
          }
        />

        <Media>
          <div className="media-comment-text">
            <h6 className="h5 mt-0">{getName(n.user)}</h6>

            <p className="text-sm lh-160 mb-1" style={{ fontWeight: '600' }}>
              {n.value}
            </p>

            <div className="icon-actions">
              <a
                className="like active"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                <small className="text-muted">
                  {moment(n.createdAt).format('lll')}
                </small>
              </a>
              {isAdmin() ? (
                <a
                  href="#pablo"
                  onClick={async (e) => {
                    e.preventDefault();
                    if (
                      !window.confirm(
                        '¿Seguro que quieres eliminar este comentario?'
                      )
                    )
                      return;
                    try {
                      await NotesAPI.destroy(n._id);
                      await fetchNotes();
                      commentsRef.current.scrollTop = 0;
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                >
                  <small className="text-muted">
                    <i className="fas fa-trash" />
                    <span className="text-muted">Eliminar</span>
                  </small>
                </a>
              ) : null}
            </div>
          </div>
        </Media>
      </Media>
    ));
  };

  return (
    <Card>
      <CardHeader>
        <h5 className="h3 mb-0">Comentarios</h5>
      </CardHeader>

      <CardBody>
        <div
          ref={commentsRef}
          style={{
            maxHeight: '400px',
            overflowY: 'auto'
          }}
        >
          {renderNotes()}
        </div>

        <hr />

        <Media className="align-items-center">
          <Media body>
            <Form>
              <Input
                placeholder="Escribe tu comentario"
                rows="1"
                type="textarea"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                onKeyPress={async (e) => {
                  if (e.charCode === 13) {
                    e.preventDefault();
                    const noteTmp = note;
                    setNote('');
                    try {
                      await NotesAPI.create({
                        resourceId: resourceId,
                        value: e.target.value
                      });
                      await fetchNotes();
                      commentsRef.current.scrollTop = 0;
                    } catch (e) {
                      setNote(noteTmp);
                      console.log(e);
                    }
                  }
                }}
              />
            </Form>
          </Media>
        </Media>
      </CardBody>
    </Card>
  );
}

export default AppNotes;
