import axios from '../../utils/axios';
import toast from 'react-hot-toast';

const signin = async (email) => {
  try {
    const response = await axios.post('/api/signin-request', {
      email
    });
    toast.success(response.data.message, {
      style: {
        animation: 'none'
      }
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }

      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const getUser = async () => {
  try {
    const response = await axios.get('/api/account');
    if (response && response.data) return response.data;
    return {};
  } catch (e) {
    if (e && e.response && e.response.data)
      return Promise.reject(e.response.data);
    return Promise.reject(e);
  }
};

export default {
  signin,
  getUser
};
