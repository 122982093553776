import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  Card,
  CardBody,
  CardFooter,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  Button,
  ModalFooter,
} from "reactstrap";

import Header from "components/Headers/AlternativeHeader";
import {
  ProductCategory,
  ProjectStatus,
  RejectionStatus,
} from "../../constants";
import { getName } from "utils";
import { getProjectStatusClass } from "utils";
import moment from "utils/moment";
import { getElectricalUnit } from "utils";
import AppInput from "components/Forms/AppInput";
moment.locale("es-do");

function AppProjectList({
  isLoading,
  projects,
  allProjects,
  count,
  page,
  itemsPerPage,
  onSearch,
  onPageClick,
  onProjectClick,
  onDelete,
  onNewClick,
}) {
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = useState("");
  const [status, setStatus] = useState("");
  const [rsStatus, setRsStatus] = useState("");
  const [projectPreview, setProjectPreview] = useState(null);

  useEffect(() => {
    const vParam = new URLSearchParams(location.search).get("v");
    const cParam = new URLSearchParams(location.search).get("c");
    const rsParam = new URLSearchParams(location.search).get("rs");

    if (vParam) {
      setValue(vParam);
    }

    if (cParam) {
      setStatus(cParam);
    }

    if (rsParam) {
      setRsStatus(rsParam);
    }

    // if (vParam && cParam) {
    //   setValue(vParam);
    //   setStatus(cParam);
    // } else if (vParam) {
    //   setValue(vParam);
    //   onSearch(vParam);
    // } else if (cParam) {
    //   setStatus(cParam);
    //   onSearch(undefined, cParam);
    //   onSearch(vParam, cParam);
    // }

    if (!vParam && !cParam && !rsParam) {
      onSearch();
    } else {
      onSearch(vParam, cParam, rsParam);
    }
  }, [location]);

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   params.delete('c');
  //   params.delete('rs');

  //   if (status) {
  //     params.append('c', status);
  //   }

  //   if (rsStatus) {
  //     params.append('rs', rsStatus);
  //   }

  //   history.push({ search: params.toString() });
  //   onSearch(value, status, rsStatus);
  // }, [status, rsStatus]);

  const getStatusCount = (status) => {
    return (
      allProjects.reduce((a, b) => (b.status === status ? a + 1 : a), 0) || "-"
    );
  };

  const getExpirationDate = (p) => {
    if (p.status === ProjectStatus.FirstInProgress) {
      return moment(p.submission.firstVisitExpiresAt).fromNow();
    } else if (
      p.fileNoObjectionLetter &&
      (p.status === ProjectStatus.FirstInProgress ||
        p.status === ProjectStatus.SecondReadyToSend)
    ) {
      return moment(p.submission.noObjectionSubmittedAt)
        .add(60, "days")
        .fromNow();
    } else if (p.status === ProjectStatus.SecondInProgress) {
      return moment(p.submission.secondVisitExpiresAt).fromNow();
    } else if (p.status === ProjectStatus.CounterPendingPayment) {
      return moment(p.submission.meterPaymentRequestedAt)
        .add(30, "days")
        .fromNow();
    } else if (p.status === ProjectStatus.CounterReadyToInstall) {
      return moment(p.submission.meterPaymentRequestedAt)
        .add(30, "days")
        .fromNow();
    } else {
      return "-";
    }
  };

  const getExpirationDateColor = (p) => {
    if (
      p.status !== ProjectStatus.FirstInProgress &&
      p.status !== ProjectStatus.SecondInProgress &&
      p.status !== ProjectStatus.CounterPendingPayment &&
      p.status !== ProjectStatus.CounterReadyToInstall
    ) {
      return "";
    } else if (
      p.status === ProjectStatus.FirstInProgress &&
      moment(p.submission.firstVisitExpiresAt).isBefore(moment())
    ) {
      return "#FF6961";
    } else if (
      p.status === ProjectStatus.FirstInProgress &&
      moment(p.submission.firstVisitExpiresAt).isAfter(
        moment().subtract(7, "days")
      )
    ) {
      return "#ffb347";
    } else if (
      p.status === p.fileNoObjectionLetter &&
      (p.status === ProjectStatus.FirstInProgress ||
        p.status === ProjectStatus.SecondReadyToSend) &&
      moment(p.submission.noObjectionSubmittedAt)
        .add(60, "days")
        .isBefore(moment())
    ) {
      return "#FF6961";
    } else if (
      p.status === p.fileNoObjectionLetter &&
      (p.status === ProjectStatus.FirstInProgress ||
        p.status === ProjectStatus.SecondReadyToSend) &&
      moment(p.submission.noObjectionSubmittedAt)
        .add(60, "days")
        .isAfter(moment().subtract(7, "days"))
    ) {
      return "#ffb347";
    } else if (
      p.status === ProjectStatus.SecondInProgress &&
      moment(p.submission.secondVisitExpiresAt).isBefore(moment())
    ) {
      return "#FF6961";
    } else if (
      p.status === ProjectStatus.SecondInProgress &&
      moment(p.submission.secondVisitExpiresAt).isAfter(
        moment().subtract(7, "days")
      )
    ) {
      return "#ffb347";
    } else if (
      p.status === ProjectStatus.CounterPendingPayment &&
      p.meterPaymentRequestedAt &&
      moment(p.meterPaymentRequestedAt).add(30, "days").isBefore(moment())
    ) {
      return "#FF6961";
    } else if (
      p.status === ProjectStatus.CounterPendingPayment &&
      p.meterPaymentRequestedAt &&
      moment(p.meterPaymentRequestedAt)
        .add(30, "days")
        .isAfter(moment().subtract(7, "days"))
    ) {
      return "#ffb347";
    } else if (
      p.status === ProjectStatus.CounterReadyToInstall &&
      p.meterInstalationRequestedAt &&
      moment(p.meterInstalationRequestedAt).add(19, "days").isBefore(moment())
    ) {
      return "#FF6961";
    } else if (
      p.status === ProjectStatus.CounterReadyToInstall &&
      p.meterInstalationRequestedAt &&
      moment(p.meterInstalationRequestedAt)
        .add(19, "days")
        .isAfter(moment().subtract(7, "days"))
    ) {
      return "#ffb347";
    } else {
      return "";
    }
  };

  const toggleStatus = (s) => {
    if (getStatusCount(s) === "-") {
      return;
    }

    const params = new URLSearchParams(location.search);
    params.delete("c");

    if (s === status) {
      setStatus("");
    } else {
      params.append("c", s);
      setStatus(s);
    }

    history.push({ search: params.toString() });
  };

  const renderPagination = () => {
    const actualPage = page + 1;
    const totalPages =
      count > 0 && itemsPerPage > 0 ? Math.ceil(count / itemsPerPage) : 0;
    if (totalPages === 0) return null;

    const hasPrev = page > 0;
    const hasNext = actualPage < totalPages;
    const totalNextPages = totalPages - actualPage;
    let numbers = [];

    for (let i = 0, len = totalNextPages + 1; i < len; i++) {
      numbers.push(
        <PaginationItem
          key={`pagination-number-${actualPage + i}`}
          className={i === 0 ? "active" : ""}
        >
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              onPageClick(page + i, value, status, rsStatus);
            }}
          >
            {actualPage + i}
          </PaginationLink>
        </PaginationItem>
      );
      if (i === 2) break;
    }

    return (
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={hasPrev ? "" : "disabled"}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasPrev) {
                onPageClick(page - 1, value, status, rsStatus);
              }
            }}
            tabIndex="-1"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
        {numbers}
        <PaginationItem className={hasNext ? "" : "disabled"}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasNext) {
                onPageClick(page + 1, value, status, rsStatus);
              }
            }}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  };

  const renderProjects = () => {
    return projects.map((p, uIndex) => (
      <tr key={`projects-${uIndex}`}>
        <td className="table-actions">
          <a
            className="table-action"
            href={`/proyectos?id=${p._id}&edit=1`}
            id="tooltip229424781"
          >
            <i className="fas fa-edit" />
          </a>
          <UncontrolledTooltip delay={0} target="tooltip229424781">
            Editar Proyecto
          </UncontrolledTooltip>
          <a
            className="table-action table-action-delete"
            href="#"
            id="tooltip12475025"
            onClick={(e) => {
              e.preventDefault();
              setProjectPreview(p);
              document.addEventListener(
                "keydown",
                (e) => {
                  if (e.key === "Escape") {
                    setProjectPreview(null);
                  }
                },
                { once: true }
              );
            }}
          >
            <i className="fas fa-eye" />
          </a>
          <UncontrolledTooltip delay={0} target="tooltip12475025">
            Vista previa
          </UncontrolledTooltip>
          <a
            className="table-action"
            href="#"
            id="tooltip209424781"
            onClick={(e) => {
              e.preventDefault();
              onProjectClick(p._id, false);
            }}
          >
            <i className="fas fa-user-edit" />
          </a>
          <UncontrolledTooltip delay={0} target="tooltip209424781">
            Editar Proyecto
          </UncontrolledTooltip>
          <a
            className="table-action table-action-delete"
            href="#"
            id="tooltip12475020"
            onClick={(e) => {
              e.preventDefault();
              onDelete(p._id);
            }}
          >
            <i className="fas fa-trash" />
          </a>
          <UncontrolledTooltip delay={0} target="tooltip12475020">
            Eliminar Proyecto
          </UncontrolledTooltip>
        </td>
        <td className="table-project">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onProjectClick(p._id, true);
            }}
          >
            <b>{p.name}</b>
          </a>
        </td>
        {p.customer ? (
          <td>
            {getName(p.customer)}{" "}
            {p.customer.alias ? `(${p.customer.alias})` : ""}
          </td>
        ) : (
          <td></td>
        )}
        <td>
          <span className={`project-label ${getProjectStatusClass(p.status)}`}>
            {p.status}
          </span>
        </td>
        <td
          style={{
            color: getExpirationDateColor(p),
            fontWeight: 600,
          }}
        >
          {getExpirationDate(p)}
        </td>
        <td>{p.representative}</td>
      </tr>
    ));
  };

  return (
    <>
      <Header name="" parentName="Proyectos" />

      <Container fluid className="mt--6">
        <Row>
          <Col md="12">
            <h3>Medición neta</h3>
          </Col>

          <Col lg="3">
            <Card className="projects-overview-card projects-overview-card--blue">
              <CardBody>
                <h3 className="projects-overview__title">PRIMERA VISITA</h3>

                <ul className="projects-overview-list">
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.FirstPending);
                      }}
                      className={`projects-overview-list__item${
                        status === ProjectStatus.FirstPending
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.FirstPending) === "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>{getStatusCount(ProjectStatus.FirstPending)}</span>{" "}
                      Preparar sometimiento
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.FirstReadyToSend);
                      }}
                      className={`projects-overview-list__item projects-overview-list__item--yellow${
                        status === ProjectStatus.FirstReadyToSend
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.FirstReadyToSend) ===
                            "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>
                        {getStatusCount(ProjectStatus.FirstReadyToSend)}
                      </span>{" "}
                      Listo para someter
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.FirstRejected);
                      }}
                      className={`projects-overview-list__item projects-overview-list__item--red${
                        status === ProjectStatus.FirstRejected
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.FirstRejected) === "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>{getStatusCount(ProjectStatus.FirstRejected)}</span>{" "}
                      No cumple
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.FirstInProgress);
                      }}
                      className={`projects-overview-list__item${
                        status === ProjectStatus.FirstInProgress
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.FirstInProgress) ===
                            "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>
                        {getStatusCount(ProjectStatus.FirstInProgress)}
                      </span>{" "}
                      Sometimiento en proceso
                    </a>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>

          <Col lg="3">
            <Card className="projects-overview-card projects-overview-card--orange">
              <CardBody>
                <h3 className="projects-overview__title">SEGUNDA VISITA</h3>

                <ul className="projects-overview-list">
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.SecondPending);
                      }}
                      className={`projects-overview-list__item${
                        status === ProjectStatus.SecondPending
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.SecondPending) === "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>{getStatusCount(ProjectStatus.SecondPending)}</span>{" "}
                      Preparar sometimiento
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.SecondReadyToSend);
                      }}
                      className={`projects-overview-list__item projects-overview-list__item--yellow${
                        status === ProjectStatus.SecondReadyToSend
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.SecondReadyToSend) ===
                            "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>
                        {getStatusCount(ProjectStatus.SecondReadyToSend)}
                      </span>{" "}
                      Listo para someter
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.SecondRejected);
                      }}
                      className={`projects-overview-list__item projects-overview-list__item--red${
                        status === ProjectStatus.SecondRejected
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.SecondRejected) === "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>
                        {getStatusCount(ProjectStatus.SecondRejected)}
                      </span>{" "}
                      No cumple
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.SecondInProgress);
                      }}
                      className={`projects-overview-list__item${
                        status === ProjectStatus.SecondInProgress
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.SecondInProgress) ===
                            "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>
                        {getStatusCount(ProjectStatus.SecondInProgress)}
                      </span>{" "}
                      Sometimiento en proceso
                    </a>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>

          <Col lg="3">
            <Card className="projects-overview-card projects-overview-card--yellow">
              <CardBody>
                <h3 className="projects-overview__title">ACUERDO</h3>

                <ul className="projects-overview-list">
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.AgreementPending);
                      }}
                      className={`projects-overview-list__item${
                        status === ProjectStatus.AgreementPending
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.AgreementPending) ===
                            "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>
                        {getStatusCount(ProjectStatus.AgreementPending)}
                      </span>{" "}
                      Acuerdo pendiente
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.AgreementSent);
                      }}
                      className={`projects-overview-list__item projects-overview-list__item--yellow${
                        status === ProjectStatus.AgreementSent
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.AgreementSent) === "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>{getStatusCount(ProjectStatus.AgreementSent)}</span>{" "}
                      Acuerdo enviado a firmar
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.CounterPending);
                      }}
                      className={`projects-overview-list__item${
                        status === ProjectStatus.CounterPending
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.CounterPending) === "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>
                        {getStatusCount(ProjectStatus.CounterPending)}
                      </span>{" "}
                      En espera carta medidor
                    </a>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>

          <Col lg="3">
            <Card className="projects-overview-card projects-overview-card--purple">
              <CardBody>
                <h3 className="projects-overview__title">MEDIDOR</h3>

                <ul className="projects-overview-list">
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.CounterPendingReceipt);
                      }}
                      className={`projects-overview-list__item${
                        status === ProjectStatus.CounterPendingReceipt
                          ? " projects-overview-list__item--active"
                          : getStatusCount(
                              ProjectStatus.CounterPendingReceipt
                            ) === "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>
                        {getStatusCount(ProjectStatus.CounterPendingReceipt)}
                      </span>{" "}
                      Cargo pendiente
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.CounterPendingPayment);
                      }}
                      className={`projects-overview-list__item projects-overview-list__item--yellow${
                        status === ProjectStatus.CounterPendingPayment
                          ? " projects-overview-list__item--active"
                          : getStatusCount(
                              ProjectStatus.CounterPendingPayment
                            ) === "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>
                        {getStatusCount(ProjectStatus.CounterPendingPayment)}
                      </span>{" "}
                      Pago pendiente
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.CounterReadyToInstall);
                      }}
                      className={`projects-overview-list__item projects-overview-list__item--yellow${
                        status === ProjectStatus.CounterReadyToInstall
                          ? " projects-overview-list__item--active"
                          : getStatusCount(
                              ProjectStatus.CounterReadyToInstall
                            ) === "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>
                        {getStatusCount(ProjectStatus.CounterReadyToInstall)}
                      </span>{" "}
                      Instalación pendiente
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.CounterInstalled);
                      }}
                      className={`projects-overview-list__item${
                        status === ProjectStatus.CounterInstalled
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.CounterInstalled) ===
                            "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>
                        {getStatusCount(ProjectStatus.CounterInstalled)}
                      </span>{" "}
                      Instalado
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleStatus(ProjectStatus.Running);
                      }}
                      className={`projects-overview-list__item projects-overview-list__item--green${
                        status === ProjectStatus.Running
                          ? " projects-overview-list__item--active"
                          : getStatusCount(ProjectStatus.Running) === "-"
                          ? " projects-overview-list__item--empty"
                          : ""
                      }`}
                    >
                      <span>{getStatusCount(ProjectStatus.Running)}</span>{" "}
                      Arrancado
                    </a>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>

          <Col>
            <Card className="mb-0">
              <Row className="px-3 py-3">
                <Col md="12" lg="4">
                  <Input
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.05)",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                    type="search"
                    placeholder="Nombre, cliente, contrato o medidor..."
                    value={value}
                    onChange={(e) => {
                      const params = new URLSearchParams(location.search);
                      params.delete("v");
                      params.append("v", e.target.value);
                      history.push({ search: params.toString() });
                      setValue(e.target.value);
                      // onSearch(e.target.value);
                    }}
                  />
                </Col>

                <Col md="3">
                  <AppInput
                    bsSize="md"
                    type="select"
                    value={rsStatus}
                    onChange={(e) => {
                      const params = new URLSearchParams(location.search);
                      params.delete("rs");
                      params.append("rs", e.target.value);
                      history.push({ search: params.toString() });
                      setRsStatus(e.target.value);
                      // onSearch(e.target.value);
                    }}
                  >
                    {Object.values(RejectionStatus).map((rep, repIndex) => (
                      <option
                        key={`project-representatives-${repIndex}`}
                        value={rep}
                      >
                        {rep === "" ? "Estado del rechazo" : rep}
                      </option>
                    ))}
                  </AppInput>
                </Col>

                {status ? (
                  <Col md="12" lg="4">
                    <div className="pt-2">
                      <a
                        className="badge"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleStatus(status);
                        }}
                      >
                        {status} <i className="ml-1 fas fa-times"></i>
                      </a>
                    </div>
                  </Col>
                ) : null}
              </Row>
              {isLoading ? (
                <div
                  style={{
                    width: "100%",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Spinner />
                </div>
              ) : projects.length > 0 ? (
                <>
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    striped
                  >
                    <thead className="thead-light">
                      <tr>
                        <th />
                        <th>Nombre</th>
                        <th>Cliente</th>
                        <th>Estado</th>
                        <th>Vence en</th>
                        <th>Representante</th>
                      </tr>
                    </thead>
                    <tbody>{renderProjects()}</tbody>
                  </Table>

                  <CardFooter className="py-4">
                    <nav aria-label="...">{renderPagination()}</nav>
                  </CardFooter>
                </>
              ) : (
                <CardBody className="text-center">
                  <p className="text-muted">No existen proyectos</p>
                </CardBody>
              )}
            </Card>

            <div className="my-3">
              <small>Total de proyectos {count}</small>
            </div>
          </Col>
        </Row>
      </Container>

      {projectPreview ? (
        <Modal
          fade={false}
          isOpen={!!projectPreview}
          size="md"
          className="modal-dialog-centered modal-secondary"
        >
          <ModalBody className="py-0">
            <div className="submission-components">
              <div className="submission-components-header">
                <h3 className="submission-components-header__title">
                  <span>Equipos</span>
                </h3>

                <div className="submission-components-summary">
                  <img
                    alt="..."
                    className="inventory-categories-item__img"
                    src={require("assets/img/icons/solar-panels.png").default}
                  />
                  {getElectricalUnit(
                    projectPreview.components.reduce(
                      (a, b) =>
                        b.product.category === ProductCategory.SolarPanels
                          ? a + b.product.capacity * b.qty
                          : a,
                      0
                    )
                  )}
                  p
                  <img
                    className="inventory-categories-item__img"
                    src={require("assets/img/icons/generator.png").default}
                  />
                  {getElectricalUnit(
                    projectPreview.components.reduce(
                      (a, b) =>
                        b.product.category === ProductCategory.Inverters
                          ? a + b.product.capacity * b.qty
                          : a,
                      0
                    ) * 1000
                  )}
                </div>
              </div>

              <div className="submission-components-container">
                {projectPreview.components.length === 0 ? (
                  <small style={{ textAlign: "center" }}>
                    No has seleccionado equipos
                  </small>
                ) : null}

                {projectPreview.components.map((c, cIndex) => (
                  <div
                    className="submission-component"
                    key={`submission-component-${cIndex}`}
                  >
                    <div className="submission-component__name">
                      <a
                        href={`/productos?id=${c.product._id}`}
                        target="_blank"
                      >
                        {c.product.category === ProductCategory.Inverters
                          ? "Inversor "
                          : "Panel "}
                        {c.product.name}
                      </a>
                    </div>

                    <div className="submission-component__capacity">
                      <span>{c.qty}</span>
                      <span>
                        <i className="fas fa-times"></i>
                      </span>
                      <span>
                        {c.product.capacity}
                        {c.product.category === ProductCategory.SolarPanels
                          ? "w"
                          : "Kw"}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setProjectPreview(null)}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
}

export default AppProjectList;
