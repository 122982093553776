import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/use-auth';

export function withUnAuth(Component) {
  return (props) => {
    const history = useHistory();
    const { user, isLoading } = useAuth();

    useEffect(() => {
      if (user && !isLoading) history.push('/');
    }, [user, isLoading]);

    return isLoading || user ? null : <Component {...props} />;
  };
}

export default withUnAuth;
