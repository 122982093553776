import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import fileAPI from 'services/api/file';
import AppFileList from 'components/Files/AppFileList';

function Files() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [files, setFiles] = useState([]);
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(location.search).get('page')) - 1 || 0
  );
  const [count, setCount] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => fetchFiles(), []);

  const fetchFiles = async (pageToGo, valueToSearch) => {
    try {
      const { files, count } = await fileAPI.getAll(
        pageToGo >= 0 ? pageToGo : page,
        itemsPerPage,
        valueToSearch && valueToSearch.length > 0 ? valueToSearch : ''
      );
      setFiles(files);
      setCount(count);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      /* handle error */
      console.log(e);
    }
  };

  const handleSearch = (valueToSearch) => {
    fetchFiles(0, valueToSearch);
    setPage(0);
  };

  const handlePageClick = (pageToGo, value) => {
    setPage(pageToGo);
    fetchFiles(pageToGo, value);
  };

  const handleFileDelete = async (id) => {
    try {
      await fileAPI.deleteOne(id);
      await fetchFiles();
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const renderView = () => {
    return (
      <AppFileList
        isLoading={isLoading}
        files={files}
        count={count}
        page={page}
        itemsPerPage={itemsPerPage}
        onPageClick={handlePageClick}
        onFileDelete={handleFileDelete}
        onSearch={handleSearch}
      />
    );
  };

  return renderView();
}

export default Files;
