import React, { useRef, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col
} from 'reactstrap';

import { getName, useForm, validate } from 'utils';
import AlternativeHeader from 'components/Headers/AlternativeHeader';
import AppInput from 'components/Forms/AppInput';
import { formatFilename, getFileObject } from 'utils';
import fileAPI from 'services/api/file';

function AppDataSheetForm({ dataSheet, onSave, onClose, onViewClick }) {
  const avatarFieldRef = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const [enabledButton, setEnabledButton] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const requiredValues = [];

  const submitHandler = async (v) => {
    if (isSending) return;
    setIsSending(true);
    let form = {};

    try {
      Object.entries(dirtyInputs).forEach(([key, value]) => {
        if (value) {
          form[key] = v[key];
        }
      });

      if (file) {
        const signedRequest = await fileAPI.signS3Upload(fileName, file.type);
        await fileAPI.uploadToS3(file, signedRequest);
        await fileAPI.create({
          key: fileName
        });
        form.file = fileName;
      }
      // TODO: Check this out
      // else if (!dataSheet.file && !file) {
      //   alert('Debes adjuntar el dataSheet');
      //   return;
      // }

      // if (dataSheet._id) {
      //   await dataSheetAPI.update(dataSheet._id, { avatar: fileName });
      //   fileAPI.deleteOneByKey(dataSheet.avatar);
      //   setDataSheet(await dataSheetAPI.getOne(dataSheet._id));
      // }

      await onSave(form);
    } catch (e) {
      setIsSending(false);
    }
  };

  const onFileChange = (e) => {
    if (!e.target.files[0]) return;
    setFile(e.target.files[0]);
    setFileName(formatFilename(e.target.files[0].name, 'dataSheets'));
  };

  const {
    values,
    dirtyInputs,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFormValue,
    setFormDirtyValues
  } = useForm(submitHandler, requiredValues, dataSheet, {}, {});

  const setPhoneValue = (oldValue, newValue) => {
    let tmpValue = newValue || '';

    tmpValue = tmpValue.replace(/[^0-9]/g, '');
    if (tmpValue.length > 15) {
      tmpValue = oldValue;
    }

    return tmpValue;
  };

  return (
    <>
      <AlternativeHeader
        name={getName(values)}
        parentName="Data Sheets"
        onBackClick={onClose}
        onNewLabel="Visualizar"
        onNewClick={
          dataSheet._id ? () => onViewClick(dataSheet._id, true) : null
        }
      />

      <Container className="mt--6">
        <Row>
          <Col xl="8">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {dataSheet._id ? 'Editar' : 'Crear'} data sheet
                    </h3>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Form>
                  <Row>
                    <Col sm="12">
                      <Row className="align-items-center mb-3">
                        <Col className="col-auto">
                          <a
                            onClick={(e) => {
                              if (!dataSheet.file) e.preventDefault();
                            }}
                            style={{ height: '32px', width: '32px' }}
                            href={
                              dataSheet.file
                                ? getFileObject(dataSheet.file)
                                : '#'
                            }
                            target="_blank"
                            className={`d-block ${
                              dataSheet.file ? 'bg-primary' : 'bg-gray'
                            } text-white d-flex justify-content-center align-items-center rounded`}
                          >
                            <i className="fas fa-file-alt"></i>
                          </a>
                        </Col>
                        <div className="col">
                          <a
                            onClick={(e) => {
                              if (!dataSheet.file) e.preventDefault();
                            }}
                            style={{ height: '32px', width: '32px' }}
                            href={
                              dataSheet.file
                                ? getFileObject(dataSheet.file)
                                : '#'
                            }
                            target="_blank"
                          >
                            <h5 className="mb-0">
                              {fileName ? fileName : 'Data Sheet'}
                            </h5>
                          </a>
                        </div>
                        <div className="col">
                          <Button
                            color="default"
                            size="sm"
                            className="d-block ml-auto"
                            onClick={(e) => {
                              e.preventDefault();
                              avatarFieldRef.current.click();
                            }}
                          >
                            <i className="fas fa-upload"></i>
                          </Button>
                        </div>
                      </Row>

                      <AppInput
                        label="Nombre"
                        id="name"
                        name="name"
                        type="text"
                        placeholder={file ? file.name : ''}
                        value={values.name || ''}
                        error={errors.name}
                        required
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />


                      <input
                        hidden
                        ref={avatarFieldRef}
                        type="file"
                        onChange={onFileChange}
                      />
                    </Col>
                  </Row>

                  <Button
                    color="primary"
                    type="button"
                    onClick={handleSubmit}
                    className="mt-3"
                  >
                    Guardar
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AppDataSheetForm;
