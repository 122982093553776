/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from 'reactstrap';

import { useAuth } from 'hooks/use-auth';
import withUnAuth from 'hoc/withUnAuth';
import AuthHeader from 'components/Headers/AuthHeader.js';

function Login() {
  const auth = useAuth();
  const [email, setEmail] = React.useState('');
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      await auth.signin(email);
      setIsLoading(false);
      setIsSuccess(true);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AuthHeader
        title="¡Bienvenido!"
        lead="Utiliza tu correo corporativo para recibir un enlace de acceso."
      />

      <Container className="mt--7 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Inicia sesión con enlace</small>
                </div>
                {!isSuccess ? (
                  <Form role="form" onSubmit={handleLogin}>
                    <FormGroup
                      className={classnames('mb-3', {
                        focused: focusedEmail
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Correo Eléctronico"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(false)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="info"
                        type="submit"
                        disabled={isLoading}
                      >
                        Enviar enlace
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <p>
                    <strong>
                      Enviamos el enlace para iniciar sesión a tu correo
                      electronico {email}.
                    </strong>
                  </p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default withUnAuth(Login);
