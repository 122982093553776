import React, { useEffect, useState } from 'react';

import energySupplierAPI from 'services/api/energySupplier';
import AppEnergySupplierList from 'components/EnergySuppliers/AppEnergySupplierList';
import AppEnergySupplierForm from 'components/EnergySuppliers/AppEnergySupplierForm';

function EnergySuppliers({ location, history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [energySuppliers, setEnergySuppliers] = useState([]);
  const [energySupplier, setEnergySupplier] = useState(null);
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(location.search).get('page')) - 1 || 0
  );
  const [count, setCount] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => fetchEnergySuppliers(), []);

  useEffect(() => {
    if (!new URLSearchParams(location.search).get('id')) {
      handleClose();
    }
  }, [location.search]);

  useEffect(() => {
    if (!energySupplier) {
      return;
    }

    if (energySupplier._id) {
      setEnergySuppliers(
        energySuppliers.map((u) =>
          u._id === energySupplier._id ? { ...energySupplier } : u
        )
      );
      if (!new URLSearchParams(location.search).get('id')) {
        const params = new URLSearchParams();
        params.append('id', energySupplier._id);
        history.push({ search: params.toString() });
      }
    }
  }, [energySupplier]);

  const fetchEnergySuppliers = async (pageToGo, valueToSearch) => {
    setIsLoading(true);
    try {
      const { energySuppliers, count } = await energySupplierAPI.getAll(
        pageToGo >= 0 ? pageToGo : page,
        itemsPerPage,
        valueToSearch && valueToSearch.length > 0 ? valueToSearch : ''
      );
      setEnergySuppliers(energySuppliers);
      setCount(count);

      const userId = new URLSearchParams(location.search).get('id');
      if (userId && userId !== 'undefined') {
        setEnergySupplier(await energySupplierAPI.getOne(userId));
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      /* handle error */
      console.log(e);
    }
  };

  const handleSearch = (valueToSearch) => {
    fetchEnergySuppliers(0, valueToSearch);
    setPage(0);
  };

  const handlePageClick = (pageToGo, value) => {
    setPage(pageToGo);
    fetchEnergySuppliers(pageToGo, value);
  };

  const handleEnergySupplierClick = async (id, toView) => {
    try {
      setEnergySupplier(await energySupplierAPI.getOne(id));
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleClose = async () => {
    try {
      setEnergySupplier(null);

      if (new URLSearchParams(location.search).get('id')) {
        const params = new URLSearchParams();
        params.delete('id');
        history.push({ search: params.toString() });
      }
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleSave = async (payload) => {
    try {
      if (energySupplier._id) {
        await energySupplierAPI.update(energySupplier._id, payload);
        setPage(0);
        await fetchEnergySuppliers(0);
      } else {
        await energySupplierAPI.create(payload);
        await fetchEnergySuppliers(0);
        setEnergySupplier(null);
        setPage(0);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('¿Seguro quieres eliminar esta suplidora de energía?')) return;
    if (!window.confirm('¡Después de este punto, no hay vuelta atrás!')) return;
    try {
      await energySupplierAPI.deleteOne(id);
      fetchEnergySuppliers(0);
      setPage(0);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleNewClick = () => {
    setEnergySupplier({});
  };

  const renderView = () => {
    if (!energySupplier) {
      return (
        <AppEnergySupplierList
          isLoading={isLoading}
          energySuppliers={energySuppliers}
          count={count}
          page={page}
          itemsPerPage={itemsPerPage}
          onSearch={handleSearch}
          onPageClick={handlePageClick}
          onEnergySupplierClick={handleEnergySupplierClick}
          onDelete={handleDelete}
          onNewClick={handleNewClick}
        />
      );
    }

    return (
      <AppEnergySupplierForm
        energySupplier={energySupplier}
        onClose={handleClose}
        onSave={handleSave}
        onViewClick={handleEnergySupplierClick}
      />
    );
  };

  return renderView();
}

export default EnergySuppliers;
