/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Link } from 'react-router-dom';

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col
} from 'reactstrap';

function AlternativeHeader({
  name,
  parentName,
  onNewClick,
  onNewLabel,
  onFilterClick,
  customAction,
  onBackClick = () => {}
}) {
  return (
    <>
      <div className="header pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                {name ? (
                  <h6 className="h2 d-inline-block mb-0 mr-md-4">{name}</h6>
                ) : null}
                <Breadcrumb
                  className="d-md-inline-block"
                  listClassName="breadcrumb-links"
                >
                  <BreadcrumbItem>
                    <Link to="/">
                      <i className="fas fa-home" />
                    </Link>
                  </BreadcrumbItem>
                  {parentName ? (
                    <BreadcrumbItem>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          onBackClick();
                        }}
                      >
                        {parentName}
                      </a>
                    </BreadcrumbItem>
                  ) : null}
                  {name ? (
                    <BreadcrumbItem aria-current="page" className="active">
                      {name}
                    </BreadcrumbItem>
                  ) : null}
                </Breadcrumb>
              </Col>
              <Col className="text-right" lg="6" xs="5">
                {onNewClick ? (
                  <Button
                    className="btn-neutral"
                    color=""
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      onNewClick();
                    }}
                    size="sm"
                  >
                    {onNewLabel || 'Nuevo'}
                  </Button>
                ) : null}
                {onFilterClick ? (
                  <Button
                    className="btn-neutral"
                    color=""
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Filtros
                  </Button>
                ) : null}
                {customAction}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AlternativeHeader;
