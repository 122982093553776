import React, { useEffect, useState } from 'react';

import productAPI from 'services/api/product';
import AppProductList from 'components/Products/AppProductList';
import AppProductForm from 'components/Products/AppProductForm';
import { parseAmount } from 'utils';

function Products({ location, history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(location.search).get('page')) - 1 || 0
  );
  const [count, setCount] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => fetchProducts(), []);

  useEffect(() => {
    if (!new URLSearchParams(location.search).get('id')) {
      handleClose();
    }
  }, [location.search]);

  useEffect(() => {
    if (!product) {
      return;
    }

    if (product._id) {
      setProducts(
        products.map((u) => (u._id === product._id ? { ...product } : u))
      );

      if (!new URLSearchParams(location.search).get('id')) {
        const params = new URLSearchParams();
        params.append('id', product._id);
        history.push({ search: params.toString() });
      }
    }
  }, [product]);

  const fetchProducts = async (pageToGo, valueToSearch, categoryId) => {
    setIsLoading(true);
    try {
      const { products, count } = await productAPI.getAll(
        pageToGo >= 0 ? pageToGo : page,
        itemsPerPage,
        valueToSearch && valueToSearch.length > 0 ? valueToSearch : '',
        categoryId && categoryId.length > 0 ? categoryId : ''
      );
      setProducts(products);
      setCount(count);

      const productId = new URLSearchParams(location.search).get('id');
      if (productId && productId !== 'undefined') {
        const result = await productAPI.getOne(productId);
        setProduct({
          ...result,
          price: parseAmount(result.price),
          purchasingPrice: parseAmount(result.purchasingPrice)
        });
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      /* handle error */
      console.log(e);
    }
  };

  const handleSearch = (valueToSearch, categoryId) => {
    fetchProducts(0, valueToSearch, categoryId);
    setPage(0);
  };

  const handlePageClick = (pageToGo, value, categoryId) => {
    setPage(pageToGo);
    fetchProducts(pageToGo, value, categoryId);
  };

  const handleProductClick = async (id) => {
    try {
      const result = await productAPI.getOne(id);
      setProduct({
        ...result,
        price: parseAmount(result.price),
        purchasingPrice: parseAmount(result.purchasingPrice)
      });
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleClose = async () => {
    try {
      setProduct(null);

      if (new URLSearchParams(location.search).get('id')) {
        const params = new URLSearchParams();
        params.delete('id');
        history.push({ search: params.toString() });
      }
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleSave = async (payload) => {
    try {
      if (product._id) {
        if (Object.entries(payload).length > 0) {
          await productAPI.update(product._id, payload);
          await fetchProducts(0);
        }
        setPage(0);
        setProduct(null);

        if (new URLSearchParams(location.search).get('id')) {
          const params = new URLSearchParams();
          params.delete('id');
          console.log(params);
          history.push({ search: params.toString() });
        }
      } else {
        await productAPI.create(payload);
        await fetchProducts(0);

        setProduct(null);
        setPage(0);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('¿Seguro quieres eliminar este producto?')) return;
    if (!window.confirm('¡Después de este punto, no hay vuelta atrás!')) return;
    try {
      await productAPI.deleteOne(id);
      fetchProducts(0);
      setPage(0);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleNewClick = () => {
    setProduct({});
  };

  const renderView = () => {
    if (!product) {
      return (
        <AppProductList
          isLoading={isLoading}
          products={products}
          count={count}
          page={page}
          itemsPerPage={itemsPerPage}
          onSearch={handleSearch}
          onPageClick={handlePageClick}
          onProductClick={handleProductClick}
          onDelete={handleDelete}
          onNewClick={handleNewClick}
        />
      );
    }

    return (
      <AppProductForm
        product={product}
        onClose={handleClose}
        onSave={handleSave}
      />
    );
  };

  return renderView();
}

export default Products;
