import React, { useState } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Spinner,
  Col,
  UncontrolledTooltip
} from 'reactstrap';

import Header from 'components/Headers/AlternativeHeader';
import { getName } from 'utils';
import { getFileObject } from 'utils';
import AppInput from 'components/Forms/AppInput';

function AppUserList({
  isLoading,
  users,
  count,
  page,
  itemsPerPage,
  onSearch,
  onUserClick,
  onDelete,
  onNewClick
}) {
  const [value, setValue] = useState('');

  const renderPagination = () => {
    const actualPage = page + 1;
    const totalPages =
      count > 0 && itemsPerPage > 0 ? Math.ceil(count / itemsPerPage) : 0;
    if (totalPages === 0) return null;

    const hasPrev = page > 0;
    const hasNext = actualPage < totalPages;
    const totalNextPages = totalPages - actualPage;
    let numbers = [];

    for (let i = 0, len = totalNextPages + 1; i < len; i++) {
      numbers.push(
        <PaginationItem
          key={`pagination-number-${actualPage + i}`}
          className={i === 0 ? 'active' : ''}
        >
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              onSearch(value, page + i);
            }}
          >
            {actualPage + i}
          </PaginationLink>
        </PaginationItem>
      );
      if (i === 2) break;
    }

    return (
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={hasPrev ? '' : 'disabled'}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasPrev) {
                onSearch(value, page - 1);
              }
            }}
            tabIndex="-1"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
        {numbers}
        <PaginationItem className={hasNext ? '' : 'disabled'}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasNext) {
                onSearch(value, page + 1);
              }
            }}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  };

  const renderUsers = () => {
    return users.map((u, uIndex) => (
      <tr key={`users-${uIndex}`}>
        <td className="table-user">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onUserClick(u._id);
            }}
          >
            {u.avatar && u.avatar !== 'noProfileImage.jpg' ? (
              <img
                alt="..."
                className="avatar rounded-circle mr-3"
                src={getFileObject(u.avatar)}
              />
            ) : (
              <img
                alt="..."
                className="avatar rounded-circle mr-3"
                src="/noProfileImage.jpg"
              />
            )}
            <b>{getName(u)}</b>
          </a>
        </td>
        <td>{u.position}</td>
        <td>{u.email}</td>
        <td className="table-actions">
          <a
            className="table-action"
            href="#"
            id="tooltip209424781"
            onClick={(e) => {
              e.preventDefault();
              onUserClick(u._id);
            }}
          >
            <i className="fas fa-user-edit" />
          </a>
          <UncontrolledTooltip delay={0} target="tooltip209424781">
            Editar Usuario
          </UncontrolledTooltip>
          <a
            className="table-action table-action-delete"
            href="#"
            id="tooltip12475020"
            onClick={(e) => {
              e.preventDefault();
              onDelete(u._id);
            }}
          >
            <i className="fas fa-trash" />
          </a>
          <UncontrolledTooltip delay={0} target="tooltip12475020">
            Eliminar Usuario
          </UncontrolledTooltip>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Header name="Usuarios" onNewClick={onNewClick} />

      <Container fluid className="mt--6">
        <Row>
          <Col>
            <Card className="mb-0">
              <Row className="px-3 pt-3">
                <Col md="3">
                  <AppInput
                    label="Buscar"
                    type="search"
                    placeholder="Nombre, correo..."
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value);
                      onSearch(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    marginTop: '30px',
                    marginBottom: '30px'
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Spinner />
                </div>
              ) : users.length > 0 ? (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  striped
                >
                  <thead className="thead-light">
                    <tr>
                      <th>Nombre</th>
                      <th>Puesto</th>
                      <th>Email</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>{renderUsers()}</tbody>
                </Table>
              ) : (
                <CardBody className="text-center">
                  <p className="text-muted">No existen usuarios</p>
                </CardBody>
              )}

              <CardFooter className="py-4">
                <nav aria-label="...">{renderPagination()}</nav>
              </CardFooter>
            </Card>

            <div className="my-3">
              <small>Total de usuarios {count}</small>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AppUserList;
