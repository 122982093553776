import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { useAuth } from './hooks/use-auth';
import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';

const loading = (
  <div className="container text-center">
    <div
      style={{ marginTop: '40%' }}
      className="spinner-grow text-warning"
      role="status"
    >
      <span className="sr-only">Cargando...</span>
    </div>
  </div>
);

function App() {
  const auth = useAuth();

  if (auth.isLoading) {
    return loading;
  }

  return (
    <div>
      <Toaster />

      <BrowserRouter>
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/" render={(props) => <AdminLayout {...props} />} />
          <Redirect from="*" to="/" />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
