import axios, { requestToken } from '../../utils/axios';

const getAll = async (page, itemsPerPage, resourceId) => {
  try {
    const response = await axios.get(
      `/api/activities?search=${JSON.stringify({
        page,
        itemsPerPage,
        resourceId
      })}`
      // {
      //   cancelToken: requestToken()
      // }
    );
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data)
      return Promise.reject(e.response.data);
    return Promise.reject(e);
  }
};

export default {
  getAll
};
