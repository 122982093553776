import React, { forwardRef } from 'react';
import { FormGroup } from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';

const AppCurrencyInput = (props, ref) => {
  const {
    label,
    children,
    isTableInput,
    childRef,
    error,
    hasError,
    ...remainingProps
  } = props;

  return (
    <FormGroup className={`${isTableInput ? 'form-group--table' : ''}`.trim()}>
      {label ? (
        <label htmlFor={remainingProps.name} className="form-control-label">
          {label}
        </label>
      ) : null}
      <div style={{ position: 'relative' }}>
        <CurrencyInput
          autoComplete="off"
          {...remainingProps}
          intlConfig={{ locale: 'es-DO', currency: 'DOP' }}
          ref={ref}
          className={`form-control-sm form-control ${hasError || error ? 'has-error' : ''} ${
            error ? 'with-label' : ''
          } ${props.className ? props.className : ''}`}
        />
      </div>
      {error && <span className="form-error__label">{error}</span>}
    </FormGroup>
  );
};

export default forwardRef(AppCurrencyInput);
