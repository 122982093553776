import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import {
  FormGroup,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Row,
  Col
} from 'reactstrap';

import { displayAmount, formatFilename, moment, getFileObject } from 'utils';
import AccountsReceivableAPI from 'services/api/accountReceivable';
import FileAPI from 'services/api/file';
import { TransactionKind } from '../../constants';
import AppCurrencyInput from 'components/Forms/AppCurrencyInput';
import AppInput from 'components/Forms/AppInput';

function AppARCreditNoteForm({
  accountReceivableId,
  creditNote,
  invoice,
  milestone,
  transactions,
  isCreatingCreditNote,
  isEditingCreditNote,
  onClose,
  onSuccess
}) {
  const [form, setForm] = useState({
    amount: '0.00',
    date: '',
    note: '',
    fileVoucher: null,
    transactionNote: ''
  });
  const [errors, setErrors] = useState({});
  const [voucherFile, setVoucherFile] = useState(null);
  const voucherInputRef = useRef(null);
  const [isSending, setIsSending] = useState(false);

  const [voucherFieldDirty, setVoucherFieldDirty] = useState(false);

  const [displayMoreFields, setDisplayMoreFields] = useState(false);

  useEffect(() => {
    if (isEditingCreditNote) {
      function toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          callback(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      }

      toDataUrl(getFileObject(creditNote.fileVoucher), function (x) {
        const file = x;
        // const dT =
        //   new ClipboardEvent('').clipboardData || // Firefox < 62 workaround exploiting https://bugzilla.mozilla.org/show_bug.cgi?id=1422655
        //   new DataTransfer(); // specs compliant (as of March 2018 only Chrome)
        const fileToSet = new File(
          [file],
          creditNote.fileVoucher.replace('vouchers/', '')
        );
        setVoucherFile(fileToSet);
      });

      setForm({
        amount: (creditNote.total / 100).toString(),
        date: moment(creditNote.date).format('YYYY-MM-DD'),
        note: creditNote.note,
        fileVoucher: creditNote.fileVoucher,
        transactionNote: creditNote.transactionNote
      });
    }
  }, []);

  useEffect(() => {
    if (!isEditingCreditNote && milestone) {
      setForm({
        ...form,
        amount: milestone.amount.toFixed(2)
      });
    } else if (!isEditingCreditNote) {
      setForm({
        ...form,
        amount: '0.00'
      });
    }
  }, [milestone]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async () => {
    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    if (!form.date) {
      newErrors.date = 'Es obligatorio';
    }

    if (!getAmount()) {
      newErrors.amount = 'Debe ser mayor que 0';
    } else if (getNewPendingAmount() < 0) {
      newErrors.amount = 'Debe ser menor que el balance pendiente';
    }

    if (!voucherFile) {
      alert('Debes adjuntar un voucher');
      newErrors.voucherFile = true;
    }

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    let tmpForm = {};

    if (isEditingCreditNote) {
      tmpForm = { ...creditNote };
    }

    tmpForm = {
      ...tmpForm,
      amount: getAmount() * 100,
      total: getAmount() * 100,
      note: form.note,
      kind: TransactionKind.CreditNote,
      invoice: invoice._id,
      date: moment(form.date),
      milestoneId: milestone ? milestone._id : undefined,
      transactionNote: form.transactionNote
    };

    try {
      if (!isEditingCreditNote || (isEditingCreditNote && voucherFieldDirty)) {
        const voucherFileName = formatFilename(
          voucherFile.name,
          'credit-notes'
        );
        const voucherSignedRequest = await FileAPI.signS3Upload(
          voucherFileName,
          voucherFile.type
        );
        await FileAPI.uploadToS3(voucherFile, voucherSignedRequest);
        await FileAPI.create({
          key: voucherFileName
        });
        tmpForm.fileVoucher = voucherFileName;
      }

      if (isEditingCreditNote) {
        await AccountsReceivableAPI.updateTransaction(
          accountReceivableId,
          creditNote._id,
          tmpForm
        );
      } else {
        await AccountsReceivableAPI.createTransaction(
          accountReceivableId,
          tmpForm
        );
      }

      setIsSending(false);
      setForm({
        amount: '0.00',
        date: '',
        fileVoucher: null,
        transactionNote: ''
      });
      setVoucherFile(null);
      onSuccess();
    } catch (e) {
      if (tmpForm.fileVoucher) {
        FileAPI.deleteOneByKey(tmpForm.fileVoucher);
      }

      setIsSending(false);
    }
  };

  const handleVoucherFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVoucherFile(file);
      setVoucherFieldDirty(true);
      e.target.value = null;
    }
  };

  const getAmount = () => {
    if (!form.amount) return 0.0;
    return parseFloat(form.amount.replace(/[^0-9.]/g, ''));
  };

  const getPendingAmount = () => {
    let amount = 0;

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (transactions[i].invoice === invoice._id) {
        amount += transactions[i].total;
      }
    }

    return (invoice.total - amount) / 100;
  };

  const getNewPendingAmount = () => {
    if (!form.amount) return getPendingAmount();
    return parseFloat(getPendingAmount()) - getAmount();
  };

  const renderView = () => {
    if (!invoice) return null;

    return (
      <Modal
        fade={false}
        isOpen={isCreatingCreditNote || isEditingCreditNote}
        size="lg"
        className="modal-dialog-centered modal-secondary"
      >
        <ModalHeader>Nota de crédito</ModalHeader>

        <ModalBody className="py-0">
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <div className="text-muted">Numero de Documento</div>
                  <div className="">{invoice.invoiceNumber}</div>

                  <div className="text-muted mt-4">Total de la Factura</div>
                  <div className="">{displayAmount(invoice.total / 100)}</div>

                  {!isEditingCreditNote ? (
                    <>
                      <div className="text-muted mt-4">Balance Pendiente</div>
                      <div className="">
                        {displayAmount(getPendingAmount())}
                      </div>
                    </>
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            <Col lg="6">
              <Card className="mb-0">
                <CardBody className="mb-0">
                  <FormGroup>
                    <label
                      htmlFor="voucher"
                      className="form-control-label d-flex justify-content-between"
                    >
                      Voucher
                      {!voucherFile ? (
                        <Button
                          className="d-block"
                          size="sm"
                          color="primary"
                          outline
                          onClick={(e) => {
                            e.preventDefault();
                            voucherInputRef.current.click();
                          }}
                        >
                          CARGAR
                        </Button>
                      ) : (
                        <Button
                          className="d-block"
                          size="sm"
                          color="danger"
                          outline
                          onClick={(e) => {
                            e.preventDefault();
                            setVoucherFile(null);
                          }}
                        >
                          ELIMINAR
                        </Button>
                      )}
                    </label>

                    {voucherFile ? (
                      <span>{voucherFile.name}</span>
                    ) : (
                      <span className="text-muted">
                        No tienes voucher adjunto
                      </span>
                    )}

                    <input
                      ref={voucherInputRef}
                      hidden
                      type="file"
                      onChange={handleVoucherFileChange}
                    />
                  </FormGroup>

                  <AppInput
                    label="Fecha"
                    id="date"
                    name="date"
                    type="date"
                    value={form.date}
                    error={errors.date}
                    onChange={handleChange}
                  />

                  <AppInput
                    label="Nota"
                    id="note"
                    name="note"
                    value={form.note}
                    error={errors.note}
                    onChange={handleChange}
                  />

                  <AppCurrencyInput
                    label="Monto"
                    id="amount"
                    name="amount"
                    step={1}
                    decimalScale={2}
                    value={form.amount}
                    error={errors.amount}
                    onValueChange={(value, name) =>
                      handleChange({
                        target: { value, name }
                      })
                    }
                  />

                  {!isEditingCreditNote ? (
                    <>
                      <hr className="mt-2 mb-3" />

                      <small className="text-muted">Nuevo Balance</small>

                      <div className="font-weight-bold">
                        {getNewPendingAmount() < 0 ? '-' : ''}
                        {displayAmount(getNewPendingAmount())}
                      </div>
                    </>
                  ) : null}

                  <Button
                    size="sm"
                    color="transparent"
                    className="mx-auto d-block mt-3"
                    onClick={() => setDisplayMoreFields(!displayMoreFields)}
                  >
                    {displayMoreFields ? 'Mostrar menos' : 'Mostrar más'}
                  </Button>

                  {displayMoreFields ? (
                    <>
                      <label
                        htmlFor="voucher"
                        className="form-control-label d-flex justify-content-between"
                      >
                        Anotaciones
                      </label>

                      <div
                        data-quill-placeholder="Quill WYSIWYG"
                        data-toggle="quill"
                      />
                      <ReactQuill
                        value={form.transactionNote || ''}
                        onChange={(value) => {
                          setForm({
                            ...form,
                            transactionNote: value
                          });
                        }}
                        theme="snow"
                        modules={{
                          toolbar: [
                            ['bold', 'italic'],
                            ['link', 'blockquote', 'code', 'image'],
                            [
                              {
                                list: 'ordered'
                              },
                              {
                                list: 'bullet'
                              }
                            ]
                          ]
                        }}
                      />
                    </>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          {isSending ? (
            <Button className="new-event--add" color="primary" type="button">
              <Spinner size="sm" />
            </Button>
          ) : (
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={handleSubmit}
            >
              Guardar
            </Button>
          )}

          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => {
              setForm({
                amount: '0.00',
                date: '',
                note: '',
                fileVoucher: null,
                transactionNote: ''
              });
              setErrors({});
              setVoucherFile(null);
              onClose();
            }}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return renderView();
}

export default AppARCreditNoteForm;
