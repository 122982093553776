import React, { useEffect, useState } from 'react';

import dataSheetAPI from 'services/api/dataSheet';
import AppDataSheetList from 'components/DataSheets/AppDataSheetList';
import AppDataSheetForm from 'components/DataSheets/AppDataSheetForm';
import AppDataSheetView from 'components/DataSheets/AppDataSheetView';

function DataSheets({ location, history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [dataSheets, setDataSheets] = useState([]);
  const [dataSheet, setDataSheet] = useState(null);
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(location.search).get('page')) - 1 || 0
  );
  const [count, setCount] = useState(0);
  const [isViewing, setIsViewing] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => fetchDataSheets(), []);

  useEffect(() => {
    if (!new URLSearchParams(location.search).get('id')) {
      handleClose();
    }
  }, [location.search]);

  useEffect(() => {
    if (!dataSheet) {
      return;
    }

    if (dataSheet._id) {
      setDataSheets(
        dataSheets.map((u) => (u._id === dataSheet._id ? { ...dataSheet } : u))
      );
      if (!new URLSearchParams(location.search).get('id')) {
        const params = new URLSearchParams();
        params.append('id', dataSheet._id);
        history.push({ search: params.toString() });
      }
    }
  }, [dataSheet]);

  const fetchDataSheets = async (pageToGo, valueToSearch) => {
    setIsLoading(true);
    try {
      const { dataSheets, count } = await dataSheetAPI.getAll(
        pageToGo >= 0 ? pageToGo : page,
        itemsPerPage,
        valueToSearch && valueToSearch.length > 0 ? valueToSearch : ''
      );
      setDataSheets(dataSheets);
      setCount(count);

      const userId = new URLSearchParams(location.search).get('id');
      if (userId && userId !== 'undefined') {
        setDataSheet(await dataSheetAPI.getOne(userId));
        setIsViewing(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      /* handle error */
      console.log(e);
    }
  };

  const handleSearch = (valueToSearch) => {
    fetchDataSheets(0, valueToSearch);
    setPage(0);
  };

  const handlePageClick = (pageToGo, value) => {
    setPage(pageToGo);
    fetchDataSheets(pageToGo, value);
  };

  const handleDataSheetClick = async (id, toView) => {
    try {
      setDataSheet(await dataSheetAPI.getOne(id));
      setIsViewing(!!toView);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleClose = async () => {
    try {
      setDataSheet(null);
      setIsViewing(false);

      if (new URLSearchParams(location.search).get('id')) {
        const params = new URLSearchParams();
        params.delete('id');
        history.push({ search: params.toString() });
      }
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleSave = async (payload) => {
    try {
      if (dataSheet._id) {
        await dataSheetAPI.update(dataSheet._id, payload);
        // if (new URLSearchParams(location.search).get('id')) {
        //   const params = new URLSearchParams();
        //   params.delete('id');
        //   history.push({ search: params.toString() });
        // }
        // setDataSheet(await dataSheetAPI.getOne(dataSheet._id));
        setIsViewing(false);
        // setDataSheet(null);
        setPage(0);
        await fetchDataSheets(0);
      } else {
        await dataSheetAPI.create(payload);
        await fetchDataSheets(0);
        setDataSheet(null);
        setPage(0);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('¿Seguro quieres eliminar esta data sheet?')) return;
    if (!window.confirm('¡Después de este punto, no hay vuelta atrás!')) return;
    try {
      await dataSheetAPI.deleteOne(id);
      fetchDataSheets(0);
      setPage(0);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleNewClick = () => {
    setDataSheet({});
  };

  const renderView = () => {
    if (!dataSheet) {
      return (
        <AppDataSheetList
          isLoading={isLoading}
          dataSheets={dataSheets}
          count={count}
          page={page}
          itemsPerPage={itemsPerPage}
          onSearch={handleSearch}
          onPageClick={handlePageClick}
          onDataSheetClick={handleDataSheetClick}
          onDelete={handleDelete}
          onNewClick={handleNewClick}
        />
      );
    }

    if (isViewing) {
      return (
        <AppDataSheetView
          dataSheet={dataSheet}
          onClose={handleClose}
          onEditClick={handleDataSheetClick}
          onProductsUpdate={fetchDataSheets}
        />
      );
    }

    return (
      <AppDataSheetForm
        dataSheet={dataSheet}
        onClose={handleClose}
        onSave={handleSave}
        onViewClick={handleDataSheetClick}
      />
    );
  };

  return renderView();
}

export default DataSheets;
