export const Settings = {
  api: {
    baseURL:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:5000'
        : 'http://45.32.163.125:5000'
  }
};

export const UserRole = Object.freeze({
  Admin: 'Admin',
  AdminReadOnly: 'Admin Solo Lectura',
  GeneralSupervisor: 'Supervisor General',
  Secretary: 'Secretario',
  Accountant: 'Contador',
  None: 'Ninguno'
});

export const CustomerKind = Object.freeze({
  Person: 'Persona',
  Company: 'Empresa'
});

export const UserPosition = Object.freeze({
  President: 'Presidente',
  ManagerGeneral: 'Gerente General',
  ManagerAdministrator: 'Gerente Administrativo',
  ProjectDirector: 'Director de Proyectos',
  ProjectAuxiliary: 'Auxiliar de Proyectos',
  Accounting: 'Contabilidad',
  HumanResources: 'Recursos Humanos',
  Secretary: 'Secretaria',
  Electrician: 'Técnico Eléctrico',
  Installer: 'Técnico Instaladores',
  WarehouseManager: 'Encargado de Almacén',
  Consultant: 'Proveedor Externo',
  None: 'Ninguno'
});

export const TransactionKind = Object.freeze({
  Invoice: 'Factura',
  Payment: 'Pago',
  CreditNote: 'Nota de crédito'
});

export const NotificationKind = Object.freeze({
  MilestoneStatus: 'Estado de hito',
  PaymentReceipt: 'Recibo de pago',
  Invoice: 'Factura'
});

export const ProductKind = Object.freeze({
  Inventory: 'Inventario',
  NonInventory: 'Sin Inventario',
  Service: 'Service'
});

export const ProductCategory = Object.freeze({
  None: 'Ninguna',
  SolarPanels: 'Paneles Solares',
  Inverters: 'Inversores',
  Materials: 'Materiales'
});

export const ProjectStatus = Object.freeze({
  FirstPending: 'PV: Preparar sometimiento',
  FirstReadyToSend: 'PV: Listo para someter',
  FirstInProgress: 'PV: Sometimiento en proceso',
  FirstRejected: 'PV: No cumple',

  SecondPending: 'SV: Preparar sometimiento',
  SecondReadyToSend: 'SV: Listo para someter',
  SecondInProgress: 'SV: Sometimiento en proceso',
  SecondRejected: 'SV: No cumple',

  AgreementPending: 'Acuerdo de interconexión: Recibido',
  AgreementSent: 'Acuerdo de interconexión: En espera',

  CounterPending: 'Medidor: En espera',
  CounterPendingReceipt: 'Medidor: Cargo pendiente',
  CounterPendingPayment: 'Medidor: Pago pendiente',
  CounterReadyToInstall: 'Medidor: Instalación pendiente',
  CounterInstalled: 'Medidor: Instalado',

  Running: 'Arrancado'
});

export const PropertyRight = Object.freeze({
  Owner: 'Dueño',
  CoOwner: 'Co-dueño',
  Rented: 'Alquiler',
  Other: 'Otro'
});

export const InterconnectionType = Object.freeze({
  ParalelOperation: 'Operación en Paralelo',
  MomentaryParalelOperation: 'Operación en Paralelo Momentánea'
});

export const GeneratorType = Object.freeze({
  Sincronic: 'Sincrónico',
  Induction: 'Inducción',
  BasedOnIversor: 'Basado en Inversor',
  Other: 'Otro'
});

export const GeneratorTechnologyType = Object.freeze({
  PhotoVoltaic: 'Fotovoltaico',
  Wind: 'Viento',
  MicroTurbine: 'Micro Turbina',
  FuelCell: 'Celda Combustible',
  Biomass: 'Biomasa',
  Vapor: 'Vapor',
  InternalCombustion: 'Combustion Interna',
  Hydro: 'Hidro',
  Other: 'Otro'
});

export const CustomerType = Object.freeze({
  None: '',
  Residential: 'Residencial',
  Comertial: 'Comercial',
  Industrial: 'Industrial',
  Agricultural: 'Agrícola',
  Institution: 'Institución',
  Educative: 'Educativa',
  Medic: 'Facilidad Médico - Hospitalaria'
});

export const CheckpointKind = Object.freeze({
  None: '',
  Submission: 'Sometimiento',
  Objection: 'Objeción'
});

export const Representatives = Object.freeze({
  IsidroLora: 'Isidro Lora',
  CarlosBacha: 'Carlos Bacha',
  CarlosPerez: 'Carlos Perez',
  VictorColon: 'Victor Colón',
  JeanLuis: 'Jean Luis',
  JulioUrraca: 'Julio Urraca',
  WilmerCollado: 'Wilmer Collado'
});

export const RejectionStatus = Object.freeze({
  None: '',
  FullCircuit: 'Circuito Lleno',
  CustomerRejected: 'Rechazados por el Cliente',
  Discarded: 'Desestimados',
  StandBy: 'Stand By'
});
