import React, { useEffect, useState } from 'react';
import moment from 'utils/moment';
import { Card, Spinner, CardHeader, CardBody, Row, Col } from 'reactstrap';

import activityService from 'services/api/activity';
import { useAuth } from 'hooks/use-auth';

function AppActivity({ resourceId }) {
  const { isAdmin, isAdminReadOnly } = useAuth();
  const [activity, setActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isAdmin() && !isAdminReadOnly()) return;

    async function fetchActivity() {
      try {
        const { activities, count } = await activityService.getAll(
          0,
          10,
          resourceId
        );
        setActivity(activities);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log(e);
        /* handle error */
      }
    }

    fetchActivity();
  }, []);

  const renderActivities = () => {
    if (isLoading)
      return (
        <div className="text-center">
          <Spinner size="sm" />
        </div>
      );

    if (!activity || activity.length === 0) {
      return <p className="text-muted text-center">No existen actividades</p>;
    }

    return (
      <div
        className="timeline timeline-one-side"
        data-timeline-axis-style="dotted"
        data-timeline-content="axis"
      >
        {activity.map((a) => (
          <div key={a._id} className="timeline-block my-3">
            <span className="timeline-step timeline-step-sm badge-primary">
              <i className="fas fa-sync"></i>
            </span>
            <div className="timeline-content pt-0">
              <div className="d-flex justify-content-between">
                <div>
                  <span
                    style={{ fontSize: '12px' }}
                    className="text-muted font-weight-bold"
                  >
                    {a.user
                      ? `${a.user.firstName} ${a.user.lastName}`.trim()
                      : 'SEER Bot'}
                  </span>
                </div>
                <div className="text-right">
                  <small className="text-muted">
                    <i className="fas fa-clock mr-1" />{' '}
                    {moment(a.createdAt).format('lll')}
                  </small>
                </div>
              </div>
              <h6 style={{ fontSize: '12px' }} className="mt-1 mb-0">
                {a.message}
              </h6>
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (!resourceId) return null;

  if (!isAdmin() && !isAdminReadOnly()) return null;

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Actividad</h3>
      </CardHeader>
      <CardBody>{renderActivities()}</CardBody>
    </Card>
  );
}

export default AppActivity;
