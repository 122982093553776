import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";

import { formatFilename } from "utils";
import fileAPI from "services/api/file";
import projectAPI from "services/api/project";
import AppProjectList from "components/Projects/AppProjectList";
import AppProjectForm from "components/Projects/AppProjectForm";
import AppProjectView from "components/Projects/AppProjectView";

function Projects({ location, history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [project, setProject] = useState(null);
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(location.search).get("page")) - 1 || 0
  );
  const [count, setCount] = useState(0);
  const [isViewing, setIsViewing] = useState(false);
  const itemsPerPage = 15;

  useEffect(() => {
    if (!new URLSearchParams(location.search).get("id")) {
      handleClose();
    }
  }, [location.search]);

  useEffect(() => {
    if (!project) return;

    if (project._id) {
      setProjects(
        projects.map((u) => (u._id === project._id ? { ...project } : u))
      );

      if (!new URLSearchParams(location.search).get("id")) {
        const params = new URLSearchParams();
        params.append("id", project._id);
        history.push({ search: params.toString() });
      }
    }
  }, [project]);

  const fetchProjects = async (
    pageToGo,
    valueToSearch,
    statusToSearch,
    rejectionStatusToSearch
  ) => {
    setIsLoading(true);
    try {
      const { projects, count } = await projectAPI.getAll(
        pageToGo >= 0 ? pageToGo : page,
        itemsPerPage,
        valueToSearch && valueToSearch.length > 0 ? valueToSearch : "",
        statusToSearch && statusToSearch.length > 0 ? statusToSearch : "",
        rejectionStatusToSearch && rejectionStatusToSearch.length > 0
          ? rejectionStatusToSearch
          : ""
      );
      setProjects(projects);
      setCount(count);

      const results = await projectAPI.getAll(0, 0, "");
      setAllProjects(results.projects);

      const userId = new URLSearchParams(location.search).get("id");
      const editParam = new URLSearchParams(location.search).get("edit");
      if (userId && userId !== "undefined") {
        setProject(await projectAPI.getOne(userId));
        if (editParam === "1") {
          setIsViewing(false);
        } else {
          setIsViewing(true);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      /* handle error */
      console.log(e);
    }
  };

  const handleSearch = (
    valueToSearch,
    statusToSearch,
    rejectionStatusToSearch
  ) => {
    fetchProjects(0, valueToSearch, statusToSearch, rejectionStatusToSearch);
    setPage(0);
  };

  const handlePageClick = (
    pageToGo,
    value,
    statusToSearch,
    rejectionStatusToSearch
  ) => {
    setPage(pageToGo);
    fetchProjects(pageToGo, value, statusToSearch, rejectionStatusToSearch);
  };

  const handleDocumentFieldChange = async (e, name) => {
    const file = e.target.files[0];
    const fileName = formatFilename(file.name, "documents-submission");

    try {
      const signedRequest = await fileAPI.signS3Upload(fileName, file.type);
      await fileAPI.uploadToS3(file, signedRequest);
      await fileAPI.create({
        key: fileName,
      });

      await projectAPI.update(project._id, {
        [name]: fileName,
      });
      setProject(await projectAPI.getOne(project._id));

      e.target.value = null;
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const handleDocumentDelete = async (name) => {
    try {
      await fileAPI.deleteOneByKey(project[name]);
      await projectAPI.update(project._id, { [name]: "" });
      setProject({ ...project, [name]: "" });
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const handleProjectClick = async (id, toView) => {
    try {
      setProject(await projectAPI.getOne(id));
      setIsViewing(!!toView);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleClose = async () => {
    try {
      setProject(null);
      setIsViewing(false);

      if (new URLSearchParams(location.search).get("id")) {
        const params = new URLSearchParams();
        params.delete("id");
        history.push({ search: params.toString() });
      }
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleSave = async (payload) => {
    try {
      if (project._id) {
        await projectAPI.update(project._id, payload);
        // if (new URLSearchParams(location.search).get('id')) {
        //   const params = new URLSearchParams();
        //   params.delete('id');
        //   history.push({ search: params.toString() });
        // }
        // setProject(await projectAPI.getOne(project._id));
        // setIsViewing(false);
        // setProject(null);
        // setPage(0);
        await fetchProjects(0);
      } else {
        await projectAPI.create(payload);
        await fetchProjects(0);
        setProject(null);
        setPage(0);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm("¿Seguro quieres eliminar esta proyecto?")) return;
    if (!window.confirm("¡Después de este punto, no hay vuelta atrás!")) return;

    try {
      await projectAPI.deleteOne(id);
      fetchProjects(0);
      setPage(0);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleNewClick = () => {
    setProject({});
  };

  const renderView = () => {
    if (!project) {
      return (
        <AppProjectList
          isLoading={isLoading}
          projects={projects}
          allProjects={allProjects}
          count={count}
          page={page}
          itemsPerPage={itemsPerPage}
          onSearch={handleSearch}
          onPageClick={handlePageClick}
          onProjectClick={handleProjectClick}
          onDelete={handleDelete}
          onNewClick={handleNewClick}
        />
      );
    }

    if (isViewing) {
      return (
        <AppProjectView
          project={project}
          onClose={handleClose}
          onEditClick={handleProjectClick}
          onDocumentFieldChange={handleDocumentFieldChange}
          onDocumentDelete={handleDocumentDelete}
          onProjectsUpdate={fetchProjects}
        />
      );
    }

    return (
      <AppProjectForm
        project={project}
        onClose={handleClose}
        onSave={handleSave}
        onViewClick={handleProjectClick}
      />
    );
  };

  return renderView();
}

export default Projects;
