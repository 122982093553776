import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Badge
} from 'reactstrap';

import {
  UserRole,
  UserPosition,
  PropertyRight,
  ProjectStatus,
  ProductCategory,
  CustomerType,
  CheckpointKind,
  CustomerKind
} from './../../constants';
import { moment, getName, getFileObject } from 'utils';
import energySupplierAPI from 'services/api/energySupplier';
import userAPI from 'services/api/user';
import projectAPI from 'services/api/project';
import submissionAPI from 'services/api/submission';
import productAPI from 'services/api/product';
import FileAPI from 'services/api/file';
import AppInput from 'components/Forms/AppInput';
import AppNotes from 'components/Notes/AppNotes';
import AlternativeHeader from 'components/Headers/AlternativeHeader';
import AppCheckbox from 'components/Forms/AppCheckbox';
import AppActivity from 'components/Activities/AppActivity';
import { useAuth } from 'hooks/use-auth';
import { formatFilename } from 'utils';
import { useHistory } from 'react-router';
import { getElectricalUnit } from 'utils';
import AppAttachments from 'components/Attachments/AppAttachments';
import { Link } from 'react-router-dom';

const listSubmissionDocuments = {
  customerAuthorization: {
    name: 'Autorización del cliente',
    projectFields: [],
    submissionFields: []
  },
  fileCustomerAuthorization: {
    name: 'Autorización del cliente',
    projectFields: ['fileCustomerAuthorization'],
    submissionFields: []
  },
  mailToEdenorte: {
    name: 'Carta a EDENORTE',
    projectFields: [
      'energySupplier',
      'components',
      'energySupplierContract',
      'energySupplierMeterNumber'
    ],
    submissionFields: ['submittedAt']
  },
  anexoA: {
    name: 'Anexo A',
    projectFields: ['energySupplierContract', 'energySupplierMeterNumber'],
    submissionFields: ['authorizedBy', 'supervisedBy', 'codiaBy']
  },
  mailSecondVisit: {
    name: 'Carta Solicitud Segunda Visita',
    projectFields: [],
    submissionFields: ['authorizedBy']
  },
  mailProjectFinished: {
    name: 'Carta Proyecto Terminado',
    projectFields: [],
    submissionFields: ['endedNote']
  },
  fileNoObjectionLetter: {
    name: 'Carta de no objeción',
    projectFields: ['fileNoObjectionLetter'],
    submissionFields: []
  },
  anexoB: {
    name: 'Anexo B',
    projectFields: [],
    submissionFields: ['secondVisitSubmittedAt', 'customerKind']
  },
  fileIdenticiation: {
    name: 'Identificación del cliente',
    projectFields: ['fileIdenticiation'],
    submissionFields: []
  },
  fileMerchantRegistry: {
    name: 'Registro mercantil',
    projectFields: ['fileMerchantRegistry'],
    submissionFields: []
  },
  filePactAssembly: {
    name: 'Acta de Asamblea',
    projectFields: ['filePactAssembly'],
    submissionFields: []
  },
  fileEnergyBill: {
    name: 'Factura de energía',
    projectFields: ['fileEnergyBill'],
    submissionFields: []
  },
  codiaByCertificate: {
    name: 'Certificación del ingeniero colegiado',
    projectFields: [],
    submissionFields: ['codiaBy']
  },
  fileUnifilar: {
    name: 'Unifilar',
    projectFields: ['fileUnifilar'],
    submissionFields: []
  },
  fileLocation: {
    name: 'Ubicación',
    projectFields: ['fileLocation'],
    submissionFields: []
  },
  warrantyCertificate: {
    name: 'Certificación de garantía',
    projectFields: ['components'],
    submissionFields: []
  },
  panelCertificate: {
    name: 'Certificación del panel',
    projectFields: ['components'],
    submissionFields: []
  },
  generatorCertificate: {
    name: 'Certificación del inversor',
    projectFields: ['components'],
    submissionFields: []
  },
  datasheet: {
    name: 'Datasheet',
    projectFields: ['components'],
    submissionFields: []
  }
};

const miscFiles = [
  {
    label: 'Propuesta',
    icon: 'far fa-file-alt',
    name: 'fileProposal'
  }
];

const firstVisitItems = [
  {
    label: 'Autorización del cliente',
    icon: 'fa fa-signature',
    name: 'fileCustomerAuthorization'
  },
  {
    label: 'Diagrama unifilar',
    icon: 'fa fa-bolt',
    name: 'fileUnifilar'
  },
  {
    label: 'Plano ubicación',
    icon: 'fas fa-map-pin',
    name: 'fileLocation'
  },
  {
    label: 'Factura de energía',
    icon: 'fa fa-file-invoice-dollar',
    name: 'fileEnergyBill'
  }
];

const secondVisitItems = [
  {
    label: 'Carta de no objeción',
    icon: 'fas fa-thumbs-up',
    name: 'fileNoObjectionLetter'
  }
];

const agreementItems = [
  {
    label: 'Acuerdo de interconexión',
    icon: 'fas fa-handshake',
    name: 'fileAgreementInterconnection'
  },
  {
    label: 'Acuse de recibo acuerdo de interconexión',
    icon: 'fas fa-handshake',
    name: 'fileAgreementInterconnectionReceipt'
  }
];

const counterItems = [
  {
    label: 'Carta de medidor',
    icon: 'fas fa-envelope-open-text',
    name: 'fileCounterLetter'
  },
  {
    label: 'Recibo de pago del medidor',
    icon: 'fas fa-receipt',
    name: 'fileCounterPaymentReceipt'
  }
];

function AppProjectView({
  project,
  onEditClick,
  onDocumentFieldChange,
  onDocumentDelete,
  onClose,
  onProjectsUpdate
}) {
  const history = useHistory();
  const { user } = useAuth();
  const rejectionCheckpointFileInputRef = useRef(null);
  const documentFieldRef = useRef(null);
  const [documentToUploadName, setDocumentToUploadName] = useState('');

  const [energySuppliers, setEnergySuppliers] = useState([]);
  const [usersForSubmission, setUsersForSubmission] = useState([]);

  const [projectForm, setProjectForm] = useState({
    components: [],
    energySupplier: null,
    representative: ''
  });
  const [editingParams, setEditingParams] = useState([]);
  const [submissionForm, setSubmissionForm] = useState({});
  const [submissionExportForm, setSubmissionExportForm] = useState({
    customerAuthorization: false,
    fileCustomerAuthorization: false,
    mailToEdenorte: false,
    anexoA: false,
    mailSecondVisit: false,
    mailProjectFinished: false,
    fileNoObjectionLetter: false,
    anexoB: false,
    fileIdenticiation: false,
    fileMerchantRegistry: false,
    filePactAssembly: false,
    fileEnergyBill: false,
    codiaByCertificate: false,
    fileUnifilar: false,
    fileLocation: false,
    warrantyCertificate: false,
    panelCertificate: false,
    generatorCertificate: false,
    datasheet: false
  });
  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  const [documentsExportForm, setDocumentExportForm] = useState({});
  const [isGeneratingExport, setIsGeneratingExport] = useState(false);

  const [products, setProducts] = useState([]);
  const [productSearchValue, setProductSearchValue] = useState('');
  const [submissionDocuments, setSubmissionDocuments] = useState({});

  const [rejectionCheckpoint, setRejectionCheckpoint] = useState(null);
  const [rejectionCheckpointFile, setRejectionCheckpointFile] = useState(null);

  const [nextStepPendingField, setNextStepPendingField] = useState([]);

  const [viewingCheckpoint, setViewingCheckpoint] = useState(null);

  useEffect(() => {
    let documentsToSubmit = { ...listSubmissionDocuments };
    let documentsToExport = { ...submissionExportForm };

    if (project.customer.kind === CustomerKind.Person) {
      delete documentsToSubmit.fileCustomerAuthorization;
      delete documentsToExport.fileCustomerAuthorization;

      delete documentsToSubmit.fileMerchantRegistry;
      delete documentsToExport.fileMerchantRegistry;

      delete documentsToSubmit.filePactAssembly;
      delete documentsToExport.filePactAssembly;
    } else {
      delete documentsToSubmit.customerAuthorization;
      delete documentsToExport.customerAuthorization;
    }

    setSubmissionDocuments({ ...documentsToSubmit });
    setSubmissionExportForm({ ...documentsToExport });

    async function initComponent() {
      const newSubmissionForm = { ...project.submission };
      if (newSubmissionForm.submittedAt) {
        newSubmissionForm.submittedAt = moment(
          newSubmissionForm.submittedAt
        ).format('YYYY-MM-DD');
      }

      if (newSubmissionForm.secondVisitSubmittedAt) {
        newSubmissionForm.secondVisitSubmittedAt = moment(
          newSubmissionForm.secondVisitSubmittedAt
        ).format('YYYY-MM-DD');
      }
      setSubmissionForm({ ...newSubmissionForm });

      setProjectForm({
        status: project.status,
        checkpoints: project.checkpoints,

        representative: project.representative,
        fileCustomerAuthorization: project.fileCustomerAuthorization,
        fileUnifilar: project.fileUnifilar,
        fileLocation: project.fileLocation,
        fileEnergyBill: project.fileEnergyBill,

        fileNoObjectionLetter: project.fileNoObjectionLetter,

        fileAgreementInterconnection: project.fileAgreementInterconnection,
        fileAgreementInterconnectionReceipt:
          project.fileAgreementInterconnectionReceipt,

        fileCounterLetter: project.fileCounterLetter,
        fileCounterPaymentReceipt: project.fileCounterPaymentReceipt,

        energySupplierContract: project.energySupplierContract,
        energySupplierMeterNumber: project.energySupplierMeterNumber,
        energySupplier: project.energySupplier,
        components: project.components
      });

      try {
        const { energySuppliers } = await energySupplierAPI.getAll();
        const { users } = await userAPI.getAllForSubmission();
        setUsersForSubmission(users);
        setEnergySuppliers(energySuppliers);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        /* TODO: handle error */
      }
    }

    initComponent();
  }, []);

  useEffect(() => {
    setProjectForm({
      status: project.status,
      checkpoints: project.checkpoints,

      representative: project.representative,
      fileCustomerAuthorization: project.fileCustomerAuthorization,
      fileUnifilar: project.fileUnifilar,
      fileLocation: project.fileLocation,
      fileEnergyBill: project.fileEnergyBill,

      fileNoObjectionLetter: project.fileNoObjectionLetter,

      fileAgreementInterconnection: project.fileAgreementInterconnection,
      fileAgreementInterconnectionReceipt:
        project.fileAgreementInterconnectionReceipt,

      fileCounterLetter: project.fileCounterLetter,
      fileCounterPaymentReceipt: project.fileCounterPaymentReceipt,

      energySupplierContract: project.energySupplierContract,
      energySupplierMeterNumber: project.energySupplierMeterNumber,
      energySupplier: project.energySupplier,
      components: project.components
    });
  }, [project]);

  const checkpoints = () => {
    if (!project) return [];
    return [...project.checkpoints].reverse();
  };

  const fetchProducts = async (valueToSearch) => {
    try {
      const results = await productAPI.getAll(
        0,
        10,
        valueToSearch && valueToSearch.length > 0 ? valueToSearch : ''
      );
      setProducts(results.products);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleSubmissionFormChange = (e) => {
    let newValue = e.target.value;
    if (e.target.type === 'checkbox') newValue = !submissionForm[e.target.name];

    setSubmissionForm({
      ...submissionForm,
      [e.target.name]: newValue
    });
  };

  const handleProjectFormChange = (e) => {
    let newValue = e.target.value;
    if (e.target.type === 'checkbox') newValue = !projectForm[e.target.name];

    setProjectForm({
      ...projectForm,
      [e.target.name]: newValue
    });
  };

  const handleRejectionCheckpointChange = (e) => {
    let newValue = e.target.value;
    if (e.target.type === 'checkbox')
      newValue = !rejectionCheckpoint[e.target.name];

    setRejectionCheckpoint({
      ...rejectionCheckpoint,
      [e.target.name]: newValue
    });
  };

  const handleDocumentExportFormChange = (e) => {
    let newValue = e.target.value;
    if (e.target.type === 'checkbox')
      newValue = !documentsExportForm[e.target.name];

    setDocumentExportForm({
      ...documentsExportForm,
      [e.target.name]: newValue
    });
  };

  const handleSubmissionExportFormChange = (e) => {
    let newValue = e.target.value;
    if (e.target.type === 'checkbox')
      newValue = !submissionExportForm[e.target.name];

    setSubmissionExportForm({
      ...submissionExportForm,
      [e.target.name]: newValue
    });
  };

  const selectAllDocs = () => {
    let tmpSubmissionExportForm = { ...submissionExportForm };

    Object.keys(submissionDocuments).forEach((k) => {
      if (
        k.includes('fileIdenticiation') &&
        project.customer.fileIdenticiation
      ) {
        tmpSubmissionExportForm[k] = true;
      } else if (
        k.includes('fileMerchantRegistry') &&
        project.customer.fileMerchantRegistry
      ) {
        tmpSubmissionExportForm[k] = true;
      } else if (
        k.includes('filePactAssembly') &&
        project.customer.filePactAssembly
      ) {
        tmpSubmissionExportForm[k] = true;
      } else if (
        [
          'anexoB',
          'fileNoObjectionLetter',
          'mailSecondVisit',
          'mailProjectFinished'
        ].includes(k) &&
        [
          ProjectStatus.FirstPending,
          ProjectStatus.FirstReadyToSend,
          ProjectStatus.FirstInProgress,
          ProjectStatus.FirstRejected
        ].includes(projectForm.status)
      ) {
        tmpSubmissionExportForm[k] = false;
      } else if (
        submissionDocuments[k].projectFields.filter((d) =>
          Array.isArray(projectForm[d])
            ? projectForm[d].length === 0
            : !projectForm[d]
        ).length +
          submissionDocuments[k].submissionFields.filter((d) =>
            Array.isArray(submissionForm[d])
              ? submissionForm[d].length === 0
              : !submissionForm[d]
          ).length >
        0
      ) {
        tmpSubmissionExportForm[k] = false;
      } else if (k.includes('file') && !projectForm[k]) {
        tmpSubmissionExportForm[k] = false;
      } else {
        tmpSubmissionExportForm[k] = true;
      }
    });
    setSubmissionExportForm(tmpSubmissionExportForm);
  };

  const unSelectAllDocs = () => {
    let tmpSubmissionExportForm = { ...submissionExportForm };
    Object.keys(submissionDocuments).forEach(
      (k) => (tmpSubmissionExportForm[k] = false)
    );
    setSubmissionExportForm(tmpSubmissionExportForm);
  };

  const handleSave = async () => {
    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    // TODO: handle errors

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    try {
      const payload = {
        energySupplierContract: projectForm.energySupplierContract,
        energySupplierMeterNumber: projectForm.energySupplierMeterNumber,
        energySupplier: projectForm.energySupplier,
        components: projectForm.components,
        submission: { ...submissionForm }
      };

      if (payload.submission.submittedAt) {
        payload.submission.submittedAt = moment(payload.submission.submittedAt);
      }

      if (payload.submission.secondVisitSubmittedAt) {
        payload.submission.secondVisitSubmittedAt = moment(
          payload.submission.secondVisitSubmittedAt
        );
      }
      // TODO: Do this server side
      await projectAPI.update(project._id, payload);
      await onProjectsUpdate();
      setEditingParams([]);
      setIsSending(false);
    } catch (e) {
      setIsSending(false);
      /* handle error */
    }
  };

  const handleNextStep = async () => {
    // Validate the step is completed
    const pendingFields = [];

    switch (projectForm.status) {
      case ProjectStatus.FirstPending:
        Object.entries(submissionDocuments).forEach(([k, v]) => {
          if (
            [
              'anexoB',
              'fileNoObjectionLetter',
              'mailSecondVisit',
              'mailProjectFinished'
            ].includes(k)
          )
            return;

          // if (
          //   project.customer.kind === CustomerKind.Person &&
          //   k.includes('fileCustomerAuthorization')
          // ) {
          //   return;
          // }

          let hasPendingField = false;
          v.projectFields.forEach((pF) => {
            if (
              pF === 'fileIdenticiation' &&
              project.customer.fileIdenticiation
            ) {
              hasPendingField = false;
            } else if (
              pF === 'fileMerchantRegistry' &&
              project.customer.fileMerchantRegistry
            ) {
              hasPendingField = false;
            } else if (
              pF === 'filePactAssembly' &&
              project.customer.filePactAssembly
            ) {
              hasPendingField = false;
            } else if (!projectForm[pF] || projectForm[pF].length === 0) {
              hasPendingField = true;
            }
          });

          v.submissionFields.forEach((sF) => {
            if (!submissionForm[sF] || submissionForm[sF].length === 0) {
              hasPendingField = true;
            }
          });

          if (hasPendingField) {
            pendingFields.push(v.name);
          }
        });
        break;
      case ProjectStatus.FirstInProgress:
        if (!projectForm.fileNoObjectionLetter) {
          pendingFields.push('Carta de no objeción');
        }
        break;
      case ProjectStatus.SecondPending:
        Object.entries(submissionDocuments).forEach(([k, v]) => {
          if (
            ![
              'anexoB',
              'fileNoObjectionLetter',
              'mailSecondVisit',
              'mailProjectFinished'
            ].includes(k)
          )
            return;

          let hasPendingField = false;
          v.projectFields.forEach((pF) => {
            if (
              pF === 'fileIdenticiation' &&
              project.customer.fileIdenticiation
            ) {
              hasPendingField = false;
            } else if (
              pF === 'fileMerchantRegistry' &&
              project.customer.fileMerchantRegistry
            ) {
              hasPendingField = false;
            } else if (
              pF === 'filePactAssembly' &&
              project.customer.filePactAssembly
            ) {
              hasPendingField = false;
            } else if (!projectForm[pF] || projectForm[pF].length === 0) {
              hasPendingField = true;
            }
          });

          v.submissionFields.forEach((sF) => {
            if (!submissionForm[sF] || submissionForm[sF].length === 0) {
              hasPendingField = true;
            }
          });

          if (hasPendingField) {
            pendingFields.push(v.name);
          }
        });
        break;
      case ProjectStatus.SecondInProgress:
        if (!projectForm.fileAgreementInterconnection) {
          pendingFields.push('Acuerdo de interconexión');
        }
        break;
      case ProjectStatus.AgreementSent:
        if (!projectForm.fileAgreementInterconnectionReceipt) {
          pendingFields.push('Acuse de recibo acuerdo de interconexión');
        }
        break;
      case ProjectStatus.CounterPending:
        if (!projectForm.fileCounterLetter) {
          pendingFields.push('Carta de medidor');
        }
        break;
      case ProjectStatus.CounterPendingPayment:
        if (!projectForm.fileCounterPaymentReceipt) {
          pendingFields.push('Recibo de pago del medidor');
        }
        break;
    }

    setNextStepPendingField(pendingFields);
    if (pendingFields.length > 0) return;

    if (
      !window.confirm(
        '¿Seguro que quieres cambiar el estatus de este proyecto?'
      )
    )
      return;

    let nextStatus =
      Object.entries(ProjectStatus)[getCurrentStatusIndex() + 1][1];

    if (projectForm.status.includes('No cumple')) {
      nextStatus =
        Object.entries(ProjectStatus)[getCurrentStatusIndex() - 1][1];
    } else if (nextStatus.includes('No cumple')) {
      nextStatus =
        Object.entries(ProjectStatus)[getCurrentStatusIndex() + 2][1];
    }

    try {
      // Update the project
      await projectAPI.update(project._id, { status: nextStatus });
      // Update the project list
      await onProjectsUpdate();
      setIsSending(false);
    } catch (e) {
      setIsSending(false);
      /* handle error */
    }
  };

  const initRejection = (status) => {
    setRejectionCheckpoint({
      name: '',
      note: '',
      file: '',
      kind: CheckpointKind.Objection,
      createdAt: moment().format('YYYY-MM-DD'),
      intendedStatus: status
    });
  };

  const handleStatusChange = async (e) => {
    if (
      [ProjectStatus.FirstRejected, ProjectStatus.SecondRejected].includes(
        e.target.value
      )
    ) {
      setRejectionCheckpoint({
        name: '',
        note: '',
        file: '',
        kind: CheckpointKind.Objection,
        createdAt: moment().format('YYYY-MM-DD'),
        intendedStatus: e.target.value
      });
      return;
    }

    if (
      !window.confirm('¿Seguro que quieres cambiar el estado de este proyecto?')
    ) {
      return;
    }
    if (isSending) return;

    setIsSending(true);

    let newErrors = {};

    // TODO: handle status requirements errors

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    try {
      const payload = {
        status: e.target.value
      };

      await projectAPI.update(project._id, payload);
      await onProjectsUpdate();
      setIsSending(false);
    } catch (e) {
      setIsSending(false);
      /* handle error */
    }
  };

  const handleCheckpointDelete = async (id) => {
    if (!window.confirm('¿Seguro que quieres eliminar esto?')) {
      return;
    }

    if (isSending) return;
    setIsSending(true);

    try {
      await projectAPI.deleteOneCheckpoint(project._id, id);
      await onProjectsUpdate();
      setIsSending(false);
    } catch (e) {
      setIsSending(false);
      /* handle error */
    }
  };

  // const handleSubmissionSave = async (e) => {
  //   if (
  //     !window.confirm('¿Seguro que quieres cambiar el estado de este proyecto?')
  //   ) {
  //     return;
  //   }

  //   if (isSending) return;
  //   setIsSending(true);

  //   let newErrors = {};

  //   // TODO: handle status requirements errors

  //   if (Object.entries(newErrors).length > 0) {
  //     setIsSending(false);
  //     setErrors(newErrors);
  //     return;
  //   }

  //   try {
  //     const payload = {
  //       status: e.target.value
  //     };

  //     // TODO: Do this server side
  //     setProjectForm({ ...projectForm, status: e.target.value });
  //     await projectAPI.update(project._id, payload);
  //     setIsSending(false);
  //   } catch (e) {
  //     setIsSending(false);
  //     /* handle error */
  //   }
  // };

  const handleRejectionCheckpointSave = async (e) => {
    // if (
    //   !window.confirm('¿Seguro que quieres cambiar el estado de este proyecto?')
    // ) {
    //   return;
    // }

    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    if (!rejectionCheckpoint.name) {
      newErrors.name = 'Debes definir la razón del rechazo';
    }

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    try {
      const tmpCheckpoint = {
        user: user._id,
        name: rejectionCheckpoint.name,
        note: rejectionCheckpoint.note,
        file: '',
        kind: CheckpointKind.Objection,
        createdAt: moment(rejectionCheckpoint.createdAt)
      };

      if (rejectionCheckpointFile) {
        const rejectionCheckpointFileName = formatFilename(
          rejectionCheckpointFile.name,
          'documents-submission-objections'
        );
        const rejectionCheckpointSignedRequest = await FileAPI.signS3Upload(
          rejectionCheckpointFileName,
          rejectionCheckpointFile.type
        );
        await FileAPI.uploadToS3(
          rejectionCheckpointFile,
          rejectionCheckpointSignedRequest
        );
        await FileAPI.create({
          key: rejectionCheckpointFileName
        });
        tmpCheckpoint.file = rejectionCheckpointFileName;
      }

      const payload = {
        status: rejectionCheckpoint.intendedStatus,
        checkpoints: [...projectForm.checkpoints, tmpCheckpoint]
      };

      setRejectionCheckpoint(null);
      await projectAPI.update(project._id, payload);
      await onProjectsUpdate();
      setIsSending(false);
    } catch (e) {
      setIsSending(false);
      /* handle error */
    }
  };

  const handleRejectionCheckpointFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setRejectionCheckpointFile(file);
      // setVoucherFieldDirty(true);
      e.target.value = null;
    }
  };

  const handleDocumentExport = async () => {
    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    if (!documentsExportForm.name) {
      newErrors.name = 'Es obligatorio la razón de exportar';
    }

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    try {
      await submissionAPI.create({
        projectId: project._id,
        name: documentsExportForm.name,
        note: documentsExportForm.note,
        documentsToExport: submissionExportForm
      });
      await onProjectsUpdate();
      setIsSending(false);
      setDocumentExportForm({});
      setIsGeneratingExport(false);
    } catch (e) {
      setIsSending(false);
      /* handle error */
    }
  };

  const handleClose = () => {
    setEditingParams([]);
    setErrors({});
  };

  const initDocumentUpload = (name) => {
    setDocumentToUploadName(name);
    documentFieldRef.current.click();
  };

  const handleDocumentFieldChange = async (e) => {
    if (isUploading) {
      alert('Espera que esta cargando el archivo anterior');
      return;
    }
    setIsUploading(true);

    try {
      await onDocumentFieldChange(e, documentToUploadName);
      setDocumentToUploadName('');
      setIsUploading(false);
    } catch (e) {
      setIsUploading(false);
      console.log(e);
      /* handle error */
    }
  };

  const getCurrentStatusIndex = () => {
    return Object.entries(ProjectStatus).findIndex(
      ([k, v]) => v === projectForm.status
    );
  };

  const renderStatusOptions = () => {
    return Object.entries(ProjectStatus)
      .slice(0, getCurrentStatusIndex() + 2)
      .map(([k, v], pSIndex) => (
        <option key={`submission-status-${pSIndex}`} value={v}>
          {v}
        </option>
      ));
  };

  const nextStatusLabel = () => {
    if (!projectForm.status) return;

    let nextValue =
      Object.entries(ProjectStatus)[getCurrentStatusIndex() + 1][1];

    if (projectForm.status.includes('No cumple')) {
      nextValue = Object.entries(ProjectStatus)[getCurrentStatusIndex() - 1][1];
    } else if (nextValue.includes('No cumple')) {
      nextValue = Object.entries(ProjectStatus)[getCurrentStatusIndex() + 2][1];
    }

    return nextValue;
  };

  const renderDocumentsItems = (items) => {
    let list = [...items];
    if (project.customer.kind === CustomerKind.Person) {
      list = list.filter((item) => item.name !== 'fileCustomerAuthorization');
    }

    return list.map((i) => (
      <ListGroupItem key={i.name} className="px-0">
        <Row className="align-items-center">
          <Col className="col-auto">
            <a
              onClick={(e) => {
                if (!project[i.name]) e.preventDefault();
              }}
              style={{ height: '32px', width: '32px' }}
              href={project[i.name] ? getFileObject(project[i.name]) : '#'}
              target="_blank"
              className={`d-block ${
                project[i.name] ? 'bg-primary' : 'bg-gray'
              } text-white d-flex justify-content-center align-items-center rounded`}
            >
              <i className={i.icon}></i>
            </a>
          </Col>

          <div className="col">
            <a
              onClick={(e) => {
                if (!project[i.name]) e.preventDefault();
              }}
              style={{ height: '32px', width: '32px' }}
              href={project[i.name] ? getFileObject(project[i.name]) : '#'}
              target="_blank"
            >
              <h5 className="mb-0">
                {i.label}
                {i.name === 'fileNoObjectionLetter' && project[i.name]
                  ? ` (${
                      moment(project.noObjectionSubmittedAt)
                        .add(60, 'days')
                        .isAfter(moment())
                        ? 'Expira'
                        : 'Expiró'
                    } ${moment(project.noObjectionSubmittedAt)
                      .add(60, 'days')
                      .fromNow()})`
                  : ''}
              </h5>
            </a>
          </div>

          <div className="col">
            {project[i.name] ? (
              <Button
                color="warning"
                size="sm"
                className="d-block ml-auto"
                onClick={async (e) => {
                  if (
                    !window.confirm(
                      '¿Seguro que quieres eliminar este documento?'
                    )
                  )
                    return;
                  e.preventDefault();
                  try {
                    await onDocumentDelete(i.name);
                  } catch (e) {
                    /* handle error */
                  }
                }}
              >
                <i className="fas fa-trash"></i>
              </Button>
            ) : (
              <Button
                color="default"
                size="sm"
                className="d-block ml-auto"
                onClick={(e) => {
                  e.preventDefault();
                  initDocumentUpload(i.name);
                }}
              >
                <i className="fas fa-upload"></i>
              </Button>
            )}
          </div>
        </Row>
      </ListGroupItem>
    ));
  };

  const renderAvailableDocs = () => {
    const list = Object.entries(submissionDocuments)
      .map(([key, doc], sIndex) => {
        if (
          key.includes('fileIdenticiation') &&
          !project.customer.fileIdenticiation
        ) {
          return null;
        } else if (
          key.includes('fileIdenticiation') &&
          project.customer.fileIdenticiation
        ) {
          return (
            <div
              key={`submission-document-${sIndex}`}
              className="submission-document"
            >
              <div className="submission-document-container">
                <AppCheckbox
                  noMargin
                  label={doc.name}
                  id={key}
                  name={key}
                  checked={submissionExportForm[key]}
                  onChange={handleSubmissionExportFormChange}
                ></AppCheckbox>
              </div>

              <a
                href="#"
                onClick={() => {
                  alert(
                    'Puedes modificar la identificación al cliente en la siguiente sección'
                  );
                  history.push(`clientes?id=${project.customer._id}`);
                }}
                className="submission-document__action"
              >
                <i className="fas fa-pen"></i>
              </a>
            </div>
          );
        } else if (
          key.includes('fileMerchantRegistry') &&
          !project.customer.fileMerchantRegistry
        ) {
          return null;
        } else if (
          key.includes('fileMerchantRegistry') &&
          project.customer.fileMerchantRegistry
        ) {
          return (
            <div
              key={`submission-document-${sIndex}`}
              className="submission-document"
            >
              <div className="submission-document-container">
                <AppCheckbox
                  noMargin
                  label={doc.name}
                  id={key}
                  name={key}
                  checked={submissionExportForm[key]}
                  onChange={handleSubmissionExportFormChange}
                ></AppCheckbox>
              </div>

              <a
                href="#"
                onClick={() => {
                  alert(
                    'Puedes modificar el registro mercantil en la siguiente sección'
                  );
                  history.push(`clientes?id=${project.customer._id}`);
                }}
                className="submission-document__action"
              >
                <i className="fas fa-pen"></i>
              </a>
            </div>
          );
        } else if (
          key.includes('filePactAssembly') &&
          !project.customer.filePactAssembly
        ) {
          return null;
        } else if (
          key.includes('filePactAssembly') &&
          project.customer.filePactAssembly
        ) {
          return (
            <div
              key={`submission-document-${sIndex}`}
              className="submission-document"
            >
              <div className="submission-document-container">
                <AppCheckbox
                  noMargin
                  label={doc.name}
                  id={key}
                  name={key}
                  checked={submissionExportForm[key]}
                  onChange={handleSubmissionExportFormChange}
                ></AppCheckbox>
              </div>

              <a
                href="#"
                onClick={() => {
                  alert(
                    'Puedes modificar el acta de asamblea en la siguiente sección'
                  );
                  history.push(`clientes?id=${project.customer._id}`);
                }}
                className="submission-document__action"
              >
                <i className="fas fa-pen"></i>
              </a>
            </div>
          );
        } else if (
          [
            'anexoB',
            'fileNoObjectionLetter',
            'mailSecondVisit',
            'mailProjectFinished'
          ].includes(key) &&
          [
            ProjectStatus.FirstPending,
            ProjectStatus.FirstReadyToSend,
            ProjectStatus.FirstInProgress,
            ProjectStatus.FirstRejected
          ].includes(projectForm.status)
        ) {
          return null;
        } else if (
          doc.projectFields.filter((d) =>
            Array.isArray(projectForm[d])
              ? projectForm[d].length === 0
              : !projectForm[d]
          ).length +
            doc.submissionFields.filter((d) =>
              Array.isArray(submissionForm[d])
                ? submissionForm[d].length === 0
                : !submissionForm[d]
            ).length >
          0
        ) {
          return null;
        } else if (key.includes('file') && !projectForm[key]) {
          return null;
        }

        return (
          <div
            key={`submission-document-${sIndex}`}
            className="submission-document"
          >
            <div className="submission-document-container">
              <AppCheckbox
                noMargin
                label={doc.name}
                id={key}
                name={key}
                checked={submissionExportForm[key]}
                onChange={handleSubmissionExportFormChange}
              ></AppCheckbox>
            </div>

            <a
              href="#"
              onClick={() => {
                setEditingParams([
                  ...doc.projectFields,
                  ...doc.submissionFields
                ]);
              }}
              className="submission-document__action"
            >
              <i className="fas fa-pen"></i>
            </a>
          </div>
        );
      })
      .filter((item) => !!item);

    if (list.length === 0) return null;

    return [
      <div className="submission-documents__heading">
        <span>Disponible</span>

        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            selectAllDocs();
          }}
        >
          Todos
        </a>

        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            unSelectAllDocs();
          }}
        >
          Ninguno
        </a>
      </div>,
      ...list
    ];
  };

  const renderPendingDocs = () => {
    const list = Object.entries(submissionDocuments)
      .map(([key, doc], sIndex) => {
        const pendingProjectFieldsQty = doc.projectFields.filter((d) =>
          Array.isArray(projectForm[d])
            ? projectForm[d].length === 0
            : !projectForm[d]
        ).length;
        const pendingSubmissionFieldsQty = doc.submissionFields.filter((d) =>
          Array.isArray(submissionForm[d])
            ? submissionForm[d].length === 0
            : !submissionForm[d]
        ).length;
        const isNormalDocPending =
          pendingProjectFieldsQty + pendingSubmissionFieldsQty > 0;
        const isFileDoc = key.includes('file');

        if (
          key.includes('fileIdenticiation') &&
          !project.customer.fileIdenticiation
        ) {
          return (
            <div
              key={`submission-document-pending-${sIndex}`}
              className="submission-document"
            >
              <div className="submission-document__name">{doc.name}</div>

              <a
                href="#"
                onClick={() => {
                  alert(
                    'Adjunta la identificación al cliente en la siguiente sección'
                  );
                  history.push(`clientes?id=${project.customer._id}`);
                }}
                className="submission-document__edit"
              >
                Visitar
              </a>
            </div>
          );
        } else if (
          key.includes('fileIdenticiation') &&
          project.customer.fileIdenticiation
        ) {
          return null;
        } else if (
          key.includes('fileMerchantRegistry') &&
          !project.customer.fileMerchantRegistry
        ) {
          return (
            <div
              key={`submission-document-pending-${sIndex}`}
              className="submission-document"
            >
              <div className="submission-document__name">{doc.name}</div>

              <a
                href="#"
                onClick={() => {
                  alert(
                    'Adjunta la identificación al cliente en la siguiente sección'
                  );
                  history.push(`clientes?id=${project.customer._id}`);
                }}
                className="submission-document__edit"
              >
                Visitar
              </a>
            </div>
          );
        } else if (
          key.includes('fileMerchantRegistry') &&
          project.customer.fileMerchantRegistry
        ) {
          return null;
        } else if (
          key.includes('filePactAssembly') &&
          !project.customer.filePactAssembly
        ) {
          return (
            <div
              key={`submission-document-pending-${sIndex}`}
              className="submission-document"
            >
              <div className="submission-document__name">{doc.name}</div>

              <a
                href="#"
                onClick={() => {
                  alert(
                    'Adjunta la identificación al cliente en la siguiente sección'
                  );
                  history.push(`clientes?id=${project.customer._id}`);
                }}
                className="submission-document__edit"
              >
                Visitar
              </a>
            </div>
          );
        } else if (
          key.includes('filePactAssembly') &&
          project.customer.filePactAssembly
        ) {
          return null;
        } else if (
          [
            'anexoB',
            'fileNoObjectionLetter',
            'mailSecondVisit',
            'mailProjectFinished'
          ].includes(key) &&
          [
            ProjectStatus.FirstPending,
            ProjectStatus.FirstReadyToSend,
            ProjectStatus.FirstInProgress,
            ProjectStatus.FirstRejected
          ].includes(projectForm.status)
        ) {
          return null;
        } else if (isFileDoc && projectForm[key]) {
          return null;
        } else if (isFileDoc && !projectForm[key]) {
          return (
            <div
              key={`submission-document-pending-${sIndex}`}
              className="submission-document"
            >
              <div className="submission-document__name">{doc.name}</div>

              <a
                href="#"
                onClick={() => {
                  initDocumentUpload(key);
                }}
                className="submission-document__edit"
              >
                Adjuntar
              </a>
            </div>
          );
        } else if (isNormalDocPending) {
          return (
            <div
              key={`submission-document-pending-${sIndex}`}
              className="submission-document"
            >
              <div className="submission-document__name">{doc.name}</div>

              <a
                href="#"
                onClick={() => {
                  setEditingParams([
                    ...doc.projectFields,
                    ...doc.submissionFields
                  ]);
                }}
                className="submission-document__edit"
              >
                Completar
              </a>
            </div>
          );
        }

        return null;
      })
      .filter((item) => !!item);

    if (list.length === 0) return null;

    return [
      <div
        key="submission-document-heading-pending"
        className="submission-documents__heading submission-documents__heading--mt"
      >
        Pendiente
      </div>,
      ...list
    ];
  };

  return (
    <>
      <AlternativeHeader
        name={`${project.name}`}
        parentName="Proyectos"
        onBackClick={onClose}
        onNewLabel="Editar"
        customAction={
          <div
            style={{
              width: 'fit-content',
              marginLeft: 'auto',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <AppInput
              type="select"
              value={projectForm.status}
              onChange={handleStatusChange}
              style={{ margin: 0 }}
            >
              {renderStatusOptions()}
            </AppInput>

            <Button
              className="btn-neutral ml-2"
              color=""
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                onEditClick(project._id, false);
              }}
              size="sm"
            >
              Editar
            </Button>

            {project.status !== ProjectStatus.Running &&
            Object.entries(ProjectStatus)[
              getCurrentStatusIndex() + 1
            ][1].includes('No cumple') ? (
              <Button
                className="ml-2"
                color="warning"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  initRejection(
                    Object.entries(ProjectStatus)[
                      getCurrentStatusIndex() + 1
                    ][1]
                  );
                }}
                size="sm"
              >
                <i
                  className="fas fa-exclamation-triangle"
                  style={{ marginRight: '8px' }}
                ></i>{' '}
                No cumple
              </Button>
            ) : null}

            {project.status !== ProjectStatus.Running ? (
              <Button
                className="ml-2"
                color="primary"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  handleNextStep();
                }}
                size="sm"
              >
                {nextStatusLabel()}{' '}
                <i
                  className="fas fa-arrow-right"
                  style={{ marginLeft: '8px' }}
                ></i>
              </Button>
            ) : null}
          </div>
        }
      />

      <Container fluid>
        <div className="submission">
          <div style={{ gridColumn: '1 / 3' }}>
            <strong>{project.status}</strong>
            <br />
            <Link to={`/clientes?id=${project.customer._id}`}>
              {getName(project.customer)}{' '}
              {project.customer.alias ? `(${project.customer.alias})` : ''}
            </Link>
            <br />
            <small>
              Contrato: {project.energySupplierContract} - Medidor:{' '}
              {project.energySupplierMeterNumber}
              <br />
              {project.locationUrl ? (
                <a href={project.locationUrl} target="_blank">
                  Nave{' '}
                </a>
              ) : null}
              {project.posteLocationUrl ? (
                <a href={project.posteLocationUrl} target="_blank">
                  - Poste{' '}
                </a>
              ) : null}
              {project.droneDeployUrl ? (
                <a href={project.droneDeployUrl} target="_blank">
                  - Drone Deploy
                </a>
              ) : null}
            </small>
          </div>

          <div>
            <div className="submission-activities">
              <div className="submission-activities-header">
                <span>Movimientos</span>
                {/* <a href="#" className="submission-activities__action"> */}
                {/*   Todos <i className="fas fa-chevron-down"></i> */}
                {/* </a> */}
              </div>

              <div className="submission-activities-container">
                {project.checkpoints.length === 0 ? (
                  <small style={{ textAlign: 'center' }}>
                    No hay movimientos
                  </small>
                ) : null}

                {checkpoints().map((checkpoint) => (
                  <div className="submission-activity" key={`checkpoint-${checkpoint._id}`}>
                    <div className="submission-activity__date">
                      {checkpoint.kind === CheckpointKind.Objection
                        ? 'Rechazo'
                        : checkpoint.kind}{' '}
                      {moment(checkpoint.createdAt).format('DD MMM YYYY')}{' '}
                      <span>
                        {moment(checkpoint.createdAt).format('hh:mm A')}
                      </span>
                    </div>

                    <div className="submission-activity-container">
                      <div className="submission-activity__name">
                        {checkpoint.name}
                      </div>

                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckpointDelete(checkpoint._id);
                        }}
                        href="#"
                        className="submission-activity__action"
                        title="Ver sometimiento"
                      >
                        <i className="fas fa-trash"></i>
                      </a>

                      {checkpoint.kind === CheckpointKind.Submission ? (
                        <>
                          {/* <a */}
                          {/*   href="#" */}
                          {/*   className="submission-activity__action" */}
                          {/*   title="Enviar nuevamente" */}
                          {/* > */}
                          {/*   <i className="fas fa-paper-plane"></i> */}
                          {/* </a> */}

                          {checkpoint.file ? (
                            <a
                              href={getFileObject(checkpoint.file)}
                              target="_blank"
                              className="submission-activity__action"
                              title="Descargar archivos"
                            >
                              <i className="fas fa-cloud-download-alt"></i>
                            </a>
                          ) : null}

                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              setViewingCheckpoint(checkpoint);
                            }}
                            href="#"
                            className="submission-activity__action"
                            title="Ver sometimiento"
                          >
                            <i className="fas fa-eye"></i>
                          </a>
                        </>
                      ) : (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            setViewingCheckpoint(checkpoint);
                          }}
                          href="#"
                          className="submission-activity__action"
                          title="Ver objeción"
                        >
                          <i className="fas fa-eye"></i>
                        </a>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="submission-components">
              <div className="submission-components-header">
                <h3 className="submission-components-header__title">
                  <span>Equipos</span>

                  <a
                    href="#"
                    onClick={() => {
                      setEditingParams([...editingParams, 'components']);
                    }}
                  >
                    <i className="fas fa-pen"></i>
                  </a>
                </h3>

                <div className="submission-components-summary">
                  <img
                    alt="..."
                    className="inventory-categories-item__img"
                    src={require('assets/img/icons/solar-panels.png').default}
                  />
                  {getElectricalUnit(
                    projectForm.components.reduce(
                      (a, b) =>
                        b.product.category === ProductCategory.SolarPanels
                          ? a + b.product.capacity * b.qty
                          : a,
                      0
                    )
                  )}
                  p
                  <img
                    className="inventory-categories-item__img"
                    src={require('assets/img/icons/generator.png').default}
                  />
                  {getElectricalUnit(
                    projectForm.components.reduce(
                      (a, b) =>
                        b.product.category === ProductCategory.Inverters
                          ? a + b.product.capacity * b.qty
                          : a,
                      0
                    ) * 1000
                  )}
                </div>
              </div>

              <div className="submission-components-container">
                {projectForm.components.length === 0 ? (
                  <small style={{ textAlign: 'center' }}>
                    No has seleccionado equipos
                  </small>
                ) : null}

                {projectForm.components.map((c, cIndex) => (
                  <div
                    className="submission-component"
                    key={`submission-component-${cIndex}`}
                  >
                    <div className="submission-component__name">
                      <a
                        href={`/productos?id=${c.product._id}`}
                        target="_blank"
                      >
                        {c.product.category === ProductCategory.Inverters
                          ? 'Inversor '
                          : 'Panel '}
                        {c.product.name}
                      </a>
                    </div>

                    <div className="submission-component__capacity">
                      <span>{c.qty}</span>
                      <span>
                        <i className="fas fa-times"></i>
                      </span>
                      <span>
                        {c.product.capacity}
                        {c.product.category === ProductCategory.Inverters
                          ? 'Kw'
                          : 'w'}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="submission-documents">
            <div className="submission-activities-header">
              <span>Documentación</span>
            </div>

            <div className="submission-documents-container">
              {renderAvailableDocs()}

              {renderPendingDocs()}

              <div className="submission-documents-actions">
                <a
                  href="#"
                  onClick={() => {
                    if (!Object.values(submissionExportForm).find((v) => !!v)) {
                      alert('Debes seleccionar al menos 1 documento');
                      return;
                    }
                    setIsGeneratingExport(true);
                  }}
                  className="submission-documents__action"
                >
                  Exportar PDF
                </a>

                {/* <a href="#" className="submission-documents__action"> */}
                {/*   Correo a Edenorte */}
                {/* </a> */}
              </div>
            </div>
          </div>

          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">Adjuntos</h3>
                </Col>
              </Row>
            </CardHeader>

            <CardBody className="pt-3">
              <input
                hidden
                ref={documentFieldRef}
                type="file"
                onChange={handleDocumentFieldChange}
              />

              <ListGroup className="list" flush>
                {renderDocumentsItems(miscFiles)}
              </ListGroup>

              <h5>Primera visita</h5>

              <ListGroup className="list" flush>
                {renderDocumentsItems(firstVisitItems)}
              </ListGroup>

              {![
                ProjectStatus.FirstPending,
                ProjectStatus.FirstReadyToSend,
                ProjectStatus.FirstRejected
              ].includes(project.status) ? (
                <>
                  <h5 className="mt-3">Segunda visita</h5>

                  <ListGroup className="list" flush>
                    {renderDocumentsItems(secondVisitItems)}
                  </ListGroup>
                </>
              ) : null}

              {![
                ProjectStatus.FirstPending,
                ProjectStatus.FirstReadyToSend,
                ProjectStatus.FirstRejected,
                ProjectStatus.FirstInProgress,
                ProjectStatus.SecondPending,
                ProjectStatus.SecondReadyToSend,
                ProjectStatus.SecondRejected
              ].includes(project.status) ? (
                <>
                  <h5 className="mt-3">Acuerdo</h5>

                  <ListGroup className="list" flush>
                    {renderDocumentsItems(agreementItems)}
                  </ListGroup>
                </>
              ) : null}

              {![
                ProjectStatus.FirstPending,
                ProjectStatus.FirstReadyToSend,
                ProjectStatus.FirstRejected,
                ProjectStatus.FirstInProgress,
                ProjectStatus.SecondPending,
                ProjectStatus.SecondReadyToSend,
                ProjectStatus.SecondRejected,
                ProjectStatus.SecondInProgress,
                ProjectStatus.AgreementPending,
                ProjectStatus.AgreementSent
              ].includes(project.status) ? (
                <>
                  <h5 className="mt-3">Medidor</h5>

                  <ListGroup className="list" flush>
                    {renderDocumentsItems(counterItems)}
                  </ListGroup>
                </>
              ) : null}
            </CardBody>
          </Card>

          {!isLoading ? <AppNotes resourceId={project._id} /> : null}

          {!isLoading ? <AppActivity resourceId={project._id} /> : null}

          {!isLoading ? (
            <AppAttachments resourceType="Project" resourceId={project._id} />
          ) : null}
        </div>
      </Container>

      <Modal
        fade={false}
        isOpen={editingParams.length > 0}
        size="md"
        className="modal-dialog-centered modal-secondary"
      >
        <ModalHeader>Parametros</ModalHeader>

        <ModalBody className="py-0">
          <Card>
            <CardBody>
              <Form>
                {editingParams.includes('energySupplierContract') ? (
                  <>
                    {energySuppliers.length > 0 ? (
                      <AppInput
                        label="Número de contrato"
                        id="energySupplierContract"
                        name="energySupplierContract"
                        type="text"
                        value={projectForm.energySupplierContract || ''}
                        error={errors.energySupplierContract}
                        onChange={handleProjectFormChange}
                        onBlur={handleProjectFormChange}
                      />
                    ) : null}
                  </>
                ) : null}

                {editingParams.includes('energySupplierMeterNumber') ? (
                  <>
                    {energySuppliers.length > 0 ? (
                      <AppInput
                        label="Número de medidor"
                        id="energySupplierMeterNumber"
                        name="energySupplierMeterNumber"
                        type="text"
                        value={projectForm.energySupplierMeterNumber || ''}
                        error={errors.energySupplierMeterNumber}
                        onChange={handleProjectFormChange}
                        onBlur={handleProjectFormChange}
                      />
                    ) : null}
                  </>
                ) : null}

                {editingParams.includes('energySupplier') ? (
                  <>
                    {energySuppliers.length > 0 ? (
                      <AppInput
                        label="Distribuidora"
                        id="energySupplier"
                        name="energySupplier"
                        type="select"
                        value={projectForm.energySupplier || ''}
                        error={errors.energySupplier}
                        onChange={handleProjectFormChange}
                        onBlur={handleProjectFormChange}
                      >
                        <option value=""></option>
                        {energySuppliers.map((eS) => (
                          <option value={eS._id} key={`e-s-${eS._id}`}>
                            {eS.name}
                          </option>
                        ))}
                      </AppInput>
                    ) : null}
                  </>
                ) : null}

                {editingParams.includes('authorizedBy') ? (
                  <>
                    {usersForSubmission.length > 0 ? (
                      <AppInput
                        label="Autorizado por"
                        id="authorizedBy"
                        name="authorizedBy"
                        type="select"
                        value={submissionForm.authorizedBy || ''}
                        error={errors.authorizedBy}
                        onChange={handleSubmissionFormChange}
                        onBlur={handleSubmissionFormChange}
                      >
                        <option value=""></option>
                        {usersForSubmission
                          .filter((u) =>
                            [
                              UserRole.Admin,
                              UserRole.GeneralSupervisor
                            ].includes(u.role)
                          )
                          .map((u) => (
                            <option value={u._id} key={`a-b-${u._id}`}>
                              {getName(u)}
                            </option>
                          ))}
                      </AppInput>
                    ) : null}
                  </>
                ) : null}

                {editingParams.includes('supervisedBy') ? (
                  <>
                    {usersForSubmission.length > 0 ? (
                      <AppInput
                        label="Encargado del sometimiento"
                        id="supervisedBy"
                        name="supervisedBy"
                        type="select"
                        value={submissionForm.supervisedBy || ''}
                        error={errors.supervisedBy}
                        onChange={handleSubmissionFormChange}
                        onBlur={handleSubmissionFormChange}
                      >
                        <option value=""></option>
                        {usersForSubmission
                          .filter(
                            (u) =>
                              [UserPosition.ProjectAuxiliary].includes(
                                u.position
                              ) ||
                              [
                                UserRole.Admin,
                                UserRole.GeneralSupervisor
                              ].includes(u.role)
                          )
                          .map((u) => (
                            <option value={u._id} key={`s-b-${u._id}`}>
                              {getName(u)}
                            </option>
                          ))}
                      </AppInput>
                    ) : null}
                  </>
                ) : null}

                {editingParams.includes('codiaBy') ? (
                  <>
                    {usersForSubmission.length > 0 ? (
                      <AppInput
                        label="Ingeniero responsable"
                        id="codiaBy"
                        name="codiaBy"
                        type="select"
                        value={submissionForm.codiaBy || ''}
                        error={errors.codiaBy}
                        onChange={handleSubmissionFormChange}
                        onBlur={handleSubmissionFormChange}
                      >
                        <option value=""></option>
                        {usersForSubmission
                          .filter((u) => u.fileCodiaCertificate)
                          .map((u) => (
                            <option value={u._id} key={`c-b-${u._id}`}>
                              {getName(u)}
                            </option>
                          ))}
                      </AppInput>
                    ) : null}
                  </>
                ) : null}

                {editingParams.includes('endedNote') ? (
                  <>
                    {usersForSubmission.length > 0 ? (
                      <AppInput
                        label="Nota de proyecto terminado"
                        id="endedNote"
                        name="endedNote"
                        type="text"
                        value={submissionForm.endedNote || ''}
                        error={errors.endedNote}
                        onChange={handleSubmissionFormChange}
                        onBlur={handleSubmissionFormChange}
                      />
                    ) : null}
                  </>
                ) : null}

                {editingParams.includes('submittedAt') ? (
                  <>
                    <AppInput
                      label="Fecha sometimiento primera visita"
                      id="submittedAt"
                      name="submittedAt"
                      type="date"
                      value={submissionForm.submittedAt}
                      error={errors.submittedAt}
                      onChange={handleSubmissionFormChange}
                    />
                  </>
                ) : null}

                {editingParams.includes('secondVisitSubmittedAt') ? (
                  <>
                    <AppInput
                      label="Fecha sometimiento segunda visita"
                      id="secondVisitSubmittedAt"
                      name="secondVisitSubmittedAt"
                      type="date"
                      value={submissionForm.secondVisitSubmittedAt}
                      error={errors.secondVisitSubmittedAt}
                      onChange={handleSubmissionFormChange}
                    />
                  </>
                ) : null}

                {editingParams.includes('propertyRight') ? (
                  <>
                    <AppInput
                      label="Relación del solicitante con la propiedad"
                      id="propertyRight"
                      name="propertyRight"
                      type="select"
                      value={submissionForm.propertyRight || ''}
                      error={errors.propertyRight}
                      onChange={handleSubmissionFormChange}
                      onBlur={handleSubmissionFormChange}
                    >
                      {Object.entries(PropertyRight).map(([_, pR], i) => (
                        <option value={pR} key={`pR-${i}`}>
                          {pR}
                        </option>
                      ))}
                    </AppInput>

                    {submissionForm.propertyRight === PropertyRight.Other ? (
                      <AppInput
                        label="Otro tipo de relación con la propiedad"
                        id="propertyRightOther"
                        name="propertyRightOther"
                        value={submissionForm.propertyRightOther || ''}
                        error={errors.propertyRightOther}
                        onChange={handleSubmissionFormChange}
                        onBlur={handleSubmissionFormChange}
                      />
                    ) : null}

                    {submissionForm.propertyRight !== PropertyRight.Owner ? (
                      <>
                        <h3 className="submission-form__question">
                          ¿Si no es {getName(project.customer)}, quien es?
                        </h3>

                        <div className="submission-form__hidden-section">
                          <Row>
                            <Col sm="6">
                              <AppInput
                                label="Nombre"
                                id="ownerName"
                                name="ownerName"
                                value={submissionForm.ownerName || ''}
                                error={errors.ownerName}
                                onChange={handleSubmissionFormChange}
                                onBlur={handleSubmissionFormChange}
                              />
                            </Col>

                            <Col sm="6">
                              <AppInput
                                label="Teléfono (día)"
                                id="ownerPhoneDay"
                                name="ownerPhoneDay"
                                value={submissionForm.ownerPhoneDay || ''}
                                error={errors.ownerPhoneDay}
                                onChange={handleSubmissionFormChange}
                                onBlur={handleSubmissionFormChange}
                              />
                            </Col>

                            <Col sm="6">
                              <AppInput
                                label="Teléfono (noche)"
                                id="ownerPhoneNight"
                                name="ownerPhoneNight"
                                value={submissionForm.ownerPhoneNight || ''}
                                error={errors.ownerPhoneNight}
                                onChange={handleSubmissionFormChange}
                                onBlur={handleSubmissionFormChange}
                              />
                            </Col>

                            <Col sm="6">
                              <AppInput
                                label="Celular"
                                id="ownerMobilePhone"
                                name="ownerMobilePhone"
                                value={submissionForm.ownerMobilePhone || ''}
                                error={errors.ownerMobilePhone}
                                onChange={handleSubmissionFormChange}
                                onBlur={handleSubmissionFormChange}
                              />
                            </Col>

                            <Col sm="6">
                              <AppInput
                                label="Correo Eléctronico"
                                id="ownerEmail"
                                name="ownerEmail"
                                value={submissionForm.ownerEmail || ''}
                                error={errors.ownerEmail}
                                onChange={handleSubmissionFormChange}
                                onBlur={handleSubmissionFormChange}
                              />
                            </Col>

                            <Col sm="6">
                              <AppInput
                                label="Cédula de identidad"
                                id="ownerIdentification"
                                name="ownerIdentification"
                                value={submissionForm.ownerIdentification || ''}
                                error={errors.ownerIdentification}
                                onChange={handleSubmissionFormChange}
                                onBlur={handleSubmissionFormChange}
                              />
                            </Col>
                          </Row>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}

                {editingParams.includes('components') ? (
                  <>
                    <label
                      htmlFor="productValue"
                      className="form-control-label"
                    >
                      Componentes del sistema
                    </label>

                    <div className="submission-products">
                      {projectForm.components.map((c, cIndex) => (
                        <div
                          className="submission-products-item"
                          key={`sP-${cIndex}`}
                        >
                          <div className="submission-products-item__name">
                            <a
                              href={`/productos?id=${c.product._id}`}
                              target="_blank"
                            >
                              {c.product.name}
                            </a>
                          </div>

                          <div className="submission-products-item__qty">
                            <AppInput
                              type="number"
                              value={c.qty}
                              onChange={(e) => {
                                if (e.target.value < 0) return;

                                const newComponents = [
                                  ...projectForm.components
                                ];
                                newComponents[cIndex].qty = e.target.value;
                                setProjectForm({
                                  ...projectForm,
                                  components: newComponents
                                });
                              }}
                            />
                          </div>

                          <a
                            className="submission-products-item__delete"
                            onClick={(e) => {
                              e.preventDefault();
                              setProjectForm({
                                ...projectForm,
                                components: projectForm.components.filter(
                                  (oC) => oC.product._id !== c.product._id
                                )
                              });
                            }}
                          >
                            <i className="fas fa-trash"></i>
                          </a>
                        </div>
                      ))}
                    </div>

                    <div className="app-search">
                      <AppInput
                        className="submission-products__search"
                        id="productValue"
                        name="productValue"
                        type="search"
                        value={productSearchValue}
                        placeholder="Nombre del producto"
                        onChange={(e) => {
                          setProductSearchValue(e.target.value);
                          fetchProducts(e.target.value);
                        }}
                      />

                      {productSearchValue && products.length > 0 ? (
                        <ul className="app-search-results">
                          {productSearchValue &&
                            products
                              .filter(
                                (p) =>
                                  !projectForm.components.find(
                                    (c) => c.product._id === p._id
                                  )
                              )
                              .map((p) => (
                                <li key={`ap-search-${p._id}`}>
                                  <a
                                    href=""
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setProjectForm({
                                        ...projectForm,
                                        components: [
                                          ...projectForm.components,
                                          { product: p, qty: 1 }
                                        ]
                                      });
                                    }}
                                  >
                                    {p.name}
                                  </a>
                                </li>
                              ))}
                        </ul>
                      ) : null}
                    </div>
                  </>
                ) : null}

                {editingParams.includes('customerKind') ? (
                  <>
                    <AppInput
                      label="Tipo de cliente"
                      id="customerKind"
                      name="customerKind"
                      type="select"
                      value={submissionForm.customerKind || ''}
                      error={errors.customerKind}
                      onChange={handleSubmissionFormChange}
                      onBlur={handleSubmissionFormChange}
                    >
                      {Object.entries(CustomerType).map(([k, pR], i) => (
                        <option value={pR} key={`pR-${i}`}>
                          {pR}
                        </option>
                      ))}
                    </AppInput>
                  </>
                ) : null}
              </Form>
            </CardBody>
          </Card>

          {/* <FormGroup> */}
          {/*   <label */}
          {/*     htmlFor="voucher" */}
          {/*     className="form-control-label d-flex justify-content-between" */}
          {/*   > */}
          {/*     Voucher */}
          {/*     {!true ? ( */}
          {/*       <Button */}
          {/*         className="d-block" */}
          {/*         size="sm" */}
          {/*         color="primary" */}
          {/*         outline */}
          {/*         onClick={(e) => { */}
          {/*           e.preventDefault(); */}
          {/*           // voucherInputRef.current.click(); */}
          {/*         }} */}
          {/*       > */}
          {/*         CARGAR */}
          {/*       </Button> */}
          {/*     ) : ( */}
          {/*       <Button */}
          {/*         className="d-block" */}
          {/*         size="sm" */}
          {/*         color="danger" */}
          {/*         outline */}
          {/*         onClick={(e) => { */}
          {/*           e.preventDefault(); */}
          {/*           // setVoucherFile(null); */}
          {/*         }} */}
          {/*       > */}
          {/*         ELIMINAR */}
          {/*       </Button> */}
          {/*     )} */}
          {/*   </label> */}

          {/*   {/1* {voucherFile ? ( *1/} */}
          {/*   {/1*   <span>{voucherFile.name}</span> *1/} */}
          {/*   {/1* ) : ( *1/} */}
          {/*   {/1*   <span className="text-muted"> *1/} */}
          {/*   {/1*     No tienes voucher adjunto *1/} */}
          {/*   {/1*   </span> *1/} */}
          {/*   {/1* )} *1/} */}

          {/*   {/1* <input *1/} */}
          {/*   {/1*   ref={voucherInputRef} *1/} */}
          {/*   {/1*   hidden *1/} */}
          {/*   {/1*   type="file" *1/} */}
          {/*   {/1*   onChange={handleVoucherFileChange} *1/} */}
          {/*   {/1* /> *1/} */}
          {/* </FormGroup> */}
        </ModalBody>

        <ModalFooter>
          {isSending ? (
            <Button
              className="new-event--add"
              color="primary"
              size="sm"
              type="button"
            >
              <Spinner size="sm" />
            </Button>
          ) : (
            <Button
              className="new-event--add"
              color="primary"
              size="sm"
              type="button"
              onClick={handleSave}
            >
              Guardar
            </Button>
          )}

          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      {rejectionCheckpoint ? (
        <Modal
          fade={false}
          isOpen={rejectionCheckpoint}
          size="md"
          className="modal-dialog-centered modal-secondary"
        >
          <ModalHeader>Nuevo rechazo</ModalHeader>

          <ModalBody className="py-0">
            <Card>
              <CardBody>
                <Form>
                  <AppInput
                    label="Razón del rechazo"
                    id="name"
                    name="name"
                    type="text"
                    value={rejectionCheckpoint.name || ''}
                    error={errors.name}
                    onChange={handleRejectionCheckpointChange}
                    onBlur={handleRejectionCheckpointChange}
                  />
                  <AppInput
                    label="Nota"
                    id="note"
                    name="note"
                    type="text"
                    value={rejectionCheckpoint.note || ''}
                    error={errors.note}
                    onChange={handleRejectionCheckpointChange}
                    onBlur={handleRejectionCheckpointChange}
                  />
                  <AppInput
                    label="Fecha del rechazo"
                    id="createdAt"
                    name="createdAt"
                    type="date"
                    value={rejectionCheckpoint.createdAt}
                    error={errors.createdAt}
                    onChange={handleRejectionCheckpointChange}
                  />
                  <FormGroup>
                    <label
                      htmlFor="voucher"
                      className="form-control-label d-flex justify-content-between"
                    >
                      Adjunto
                      {!rejectionCheckpointFile ? (
                        <Button
                          className="d-block"
                          size="sm"
                          color="primary"
                          outline
                          onClick={(e) => {
                            e.preventDefault();
                            rejectionCheckpointFileInputRef.current.click();
                          }}
                        >
                          CARGAR
                        </Button>
                      ) : (
                        <Button
                          className="d-block"
                          size="sm"
                          color="danger"
                          outline
                          onClick={(e) => {
                            e.preventDefault();
                            setRejectionCheckpointFile(null);
                          }}
                        >
                          ELIMINAR
                        </Button>
                      )}
                    </label>

                    {rejectionCheckpointFile ? (
                      <span>{rejectionCheckpointFile.name}</span>
                    ) : (
                      <span className="text-muted">
                        No has adjuntado un archivo
                      </span>
                    )}

                    <input
                      ref={rejectionCheckpointFileInputRef}
                      hidden
                      type="file"
                      onChange={handleRejectionCheckpointFileChange}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            {isSending ? (
              <Button
                className="new-event--add"
                color="primary"
                size="sm"
                type="button"
              >
                <Spinner size="sm" />
              </Button>
            ) : (
              <Button
                className="new-event--add"
                color="primary"
                size="sm"
                type="button"
                onClick={handleRejectionCheckpointSave}
              >
                Guardar
              </Button>
            )}

            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => {
                setRejectionCheckpoint(null);
              }}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}

      {nextStepPendingField.length > 0 ? (
        <Modal
          fade={false}
          isOpen={true}
          size="md"
          className="modal-dialog-centered modal-secondary"
        >
          <ModalHeader>Debes completar los siguientes documentos</ModalHeader>

          <ModalBody className="py-0">
            <Card className="mb-0">
              <CardBody className="mb-0">
                <ul className="list-unstyled mt-0 mb-0">
                  {nextStepPendingField.map((fieldName, fieldIndex) => (
                    <li key={`pending-field-${fieldIndex}`} className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge className="badge-circle mr-3" color="warning">
                            {fieldIndex + 1}
                          </Badge>
                        </div>
                        <div>
                          <span className="mb-0">{fieldName}</span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => {
                setNextStepPendingField([]);
              }}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}

      {isGeneratingExport ? (
        <Modal
          fade={false}
          isOpen={isGeneratingExport}
          size="md"
          className="modal-dialog-centered modal-secondary"
        >
          <ModalHeader>Generar PDF</ModalHeader>

          <ModalBody className="py-0">
            <Card>
              <CardBody>
                <Form>
                  <AppInput
                    label="¿Por qué quieres exportar el archivo?"
                    id="name"
                    name="name"
                    type="text"
                    value={documentsExportForm.name || ''}
                    error={errors.name}
                    onChange={handleDocumentExportFormChange}
                    onBlur={handleDocumentExportFormChange}
                  />
                  <AppInput
                    label="Nota"
                    id="note"
                    name="note"
                    type="text"
                    value={documentsExportForm.note || ''}
                    error={errors.note}
                    onChange={handleDocumentExportFormChange}
                    onBlur={handleDocumentExportFormChange}
                  />
                </Form>
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            {isSending ? (
              <Button
                className="new-event--add"
                color="primary"
                size="sm"
                type="button"
              >
                <Spinner size="sm" />
              </Button>
            ) : (
              <Button
                className="new-event--add"
                color="primary"
                size="sm"
                type="button"
                onClick={handleDocumentExport}
              >
                Generar
              </Button>
            )}

            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => {
                setDocumentExportForm({});
                setIsGeneratingExport(false);
              }}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}

      {viewingCheckpoint ? (
        <Modal
          fade={false}
          isOpen={viewingCheckpoint}
          size="md"
          className="modal-dialog-centered modal-secondary"
        >
          <ModalHeader>{viewingCheckpoint.kind}</ModalHeader>

          <ModalBody className="py-0">
            <Card>
              <CardBody>
                <small>
                  {moment(viewingCheckpoint.createdAt).format('DD MMM YYYY')}{' '}
                  <span>
                    {moment(viewingCheckpoint.createdAt).format('hh:mm A')}
                  </span>
                </small>

                {viewingCheckpoint.note ? (
                  <p className="mt-3">{viewingCheckpoint.note}</p>
                ) : null}

                {viewingCheckpoint.file ? (
                  <p className="mt-3">
                    <strong>Adjunto:</strong>{' '}
                    <a
                      href={getFileObject(viewingCheckpoint.file)}
                      target="_blank"
                      title="Descargar archivos"
                    >
                      {viewingCheckpoint.file.replace(
                        'documents-submissions/',
                        ''
                      )}
                    </a>
                  </p>
                ) : null}
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setViewingCheckpoint(null)}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
}

export default AppProjectView;
