import axios, { CancelToken } from 'axios';
// import { Settings } from '../constants';

const instance = axios.create();

instance.defaults.baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://seerpanel.com'
    : 'http://localhost:5000';
instance.defaults.withCredentials = true;

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // TODO: Sentry error logger
    return Promise.reject(error);
  }
);

export const requestToken = () => {
  const source = CancelToken.source();
  if (window.requestToken) {
    window.requestToken.cancel();
    window.requestToken = null;
  }
  window.requestToken = source;
  return source.token;
};

export default instance;
