import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col
} from 'reactstrap';

import AlternativeHeader from 'components/Headers/AlternativeHeader';
import AppInput from 'components/Forms/AppInput';

function AppEnergySupplierForm({ energySupplier, onSave, onClose }) {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (energySupplier._id) setForm({ ...energySupplier });
  }, []);

  const handleChange = (e) => {
    let newValue = e.target.value;
    if (e.target.type === 'checkbox') newValue = !form[e.target.name];

    setForm({
      ...form,
      [e.target.name]: newValue
    });
  };

  const handleSubmit = async () => {
    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    if (!form.name) {
      newErrors.name = 'Es obligatorio';
    }

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    try {
      await onSave(form);
    } catch (e) {
      setIsSending(false);
    }
  };

  return (
    <>
      <AlternativeHeader
        name={form.name || 'Nueva suplidora de energía'}
        parentName="Suplidoras de energía"
        onBackClick={onClose}
      />

      <Container className="mt--6">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  {energySupplier._id ? 'Editar' : 'Crear'} suplidora de energía
                </h3>
              </CardHeader>

              <CardBody>
                <Form>
                  <AppInput
                    label="Nombre *"
                    id="name"
                    name="name"
                    type="text"
                    value={form.name || ''}
                    error={errors.name}
                    required
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleChange(e)}
                  />

                  <AppInput
                    label="Teléfono Primario"
                    id="phone"
                    name="phone"
                    type="text"
                    value={form.phone || ''}
                    error={errors.phone}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleChange(e)}
                  />

                  <AppInput
                    label="Correo Eléctronico"
                    id="email"
                    name="email"
                    type="email"
                    value={form.email || ''}
                    error={errors.email}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleChange(e)}
                  />

                  <AppInput
                    label="Dirección"
                    id="address"
                    name="address"
                    type="text"
                    value={form.address || ''}
                    error={errors.address}
                    required
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleChange(e)}
                  />

                  <AppInput
                    label="Ciudad"
                    id="city"
                    name="city"
                    type="text"
                    placeholder="Santo Domingo, República Dominicana"
                    value={form.city || ''}
                    error={errors.city}
                    required
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleChange(e)}
                  />

                  <Button
                    size="sm"
                    color="primary"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AppEnergySupplierForm;
