import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from 'reactstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { getName, displayAmount } from 'utils';
import { TransactionKind } from '../../constants';
import { moment } from 'utils';
import { NotificationKind } from '../../constants';

function AppARShowTransactions({
  accountReceivable,
  transactions,
  count,
  page,
  itemsPerPage,
  onSearch,
  onPageClick,
  onItemClick,
  onDelete,
  onNewClick,
  onNewPaymentClick,
  onNewCreditNoteClick,
  notifyCustomer
}) {
  const [value, setValue] = useState('');
  const [filterInvoiceNumber, setFilterInvoiceNumber] = useState('');

  const getPrintURL = () => {
    return `${
      process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : ''
    }/api/accounts-receivable/${
      accountReceivable._id
    }/transactions?search=${JSON.stringify({
      filterInvoiceNumber,
      isGeneratingReport: true
    })}`;
  };

  const getTotalInvoiced = () => {
    let amount = 0.0;

    const invoice = transactions.find(
      (t) => t.invoiceNumber === filterInvoiceNumber
    );

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (
        filterInvoiceNumber &&
        (transactions[i].invoiceNumber !== filterInvoiceNumber ||
          (transactions[i].invoice &&
            invoice &&
            transactions[i].invoice._id !== invoice._id))
      ) {
        continue;
      }

      if (transactions[i].kind === TransactionKind.Invoice) {
        amount += transactions[i].total / 100;
      }
    }

    return amount;
  };

  const getTotalPaid = () => {
    let amount = 0.0;

    const invoice = transactions.find(
      (t) => t.invoiceNumber === filterInvoiceNumber
    );

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (
        !transactions[i].invoice ||
        (transactions[i].invoice &&
          invoice &&
          transactions[i].invoice._id !== invoice._id)
      ) {
        continue;
      }

      if (transactions[i].kind !== TransactionKind.Invoice) {
        amount += transactions[i].total / 100;
      }
    }

    return amount;
  };

  const filteredTransactions = () => {
    if (filterInvoiceNumber) {
      return transactions.filter(
        (t) =>
          t.invoiceNumber === filterInvoiceNumber ||
          (t.invoice && t.invoice.invoiceNumber === filterInvoiceNumber)
      );
    }
    return transactions;
  };

  const renderPagination = () => {
    const actualPage = page + 1;
    const totalPages =
      count > 0 && itemsPerPage > 0 ? Math.ceil(count / itemsPerPage) : 0;
    if (totalPages === 0) return null;

    const hasPrev = page > 0;
    const hasNext = actualPage < totalPages;
    const totalNextPages = totalPages - actualPage;
    let numbers = [];

    for (let i = 0, len = totalNextPages + 1; i < len; i++) {
      numbers.push(
        <PaginationItem
          key={`pagination-number-${actualPage + i}`}
          className={i === 0 ? 'active' : ''}
        >
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              onPageClick(page + i, value);
            }}
          >
            {actualPage + i}
          </PaginationLink>
        </PaginationItem>
      );
      if (i === 2) break;
    }

    return (
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={hasPrev ? '' : 'disabled'}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasPrev) {
                onPageClick(page - 1, value);
              }
            }}
            tabIndex="-1"
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>

        {numbers}

        <PaginationItem className={hasNext ? '' : 'disabled'}>
          <PaginationLink
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();

              if (hasNext) {
                onPageClick(page + 1, value);
              }
            }}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  };

  const renderItems = () => {
    let balance = 0;
    let reversedTransactions = [...filteredTransactions()].reverse();

    return reversedTransactions.map((transaction, tIndex) => {
      if (transaction.kind === TransactionKind.Invoice) {
        balance -= transaction.total / 100;
      } else {
        balance += transaction.total / 100;
      }

      return (
        <tr key={`ar-transaction-${tIndex}`}>
          <td>
            <a
              className="py-3"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onItemClick(transaction);
              }}
            >
              <b>{transaction.kind}</b>
            </a>
          </td>

          <td>
            <a
              className="py-3"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (
                  (transaction.invoiceNumber &&
                    transaction.invoiceNumber === filterInvoiceNumber) ||
                  (transaction.invoice &&
                    transaction.invoice.invoiceNumber === filterInvoiceNumber)
                ) {
                  setFilterInvoiceNumber('');
                } else {
                  setFilterInvoiceNumber(
                    transaction.invoiceNumber ||
                      transaction.invoice.invoiceNumber
                  );
                }
              }}
            >
              {transaction.invoiceNumber || transaction.invoice.invoiceNumber}
            </a>
          </td>
          <td>{moment(transaction.date).format('ll')}</td>
          <td>
            {transaction.kind === TransactionKind.Invoice
              ? displayAmount(transaction.total / 100)
              : null}
          </td>
          <td>
            {transaction.kind !== TransactionKind.Invoice
              ? displayAmount(transaction.total / 100)
              : null}
          </td>
          <td>{displayAmount(balance)}</td>
          <td style={{ paddingRight: '0', paddingLeft: '0' }}>
            {transaction.kind === TransactionKind.Invoice ? (
              <span className="calendar-list-item__actions">
                <button
                  id={`transactions-actions-payment-${transaction._id}`}
                  onClick={() => onNewPaymentClick(transaction)}
                >
                  <i className="fas fa-hand-holding-usd"></i>
                </button>
                <UncontrolledTooltip
                  delay={0}
                  target={`transactions-actions-payment-${transaction._id}`}
                >
                  Crear pago
                </UncontrolledTooltip>
                <button
                  id={`transactions-actions-credit-note-${transaction._id}`}
                  onClick={() => onNewCreditNoteClick(transaction)}
                >
                  <i className="fas fa-file-invoice-dollar"></i>
                </button>
                <UncontrolledTooltip
                  delay={0}
                  target={`transactions-actions-credit-note-${transaction._id}`}
                >
                  Crear nota de crédito
                </UncontrolledTooltip>
                <button
                  id={`transactions-actions-notify-invoice-${transaction._id}`}
                  onClick={() => {
                    const lastReminder =
                      transaction.invoiceNotifiedAt &&
                      transaction.invoiceNotifiedAt[0]
                        ? moment(
                            transaction.invoiceNotifiedAt[
                              transaction.invoiceNotifiedAt.length - 1
                            ]
                          ).fromNow()
                        : 'nunca';

                    if (
                      !window.confirm(
                        `Ultimo envio: ${lastReminder}\n\n¿Seguro que quieres enviar la factura al cliente?.`
                      )
                    ) {
                      return;
                    }

                    notifyCustomer(NotificationKind.Invoice, {
                      transactionId: transaction._id
                    });
                  }}
                >
                  <i className="fas fa-bell"></i>
                </button>
                <UncontrolledTooltip
                  delay={0}
                  target={`transactions-actions-notify-invoice-${transaction._id}`}
                >
                  Enviar factura
                </UncontrolledTooltip>
              </span>
            ) : null}

            {transaction.kind !== TransactionKind.Invoice ? (
              <span className="calendar-list-item__actions">
                <button
                  id={`transactions-actions-notify-payment-${transaction._id}`}
                  onClick={() => {
                    const lastReminder =
                      transaction.invoiceNotifiedAt &&
                      transaction.invoiceNotifiedAt[0]
                        ? moment(
                            transaction.invoiceNotifiedAt[
                              transaction.invoiceNotifiedAt.length - 1
                            ]
                          ).fromNow()
                        : 'nunca';

                    if (
                      !window.confirm(
                        `Ultimo envio: ${lastReminder}\n\n¿Seguro que quieres enviar el recibo al cliente?.`
                      )
                    ) {
                      return;
                    }

                    notifyCustomer(NotificationKind.PaymentReceipt, {
                      transactionId: transaction._id
                    });
                  }}
                >
                  <i className="fas fa-bell"></i>
                </button>
                <UncontrolledTooltip
                  delay={0}
                  target={`transactions-actions-notify-payment-${transaction._id}`}
                >
                  Enviar recibo de pago
                </UncontrolledTooltip>
              </span>
            ) : null}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Card className="mb-0">
        {filterInvoiceNumber ? (
          <div className="p-2">
            <a
              className="badge badge-primary badge-md"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setFilterInvoiceNumber('');
              }}
            >
              {filterInvoiceNumber} <i className="ml-1 fas fa-times"></i>
            </a>
          </div>
        ) : null}

        {transactions.length > 0 ? (
          <Table
            className="align-items-center table-flush table--records"
            responsive
            striped
          >
            <thead className="thead-light">
              <tr>
                <th>Entrada</th>
                <th>Documento</th>
                <th>Fecha</th>
                <th>Débito</th>
                <th>Crédito</th>
                <th>Balance</th>
                <th style={{ paddingRight: '0', paddingLeft: '0' }}></th>
              </tr>
            </thead>
            <tbody>
              {renderItems()}
              <tr>
                <td></td>
                <td></td>
                <td>{moment().format('ll')}</td>
                <td>
                  <b>{displayAmount(getTotalInvoiced())}</b>
                </td>
                <td>
                  <b>{displayAmount(getTotalPaid())}</b>
                </td>
                <td>
                  <strong>
                    {displayAmount(getTotalInvoiced() - getTotalPaid())}
                  </strong>
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <CardBody className="text-center">
            <p className="text-muted">Esta cuenta no tiene movimientos</p>
            <Button
              color="warning"
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                onNewClick();
              }}
            >
              Ingresar Factura
            </Button>
          </CardBody>
        )}
      </Card>

      <div className="my-3 d-flex justify-space-between">
        <small className="ml-auto">
          <a href={getPrintURL()} target="_blank">
            <i className="fas fa-print"></i> Reporte
          </a>
        </small>
      </div>
    </>
  );
}

export default AppARShowTransactions;
