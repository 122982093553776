import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button
} from 'reactstrap';

import serialAPI from 'services/api/serial';
import Header from 'components/Headers/AlternativeHeader';
import AppInput from 'components/Forms/AppInput';
import { getName } from 'utils';
import { displayAmount } from 'utils';
import { ProductCategory } from '../../constants';

function AppSerials({ productId }) {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [serials, setSerials] = useState([]);
  const [count, setCount] = useState(0);
  const [availableCount, setAvailableCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => fetchSerials(), []);

  const fetchSerials = async (pageToGo) => {
    setIsLoading(true);
    try {
      const result = await serialAPI.getAll(
        productId,
        pageToGo >= 0 ? pageToGo : page,
        itemsPerPage
      );
      setSerials(result.serials);
      setCount(result.count);
      setAvailableCount(result.availableCount);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      /* handle error */
      console.log(e);
    }
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSave = async () => {
    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    if (!form.value) {
      newErrors.value = 'Es obligatorio';
    }

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }
    try {
      await serialAPI.create(productId, form);
      await fetchSerials(0);
      setPage(0);
      setIsSending(false);
      setForm({})
    } catch (e) {
      setIsSending(false);
      return Promise.reject(e);
    }
  };

  // const handleDelete = async (id) => {
  //   if (!window.confirm('¿Seguro quieres eliminar este producto?')) return;
  //   if (!window.confirm('¡Después de este punto, no hay vuelta atrás!')) return;
  //   try {
  //     await productAPI.deleteOne(id);
  //     fetchProducts(0);
  //     setSerialsPage(0);
  //   } catch (e) {
  //     /* handle error */
  //     console.log(e);
  //   }
  // };

  // const renderPagination = () => {
  //   const actualPage = page + 1;
  //   const totalPages =
  //     count > 0 && itemsPerPage > 0 ? Math.ceil(count / itemsPerPage) : 0;
  //   if (totalPages === 0) return null;

  //   const hasPrev = page > 0;
  //   const hasNext = actualPage < totalPages;
  //   const totalNextPages = totalPages - actualPage;
  //   let numbers = [];

  //   for (let i = 0, len = totalNextPages + 1; i < len; i++) {
  //     numbers.push(
  //       <PaginationItem
  //         key={`pagination-number-${actualPage + i}`}
  //         className={i === 0 ? 'active' : ''}
  //       >
  //         <PaginationLink
  //           href="#pablo"
  //           onClick={(e) => {
  //             e.preventDefault();
  //             onPageClick(page + i, value, category);
  //           }}
  //         >
  //           {actualPage + i}
  //         </PaginationLink>
  //       </PaginationItem>
  //     );
  //     if (i === 2) break;
  //   }

  //   return (
  //     <Pagination
  //       className="pagination justify-content-end mb-0"
  //       listClassName="justify-content-end mb-0"
  //     >
  //       <PaginationItem className={hasPrev ? '' : 'disabled'}>
  //         <PaginationLink
  //           href="#pablo"
  //           onClick={(e) => {
  //             e.preventDefault();

  //             if (hasPrev) {
  //               onPageClick(page - 1, value);
  //             }
  //           }}
  //           tabIndex="-1"
  //         >
  //           <i className="fas fa-angle-left" />
  //           <span className="sr-only">Previous</span>
  //         </PaginationLink>
  //       </PaginationItem>
  //       {numbers}
  //       <PaginationItem className={hasNext ? '' : 'disabled'}>
  //         <PaginationLink
  //           href="#pablo"
  //           onClick={(e) => {
  //             e.preventDefault();

  //             if (hasNext) {
  //               onPageClick(page + 1, value);
  //             }
  //           }}
  //         >
  //           <i className="fas fa-angle-right" />
  //           <span className="sr-only">Next</span>
  //         </PaginationLink>
  //       </PaginationItem>
  //     </Pagination>
  //   );
  // };

  const renderSerials = () => {
    return serials.map((s) => (
      <div key={s._id} className="serial">
        <div className="serial-name">{s.value}</div>

        {/* <div className="serial-action"> */}
        {/*   <Button color="gray" size="sm" className="d-block ml-auto"> */}
        {/*     <i className="fas fa-trash"></i> */}
        {/*   </Button> */}
        {/* </div> */}
      </div>
    ));
  };

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          marginTop: '30px',
          marginBottom: '30px'
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <Spinner />
      </div>
    );
  }

  return (
    <Card className="card-profile">
      <CardHeader className="d-flex">
        <h3 className="mb-0">Seriales</h3>

        <span className="ml-auto">
          Disp. {availableCount} de {count}
        </span>
      </CardHeader>

      <CardBody className="serials">{renderSerials()}</CardBody>

      <CardFooter className="serials-form">
        <AppInput
          id="value"
          name="value"
          type="text"
          placeholder="Nuevo serial"
          value={form.value || ''}
          error={errors.value}
          required
          onChange={(e) => handleChange(e)}
          onBlur={(e) => handleChange(e)}
        />

        {/* <Button color="secondary" size="sm"> */}
        {/*   <i className="fas fa-upload"></i> */}
        {/* </Button> */}

        <Button color="primary" size="sm" onClick={handleSave}>
          <i className="fas fa-save"></i>
        </Button>
      </CardFooter>
    </Card>
  );
}

export default AppSerials;
