import React, { useState } from 'react';
import moment from 'moment';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from 'reactstrap';

import AlternativeHeader from 'components/Headers/AlternativeHeader';
import AppInput from 'components/Forms/AppInput';
import AppCheckbox from 'components/Forms/AppCheckbox';
import { getName, useForm, validate } from 'utils';
import { CustomerKind } from '../../constants';

function AppCustomerForm({ customer, onSave, onClose, onViewClick }) {
  const requiredValues = [];
  const [isSending, setIsSending] = useState(false);
  const [_enabledButton, setEnabledButton] = useState(false);
  const [notifications, setNotifications] = useState(customer.notifications);

  const submitHandler = async (v) => {
    if (isSending) return;
    setIsSending(true);
    let form = {};

    try {
      Object.entries(dirtyInputs).forEach(([key, value]) => {
        if (value) {
          form[key] = v[key];
        }
      });

      if (customer.phones.length !== values.phones.length) {
        form.phones = values.phones;
      }

      await onSave(form);
    } catch (e) {
      setIsSending(false);
    }
  };

  const notificationsSubmitHandler = async (_v) => {
    if (isSending) return;
    setIsSending(true);
    let form = {};

    try {
      Object.entries(notifications).forEach(([key, value]) => {
        if (notifications[key] !== customer.notifications[key]) {
          form[`notifications.${key}`] = value;
        }
      });

      await onSave(form);
      setIsSending(false);
    } catch (e) {
      setIsSending(false);
    }
  };

  const {
    values,
    dirtyInputs,
    errors,
    handleBlur,
    handleChange,
    handleSubmit
  } = useForm(submitHandler, requiredValues, customer, {}, {});

  const setPhoneValue = (oldValue, newValue) => {
    let tmpValue = newValue || '';

    tmpValue = tmpValue.replace(/[^0-9]/g, '');
    if (tmpValue.length > 15) {
      tmpValue = oldValue;
    }

    return tmpValue;
  };

  const renderPhones = () => {
    if (values.phones.length === 0)
      return (
        <div className="text-center text-muted text-sm mt-4 mb-4 p-3 rounded bg-gradient-secondary">
          No tiene más números de contacto
        </div>
      );

    return values.phones.map((p, pIndex) => (
      <Row key={`Teléfono-${pIndex + 2}`} className="mt-3">
        <Col xs="5">
          <AppInput
            label={`Teléfono ${pIndex + 2}`}
            id={`phones-phone-${pIndex + 2}`}
            name="phones"
            type="text"
            value={p.phone || ''}
            onChange={(e) =>
              handleChange(
                {
                  persist: () => {},
                  target: {
                    name: 'phones',
                    value: values.phones.map((vP, vPIndex) => {
                      if (vPIndex === pIndex) {
                        return {
                          label: vP.label,
                          phone: setPhoneValue(vP.phone, e.target.value)
                        };
                      }
                      return vP;
                    })
                  }
                },
                (currentValues) =>
                  setEnabledButton(
                    JSON.stringify(validate(currentValues, requiredValues)) ===
                      '{}'
                  )
              )
            }
            onBlur={(e) =>
              handleBlur(
                {
                  persist: () => {},
                  target: {
                    name: 'phones',
                    value: values.phones.map((vP, vPIndex) => {
                      if (vPIndex === pIndex) {
                        return {
                          label: vP.label,
                          phone: setPhoneValue(vP.phone, e.target.value)
                        };
                      }
                      return vP;
                    })
                  }
                },
                () =>
                  setEnabledButton(
                    JSON.stringify(validate(values, requiredValues)) === '{}'
                  )
              )
            }
          />
        </Col>
        <Col xs="5">
          <AppInput
            label="Referencia"
            id={`phones-reference-${pIndex + 2}`}
            name="phones"
            type="text"
            value={p.label || ''}
            required
            onChange={(e) =>
              handleChange(
                {
                  persist: () => {},
                  target: {
                    name: 'phones',
                    value: values.phones.map((vP, vPIndex) => {
                      if (vPIndex === pIndex) {
                        return {
                          label: e.target.value,
                          phone: vP.phone
                        };
                      }
                      return vP;
                    })
                  }
                },
                (currentValues) =>
                  setEnabledButton(
                    JSON.stringify(validate(currentValues, requiredValues)) ===
                      '{}'
                  )
              )
            }
            onBlur={(e) =>
              handleBlur(
                {
                  persist: () => {},
                  target: {
                    name: 'phones',
                    value: values.phones.map((vP, vPIndex) => {
                      if (vPIndex === pIndex) {
                        return {
                          label: e.target.value,
                          phone: vP.phone
                        };
                      }
                      return vP;
                    })
                  }
                },
                () =>
                  setEnabledButton(
                    JSON.stringify(validate(values, requiredValues)) === '{}'
                  )
              )
            }
          />
        </Col>
        <Col xs="1" className="d-flex align-items-center">
          <a
            className="table-action table-action-delete"
            href="#"
            id="tooltip12475021"
            onClick={(e) => {
              e.preventDefault();
              if (
                !window.confirm(
                  `Seguro que quieres eliminar el numero ${p.label}`
                )
              )
                return;

              handleChange({
                persist: () => {},
                target: {
                  name: 'phones',
                  value: values.phones.filter(
                    (_vP, vPIndex) => vPIndex !== pIndex
                  )
                }
              });
              // onDelete(aR._id);
            }}
          >
            <i className="fas fa-trash" />
          </a>
          <UncontrolledTooltip delay={0} target="tooltip12475021">
            Eliminar numero {p.label}
          </UncontrolledTooltip>
        </Col>
      </Row>
    ));
  };

  return (
    <>
      <AlternativeHeader
        name={getName(values)}
        parentName="Clientes"
        onBackClick={onClose}
        onNewLabel="Visualizar"
        onNewClick={customer._id ? () => onViewClick(customer._id, true) : null}
      />

      <Container fluid className="mt--6">
        <Row>
          <Col className="order-lg-2" lg="4">
            <Card className="card-profile">
              <CardBody>
                <div className="text-center">
                  {values.alias ? (
                    <h5 className="text-gray">{values.alias}</h5>
                  ) : null}

                  <h5 className="h3">
                    {getName(values)}
                    <span className="font-weight-light">
                      {values.birthDay
                        ? `, ${moment().diff(values.birthDay, 'years')}`
                        : null}
                    </span>
                  </h5>
                </div>

                <h5>Contacto</h5>
                <ul className="customer__info-list">
                  <li>
                    <i className="fas fa-envelope"></i>
                    {values.email ? (
                      <a href={`mailto:${values.email}`}>{values.email}</a>
                    ) : (
                      '-'
                    )}
                  </li>
                  {values.phone || values.phoneAlias ? (
                    <li>
                      <i className="fas fa-phone"></i>
                      <a href={`tel:${values.phone}`}>{values.phone}</a>
                      <span className="ml-1">- {values.phoneAlias}</span>
                    </li>
                  ) : (
                    <li>
                      <i className="fas fa-phone"></i> -
                    </li>
                  )}
                  {values.phones.map((p, pIndex) => (
                    <li key={`phones-item-${pIndex}`}>
                      <i className="fas fa-phone"></i>
                      {p.phone ? <a href={`tel:${p.phone}`}>{p.phone}</a> : ''}
                      <span className="ml-1">- {p.label}</span>
                    </li>
                  ))}
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                    {values.address || '-'}
                  </li>
                </ul>

                <h5>Información</h5>
                <ul className="customer__info-list">
                  <li>
                    <i className="fas fa-user-tag"></i>
                    {values.kind || '-'}
                  </li>
                  <li>
                    <i className="fas fa-id-card"></i>
                    {values.identification || '-'}
                  </li>
                  {values.kind === CustomerKind.Company ? (
                    <li>
                      <i className="fas fa-landmark"></i>
                      {values.rnc || '-'}
                    </li>
                  ) : null}
                </ul>

                {values.position ? (
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Solution Manager - Creative Tim Officer
                  </div>
                ) : null}

                {values.company ? (
                  <div>
                    <i className="ni education_hat mr-2" />
                    University of Computer Science
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </Col>
          <Col className="order-lg-1" lg="8">
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  {customer._id ? 'Editar' : 'Crear'} cliente
                </h3>
              </CardHeader>

              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-3">Información</h6>
                  {values.kind === CustomerKind.Company ? (
                    <Row>
                      <Col sm="6">
                        <AppInput
                          label="Nombre"
                          id="name"
                          name="name"
                          type="text"
                          value={values.name || ''}
                          error={errors.name}
                          required
                          onChange={(e) =>
                            handleChange(e, (currentValues) =>
                              setEnabledButton(
                                JSON.stringify(
                                  validate(currentValues, requiredValues)
                                ) === '{}'
                              )
                            )
                          }
                          onBlur={(e) =>
                            handleBlur(e, () =>
                              setEnabledButton(
                                JSON.stringify(
                                  validate(values, requiredValues)
                                ) === '{}'
                              )
                            )
                          }
                        />
                      </Col>

                      <Col sm="6">
                        <AppInput
                          label="RNC"
                          id="rnc"
                          name="rnc"
                          type="text"
                          value={values.rnc || ''}
                          error={errors.rnc}
                          required
                          onChange={(e) =>
                            handleChange(e, (currentValues) =>
                              setEnabledButton(
                                JSON.stringify(
                                  validate(currentValues, requiredValues)
                                ) === '{}'
                              )
                            )
                          }
                          onBlur={(e) =>
                            handleBlur(e, () =>
                              setEnabledButton(
                                JSON.stringify(
                                  validate(values, requiredValues)
                                ) === '{}'
                              )
                            )
                          }
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col lg="6">
                        <AppInput
                          label="Nombre"
                          id="firstName"
                          name="firstName"
                          type="text"
                          value={values.firstName || ''}
                          error={errors.firstName}
                          required
                          onChange={(e) =>
                            handleChange(e, (currentValues) =>
                              setEnabledButton(
                                JSON.stringify(
                                  validate(currentValues, requiredValues)
                                ) === '{}'
                              )
                            )
                          }
                          onBlur={(e) =>
                            handleBlur(e, () =>
                              setEnabledButton(
                                JSON.stringify(
                                  validate(values, requiredValues)
                                ) === '{}'
                              )
                            )
                          }
                        />
                      </Col>
                      <Col lg="6">
                        <AppInput
                          label="Apellido"
                          id="lastName"
                          name="lastName"
                          type="text"
                          value={values.lastName || ''}
                          error={errors.lastName}
                          onChange={(e) =>
                            handleChange(e, (currentValues) =>
                              setEnabledButton(
                                JSON.stringify(
                                  validate(currentValues, requiredValues)
                                ) === '{}'
                              )
                            )
                          }
                          onBlur={(e) =>
                            handleBlur(e, () =>
                              setEnabledButton(
                                JSON.stringify(
                                  validate(values, requiredValues)
                                ) === '{}'
                              )
                            )
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col lg="6">
                      <AppInput
                        label="Correo Eléctronico"
                        id="email"
                        name="email"
                        type="email"
                        value={values.email || ''}
                        error={errors.email}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <AppInput
                        label="Tipo"
                        id="kind"
                        name="kind"
                        type="select"
                        value={values.kind}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      >
                        {Object.entries(CustomerKind).map(([_k, v]) => (
                          <option
                            key={`kind-${v}`}
                            value={v}
                            defaultChecked={values.kind === v}
                          >
                            {v}
                          </option>
                        ))}
                      </AppInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <AppInput
                        label="Cédula"
                        id="identification"
                        name="identification"
                        type="text"
                        value={values.identification || ''}
                        error={errors.identification}
                        required
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <AppInput
                        label={
                          <span>
                            Alias
                            <i
                              id="input-alias-help-description"
                              className="fas fa-question-circle ml-2"
                            ></i>
                            <UncontrolledTooltip
                              color="secondary"
                              delay={0}
                              target="input-alias-help-description"
                            >
                              Nombre alternativo por el cual se conoce el
                              cliente.
                            </UncontrolledTooltip>
                          </span>
                        }
                        id="alias"
                        name="alias"
                        type="text"
                        value={values.alias || ''}
                        error={errors.alias}
                        required
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>

                    <Col lg="6">
                      <AppInput
                        label={
                          <span>
                            Responsable de Cuenta
                            <i
                              id="input-responsible-help-description"
                              className="fas fa-question-circle ml-2"
                            ></i>
                            <UncontrolledTooltip
                              color="secondary"
                              delay={0}
                              target="input-responsible-help-description"
                            >
                              Útil para filtrar múltiples cuentas pertenecientes
                              al mismo cliente.
                            </UncontrolledTooltip>
                          </span>
                        }
                        id="responsible"
                        name="responsible"
                        type="text"
                        value={values.responsible || ''}
                        error={errors.responsible}
                        required
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                  </Row>

                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-3">Contacto</h6>

                  <Row>
                    <Col md="12">
                      <AppInput
                        label="Dirección"
                        id="address"
                        name="address"
                        type="text"
                        value={values.address || ''}
                        error={errors.address}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="heading-small text-muted">Teléfonos</h6>
                    <Button
                      onClick={() => {
                        handleChange({
                          persist: () => {},
                          target: {
                            name: 'phones',
                            value: [
                              ...values.phones,
                              {
                                label: '',
                                phone: ''
                              }
                            ]
                          }
                        });
                      }}
                      color="default"
                      size="sm"
                      outline
                    >
                      Agregar número
                    </Button>
                  </div>

                  <Row>
                    <Col lg="5">
                      <AppInput
                        label="Teléfono Primario"
                        id="phone"
                        name="phone"
                        type="text"
                        value={values.phone || ''}
                        error={errors.phone}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>

                    <Col lg="5">
                      <AppInput
                        label="Referencia"
                        id="phoneAlias"
                        name="phoneAlias"
                        type="text"
                        value={values.phoneAlias || ''}
                        error={errors.phoneAlias}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                  </Row>

                  {renderPhones()}

                  <Button color="primary" type="button" size="sm" onClick={handleSubmit}>
                    Guardar
                  </Button>
                </Form>
              </CardBody>
            </Card>

            {customer._id ? (
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Notificaciones</h3>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <h6 className="heading-small text-muted mb-3">
                    Cuenta por cobrar
                  </h6>

                  <Row>
                    <Col md="4">
                      <AppCheckbox
                        label="Pago vencido"
                        id="accountReceivable"
                        name="accountReceivable"
                        checked={notifications.aRExpiredMilestone}
                        onChange={() => {
                          setNotifications({
                            ...notifications,
                            aRExpiredMilestone:
                              !notifications.aRExpiredMilestone
                          });
                        }}
                      ></AppCheckbox>
                    </Col>

                    <Col md="4">
                      <AppCheckbox
                        label="Pago próximo a vencer"
                        id="accountReceivable"
                        name="accountReceivable"
                        checked={notifications.aRMilestoneAboutToExpire}
                        onChange={() => {
                          setNotifications({
                            ...notifications,
                            aRMilestoneAboutToExpire:
                              !notifications.aRMilestoneAboutToExpire
                          });
                        }}
                      ></AppCheckbox>
                    </Col>
                  </Row>

                  <Button
                    color="primary"
                    type="button"
                    size="sm"
                    onClick={notificationsSubmitHandler}
                  >
                    Guardar
                  </Button>
                </CardBody>
              </Card>
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AppCustomerForm;
