import axios, { requestToken } from '../../utils/axios';
import toast from 'react-hot-toast';

const getAll = async (page, itemsPerPage, value) => {
  try {
    const response = await axios.get(
      `/api/files?search=${JSON.stringify({ page, itemsPerPage, value })}`,
      {
        cancelToken: requestToken()
      }
    );
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const create = async (payload) => {
  try {
    const response = await axios.post(`/api/files`, payload);
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const deleteOne = async (id) => {
  try {
    const response = await axios.delete(`/api/files/${id}`);
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const deleteOneByKey = async (key) => {
  try {
    const response = await axios.post('/api/files/delete-by-key', {
      key
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const signS3Upload = async (filename, filetype) => {
  try {
    const response = await axios.put('/api/files/sign-upload', {
      filename,
      filetype
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const signS3Download = async (key) => {
  try {
    const response = await axios.put('/api/files/sign-download', {
      key
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const uploadToS3 = async (file, signedRequest) => {
  try {
    await axios.put(signedRequest, file, {
      headers: {
        'Content-Type': file.type
      }
    });
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

export default {
  create,
  getAll,
  deleteOne,
  deleteOneByKey,
  signS3Upload,
  signS3Download,
  uploadToS3
};
