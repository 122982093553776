import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
  UncontrolledTooltip
} from 'reactstrap';

import { displayAmount, moment } from 'utils';
import AccountsReceivableAPI from 'services/api/accountReceivable';
import AppCurrencyInput from 'components/Forms/AppCurrencyInput';
import AppInput from 'components/Forms/AppInput';

function AppARMilestones({
  accountReceivableId,
  invoice,
  transactions,
  onSuccess
}) {
  const [settingScheduledFor, setSettingScheduledFor] = useState(null);

  const [milestones, setMilestones] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);

  const [allocateAmount, setAllocateAmount] = useState(false);
  const [repeatEvery, setRepeatEvery] = useState('months');
  const [repeatingTimes, setRepeatingTimes] = useState(1);
  const [repeatQty, setRepeatQty] = useState(1);

  useEffect(() => {
    if (invoice.milestones) setMilestones([...invoice.milestones]);
  }, [invoice]);

  const handleChange = (index, name, value) => {
    let newValue = value;
    if (name === 'date' && value.length > 0) newValue = moment(newValue);

    setMilestones(
      milestones.map((m, mIndex) => {
        if (mIndex === index)
          return {
            ...m,
            [name]: newValue
          };
        return m;
      })
    );
  };

  const handleSubmit = async () => {
    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    if (milestones.find((m) => !m.date || !m.amount)) {
      alert('Todos los hitos deben tener una fecha y monto.');
      setIsSending(false);
      return;
    }

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    try {
      await AccountsReceivableAPI.updateTransaction(
        accountReceivableId,
        invoice._id,
        {
          milestones,
          isUpdatingMilestones: true
        }
      );

      setIsSending(false);
      onSuccess();
    } catch (e) {
      setIsSending(false);
    }
  };

  const handleDelete = (index) => {
    setMilestones(milestones.filter((m, mIndex) => mIndex !== index));
  };

  const getPendingAmount = () => {
    let amount = 0;

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (
        transactions[i].invoice &&
        transactions[i].invoice._id === invoice._id
      ) {
        amount += transactions[i].total;
      }
    }

    return invoice.total - amount;
  };

  const getAllocatedAmount = () => {
    if (!allocateAmount)
      return parseFloat(settingScheduledFor.amount.replace(/[^0-9.]/g, ''));
    return getPendingAmount() / repeatQty / 100;
  };

  const renderMilestones = () => {
    if (milestones.length === 0)
      return (
        <div className="text-center">
          <p className="p-0 my-3">Esta factura no tiene hitos</p>
        </div>
      );

    return (
      <Table className="form-table align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th>Fecha de pago</th>
            <th>Monto</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {milestones.map((m, mIndex) => (
            <tr key={`milestone-form-item-${mIndex}`}>
              <td>
                <AppInput
                  style={{ width: '128px' }}
                  isTableInput
                  id="date"
                  name="date"
                  type="date"
                  value={moment(m.date).format('YYYY-MM-DD')}
                  error={errors[mIndex] ? errors[mIndex].date : false}
                  onChange={(e) =>
                    handleChange(mIndex, e.target.name, e.target.value)
                  }
                />
              </td>
              <td>
                <AppCurrencyInput
                  isTableInput
                  id="amount"
                  name="amount"
                  step={1}
                  decimalScale={2}
                  value={m.amount}
                  error={errors[mIndex] ? errors[mIndex].amount : false}
                  onValueChange={(value, name) =>
                    handleChange(mIndex, name, value)
                  }
                />
              </td>
              <td
                style={{ width: '100px', textAlign: 'center' }}
                className="table-actions"
              >
                {!m.completed ? (
                  <a
                    className="table-action"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChange(mIndex, 'completed', true);
                    }}
                  >
                    <i className="fas fa-check-circle"></i>
                  </a>
                ) : (
                  <a
                    className="table-action text-success"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDelete(mIndex);
                      handleChange(mIndex, 'completed', false);
                    }}
                  >
                    <i className="fas fa-check-circle"></i>
                  </a>
                )}

                <a
                  className="table-action"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if ((!m.date || m.date.length === 0) && !m.amount) {
                      alert('Debes indicar un monto y fecha primero');
                      return;
                    }
                    setSettingScheduledFor({
                      ...m,
                      amount:
                        typeof m.amount === 'string'
                          ? m.amount
                          : m.amount.toFixed(2)
                    });
                  }}
                >
                  <i className="fas fa-retweet"></i>
                </a>

                <a
                  className="table-action table-action-delete"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      !window.confirm('¿Seguro que quieres eliminar este hito?')
                    )
                      return;
                    handleDelete(mIndex);
                  }}
                >
                  <i className="fas fa-trash" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const getNewTransactions = () => {
    if (!settingScheduledFor) return [];

    const newTransactions = [];
    const initDate = moment(settingScheduledFor.date).startOf('day');

    for (let i = 0, len = repeatQty; i < len; i++) {
      if (repeatEvery === 'months') {
        newTransactions.push({
          date: initDate.add(repeatingTimes, 'months').startOf('day').format(),
          amount: getAllocatedAmount(),
          completed: false
        });
      } else if (repeatEvery === 'days') {
        newTransactions.push({
          date: initDate.add(repeatingTimes, 'days').startOf('day').format(),
          amount: getAllocatedAmount(),
          completed: false
        });
      } else if (repeatEvery === 'years') {
        newTransactions.push({
          date: initDate.add(repeatingTimes, 'years').startOf('day').format(),
          amount: getAllocatedAmount(),
          completed: false
        });
      }
    }

    return newTransactions;
  };

  const renderMonthlyOptions = () => {
    const options = [];
    let times = 13;

    if (repeatEvery === 'days') {
      times = 32;
    }

    if (repeatEvery === 'years') {
      times = 11;
    }

    for (let i = 1, len = times; i < len; i++) {
      options.push(
        <option
          key={`milestone-scheduled-transaction-month-option-${i}`}
          value={i}
        >
          {i}
        </option>
      );
    }

    return options;
  };

  const renderQtyOptions = () => {
    const options = [];

    for (let i = 1, len = 301; i < len; i++) {
      options.push(
        <option
          key={`milestone-scheduled-transaction-qty-option-${i}`}
          value={i}
        >
          {i}
        </option>
      );
    }

    return options;
  };

  const renderView = () => {
    if (!invoice) return null;

    return (
      <>
        {settingScheduledFor ? (
          <Modal
            fade={false}
            isOpen={!!settingScheduledFor}
            size="md"
            scrollable
            className="modal-dialog-centered modal-secondary"
          >
            <ModalBody className="pb-0">
              <Card className="mb-0">
                <CardBody className="">
                  <div className="d-flex align-items-center">
                    <input
                      id="allocate-amount"
                      type="checkbox"
                      value={allocateAmount}
                      onChange={(e) => setAllocateAmount(!allocateAmount)}
                    />
                    <label className="mt-2 ml-2" htmlFor="allocate-amount">
                      Utilizar el balance pendiente
                    </label>
                  </div>
                  <div className="app-scheduler-form d-flex align-items-center">
                    <span>Repetir cada</span>
                    <AppInput
                      style={{
                        width: '80px',
                        marginLeft: '8px',
                        marginRight: '8px'
                      }}
                      isTableInput
                      type="select"
                      value={repeatingTimes}
                      onChange={(e) => setRepeatingTimes(e.target.value)}
                    >
                      {renderMonthlyOptions()}
                    </AppInput>
                    <AppInput
                      style={{ width: '80px', marginRight: '8px' }}
                      isTableInput
                      type="select"
                      value={repeatEvery}
                      onChange={(e) => setRepeatEvery(e.target.value)}
                    >
                      <option value="days">Días</option>
                      <option value="months">Meses</option>
                      <option value="years">años</option>
                    </AppInput>
                    <span>por</span>
                    <AppInput
                      style={{ width: '80px', marginLeft: '8px' }}
                      isTableInput
                      type="select"
                      value={repeatQty}
                      onChange={(e) => setRepeatQty(e.target.value)}
                    >
                      {renderQtyOptions()}
                    </AppInput>
                  </div>
                  <h5 className="mt-3">
                    El cliente pagara {displayAmount(getAllocatedAmount())} en
                    las fechas
                  </h5>
                  <ul>
                    {getNewTransactions()
                      .slice(0, 3)
                      .map((t, tIndex) => (
                        <li
                          key={`milestone-scheduled-transaction-examples-${tIndex}`}
                        >
                          {moment(t.date).format('LL')}
                        </li>
                      ))}
                  </ul>
                  {getNewTransactions().length > 3 ? (
                    <h5 className="mt-3">
                      Finalizando el{' '}
                      {moment(
                        getNewTransactions()[getNewTransactions().length - 1]
                          .date
                      ).format('LL')}
                    </h5>
                  ) : null}
                </CardBody>
              </Card>
            </ModalBody>

            <ModalFooter>
              <Button
                className="new-event--add"
                color="primary"
                size="sm"
                type="button"
                onClick={() => {
                  setMilestones([...milestones, ...getNewTransactions()]);
                  setSettingScheduledFor(null);
                }}
              >
                Generar
              </Button>

              <Button
                className="ml-auto"
                color="link"
                type="button"
                onClick={() => {
                  setRepeatEvery('months');
                  setSettingScheduledFor(null);
                }}
              >
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}

        <Card className="mb-0">
          <CardHeader>
            <h5 className="mb-0">
              Hitos{' '}
              <i
                id="milestones-help-description"
                className="fas fa-question-circle"
              ></i>
            </h5>
            <UncontrolledTooltip
              color="secondary"
              delay={0}
              target="milestones-help-description"
            >
              El cliente debería pagar en estas fechas.
            </UncontrolledTooltip>
          </CardHeader>

          <CardBody className="p-0 milestones-form-table">
            {renderMilestones()}

            <div className="py-2 text-center w-100">
              <Button
                onClick={() =>
                  setMilestones([
                    ...milestones,
                    { date: new Date().toISOString().slice(0, -1), amount: 0 }
                  ])
                }
                color="transparent"
                size="sm"
                className="text-center"
              >
                <i style={{ fontSize: '14px' }} className="fas fa-plus"></i>
              </Button>
            </div>
          </CardBody>
          <CardFooter>
            {isSending ? (
              <Button
                className="new-event--add"
                color="primary"
                size="sm"
                type="button"
              >
                <Spinner size="sm" />
              </Button>
            ) : (
              <Button
                className="new-event--add"
                color="primary"
                size="sm"
                type="button"
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            )}
            {milestones.length > 0 ? (
              <Button
                className="new-event--add"
                color=""
                size="sm"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    !window.confirm(
                      '¿Seguro que quieres eliminar todos los hitos?'
                    )
                  )
                    return;
                  setMilestones([]);
                }}
              >
                Limpiar
              </Button>
            ) : null}
          </CardFooter>
        </Card>
      </>
    );
  };

  return renderView();
}

export default AppARMilestones;
