import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from 'reactstrap';

import projectAPI from 'services/api/project';
import AppInput from 'components/Forms/AppInput';
import { Representatives } from '../../constants';

function AppProjectCreateForm({ show, customerId, onClose, onSuccess }) {
  const [form, setForm] = useState({ name: '' });
  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async () => {
    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    if (!form.name) {
      newErrors.name = 'Debes definir el nombre del proyecto';
    }

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    try {
      const results = await projectAPI.create({
        ...form,
        customer: customerId
      });
      setIsSending(false);
      setForm({ name: '' });
      onSuccess(results.id);
    } catch (e) {
      setIsSending(false);
    }
  };

  return (
    <Modal
      fade={false}
      isOpen={show}
      size="md"
      className="modal-dialog-centered modal-primary"
    >
      <ModalHeader>Nuevo proyecto</ModalHeader>

      <ModalBody className="py-0">
        <Card className="mb-0">
          <CardBody className="mb-0">
            <AppInput
              label="¿Cómo le llamarías a este proyecto?"
              id="name"
              name="name"
              type="name"
              placeholder="Ej. Casa, oficina, supermercado..."
              value={form.name}
              error={errors.name}
              onChange={handleChange}
            />

            <AppInput
              label="Representante"
              id="representative"
              name="representative"
              type="select"
              value={form.representative}
              error={errors.representative}
              onChange={handleChange}
            >
              <option value="">Representante del proyecto</option>
              {Object.values(Representatives).map((rep, repIndex) => (
                <option key={`project-representatives-${repIndex}`} value={rep}>
                  {rep}
                </option>
              ))}
            </AppInput>

            <AppInput
              label="Número de contrato (Opcional)"
              id="energySupplierContract"
              name="energySupplierContract"
              type="energySupplierContract"
              placeholder=""
              value={form.energySupplierContract}
              error={errors.energySupplierContract}
              onChange={handleChange}
            />

            <AppInput
              label="Número de medidor (Opcional)"
              id="energySupplierMeterNumber"
              name="energySupplierMeterNumber"
              type="energySupplierMeterNumber"
              placeholder=""
              value={form.energySupplierMeterNumber}
              error={errors.energySupplierMeterNumber}
              onChange={handleChange}
            />

            <AppInput
              label="Ubicación Nave (URL)"
              id="locationUrl"
              name="locationUrl"
              type="text"
              value={form.locationUrl || ''}
              error={errors.locationUrl}
              onChange={handleChange}
            />

            <AppInput
              label="Ubicación Poste (URL)"
              id="posteLocationUrl"
              name="posteLocationUrl"
              type="text"
              value={form.posteLocationUrl || ''}
              error={errors.posteLocationUrl}
              onChange={handleChange}
            />

            <AppInput
              label="Drone Deploy (URL)"
              id="droneDeployUrl"
              name="droneDeployUrl"
              type="text"
              value={form.droneDeployUrl || ''}
              error={errors.droneDeployUrl}
              onChange={handleChange}
            />
          </CardBody>
        </Card>
      </ModalBody>

      <ModalFooter>
        <Button
          className="ml-auto text-white"
          color="transparent"
          size="sm"
          type="button"
          onClick={() => {
            setForm({ name: '' });
            setErrors({});
            onClose();
          }}
        >
          Cancelar
        </Button>

        {isSending ? (
          <Button className="ml-1" color="secondary" size="sm" type="button">
            <Spinner size="sm" />
          </Button>
        ) : (
          <Button
            className="ml-1"
            color="secondary"
            size="sm"
            type="button"
            onClick={handleSubmit}
          >
            Iniciar
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default AppProjectCreateForm;
