import React from 'react';

const AppCheckbox = (props) => {
  const { checked, label, noMargin, ...remainingProps } = props;
  return (
    <label
      className={noMargin ? 'app-checkbox app-checkbox--no-margin' : 'app-checkbox'}
    >
      <input hidden type="checkbox" {...remainingProps} />
      <div className="app-checkbox__check">
        {checked && <i className="fas fa-check"></i>}
      </div>
      <span>{label}</span>
    </label>
  );
};

export default AppCheckbox;
