import axios, { requestToken } from '../../utils/axios';
import toast from 'react-hot-toast';

const getAll = async () => {
  try {
    const response = await axios.get('api/notifications', {
      cancelToken: requestToken()
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const create = async (payload) => {
  try {
    const response = await axios.post('/api/notifications', payload);
    toast.success(response.data.message, {
      style: {
        animation: 'none'
      }
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const markAsRead = async (id) => {
  try {
    await axios.patch(`/api/notifications/${id}/read`);
  } catch (e) {
    if (e && e.response && e.response.data) {
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const markAsCompleted = async (id) => {
  try {
    await axios.patch(`/api/notifications/${id}/completed`);
  } catch (e) {
    if (e && e.response && e.response.data) {
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

export default {
  getAll,
  create,
  markAsRead,
  markAsCompleted
};
