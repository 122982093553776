import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';

import { useAuth } from 'hooks/use-auth';
import { getFileObject, displayAmount, moment } from 'utils';

function AppARPayment({
  accountReceivableId,
  payment,
  isActive,
  onClose,
  onDeleteClick,
  onEditClick
}) {
  const { isAdmin } = useAuth();
  const [displayMoreFields, setDisplayMoreFields] = useState(false);

  const renderView = () => {
    if (!accountReceivableId) return null;
    if (!payment) return null;

    return (
      <Modal
        fade={false}
        isOpen={isActive}
        size="md"
        className="modal-dialog-centered modal-secondary"
      >
        <div className="d-flex justify-space-between align-items-center">
          <ModalHeader className="mr-auto">Pago</ModalHeader>
          {isAdmin() ? (
            <div className="ml-auto">
              <Button
                className="mr-1"
                size="sm"
                color="white"
                onClick={() => {
                  if (
                    window.confirm(
                      '¿Estas seguro que quieres eliminar este cobro?'
                    )
                  ) {
                    if (window.confirm('¿Seguro?, no hay vuelta atras')) {
                      onDeleteClick(payment._id);
                    }
                  }
                }}
              >
                Eliminar
              </Button>
              <Button
                className="mr-4"
                size="sm"
                color="default"
                onClick={() => onEditClick(payment)}
              >
                Editar
              </Button>
            </div>
          ) : null}
        </div>

        <ModalBody className="py-0">
          <Row>
            <Col lg="12">
              <Card className="mb-0">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <a
                      className="badge badge-default badge-lg"
                      href={getFileObject(payment.fileVoucher)}
                      target="_blank"
                    >
                      Voucher
                    </a>
                  </div>

                  <hr className="mt-3 mb-3" />

                  <Row>
                    <Col sm="6">
                      <small className="text-muted">Número de Documento</small>
                      <div className="mb-1">
                        {payment.invoice.invoiceNumber}
                      </div>

                      <small className="text-muted">Fecha de Pago</small>
                      <div className="mb-1">
                        {moment(payment.date).format('YYYY-MM-DD')}
                      </div>

                      <small className="text-muted">Total</small>
                      <div className="mb-1">
                        <strong>{displayAmount(payment.total / 100)}</strong>
                      </div>
                    </Col>
                  </Row>

                  {payment.transactionNote ? (
                    <Button
                      size="sm"
                      color="transparent"
                      className="mx-auto d-block"
                      onClick={() => setDisplayMoreFields(!displayMoreFields)}
                    >
                      {displayMoreFields ? 'Mostrar menos' : 'Mostrar más'}
                    </Button>
                  ) : null}

                  {displayMoreFields ? (
                    <Row>
                      <Col>
                        <small className="text-muted">Anotaciones</small>
                        <div
                          className="mt-1 app-quill-content"
                          dangerouslySetInnerHTML={{
                            __html: payment.transactionNote
                          }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => {
              onClose();
            }}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return renderView();
}

export default AppARPayment;
