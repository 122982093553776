import React, { useEffect, useRef, useState } from 'react';
import moment from 'utils/moment';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col
} from 'reactstrap';
import html2canvas from 'html2canvas';

import {
  getName,
  useForm,
  validate,
  formatFilename,
  getFileObject
} from 'utils';
import { useAuth } from 'hooks/use-auth';
import AlternativeHeader from 'components/Headers/AlternativeHeader';
import AppInput from 'components/Forms/AppInput';
import { UserPosition, UserRole } from '../../constants';
import fileAPI from 'services/api/file';
import userAPI from 'services/api/user';

import AppActivity from 'components/Activities/AppActivity';

function AppUserView({
  user,
  avatar,
  onAvatarChange,
  onSave,
  onClose,
  onDocumentDelete,
  onDocumentFieldChange
}) {
  const { isAdmin, isAdminReadOnly } = useAuth();
  const requiredValues = ['firstName', 'email'];

  const avatarFieldRef = useRef(null);

  const signatureCanvasRef = useRef(null);
  const signatureRef = useRef(null);
  const [generatedBase64, setGeneratedBase64] = useState(false);
  const [changedSignature, setChangedSignature] = useState(true);
  const [imageSlideSize, setImageSlideSize] = useState(160);

  const [isSending, setIsSending] = useState(false);
  const [enabledButton, setEnabledButton] = useState(false);

  const documentFieldRef = useRef(null);
  const [documentToUploadName, setDocumentToUploadName] = useState('');

  const generateBase64 = async () => {
    try {
      if (
        user.fileSignature &&
        signatureRef.current.complete &&
        signatureRef.current.naturalHeight !== 0 &&
        !generatedBase64
      ) {
        const imgCanvas = document.createElement('canvas');
        imgCanvas.width = signatureRef.current.width;
        imgCanvas.height = signatureRef.current.height;
        const ctx = imgCanvas.getContext('2d');
        ctx.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
        ctx.drawImage(signatureRef.current, 0, 0);
        signatureRef.current.src = imgCanvas.toDataURL('image/png');
        setGeneratedBase64(true);
      } else if (!generatedBase64) {
        setTimeout(generateBase64, 100);
      }
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  useEffect(() => {
    if (user.fileSignature) {
      generateBase64();
    }
  }, [user.fileSignature]);

  // useEffect(() => {
  //   if (generatedBase64) {
  //     return;
  //   }

  //   async function generateBase64() {
  //     try {
  //       if (user.fileSignature) {
  //         const imgCanvas = document.createElement('canvas');
  //         imgCanvas.width = signatureRef.current.width;
  //         imgCanvas.height = signatureRef.current.height;
  //         const ctx = imgCanvas.getContext('2d');
  //         ctx.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
  //         ctx.drawImage(signatureRef.current, 0, 0);
  //         signatureRef.current.src = imgCanvas.toDataURL('image/png');
  //         setGeneratedBase64(true);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //       /* handle error */
  //     }
  //   }

  //   signatureRef?.current?.addEventListener('load', generateBase64);
  //   return () =>
  //     signatureRef?.current?.removeEventListener('load', generateBase64);
  // }, [generatedBase64]);

  // useEffect(() => {
  //   async function generateBase() {
  //     try {
  //       if (!changedSignature && user.fileSignature) {
  //         const imgCanvas = document.createElement('canvas');
  //         imgCanvas.width = signatureRef.current.width;
  //         imgCanvas.height = signatureRef.current.height;
  //         const ctx = imgCanvas.getContext('2d');
  //         ctx.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
  //         ctx.drawImage(signatureRef.current, 0, 0);
  //         signatureRef.current.src = imgCanvas.toDataURL('image/png');
  //         setChangedSignature(true);
  //       }
  //       signatureRef?.current?.removeEventListener('load', generateBase);
  //     } catch (e) {
  //       console.log(e);
  //       /* handle error */
  //     }
  //   }

  //   signatureRef?.current?.addEventListener('load', generateBase);
  //   return () =>
  //     signatureRef?.current?.removeEventListener('load', generateBase);
  // }, [user.fileSignature]);

  const changeImageSizeWithSlider = (sideLength, elClass) => {
    var sideLengthStr = sideLength + 'px';
    var imageBoxes = document.getElementsByClassName(elClass);

    for (var i = 0; i < imageBoxes.length; i++) {
      imageBoxes[i].style.width = sideLengthStr;
    }

    setImageSlideSize(sideLength);
  };

  const saveImage = async (e, userFileName) => {
    e.preventDefault();

    if (isSending) return;
    setIsSending(true);

    const currentFileName = user[userFileName];
    let currentCanvasRef = null;

    currentCanvasRef = signatureCanvasRef;

    try {
      currentCanvasRef.current.style.border = 'none';
      currentCanvasRef.current.style.backgroundColor = 'transparent';
      const canvas = await html2canvas(currentCanvasRef.current, {
        backgroundColor: null
      });
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const file = await new Promise((resolve) =>
        canvas.toBlob(resolve, 'image/png')
      );

      const fileName = formatFilename(
        user[userFileName].replace('documents-user', ''),
        'documents-user'
      );

      const signedRequest = await fileAPI.signS3Upload(fileName, 'image/png');
      await fileAPI.uploadToS3(file, signedRequest);
      await fileAPI.create({
        key: fileName
      });

      await onSave({
        [userFileName]: fileName
      });

      await fileAPI.deleteOneByKey(currentFileName);
      setIsSending(false);
    } catch (e) {
      setIsSending(false);
      /* handle error */
    }
  };

  const initDocumentUpload = (name) => {
    setGeneratedBase64(false);
    setDocumentToUploadName(name);
    documentFieldRef.current.click();
  };

  const handleDocumentFieldChange = async (e) => {
    // setChangedSignature(false);
    try {
      await onDocumentFieldChange(e, documentToUploadName);
      setDocumentToUploadName('');
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const submitHandler = async (v) => {
    if (isSending) return;
    setIsSending(true);
    let form = {};

    try {
      Object.entries(dirtyInputs).forEach(([key, value]) => {
        if (value) {
          form[key] = v[key];
        }
      });
      if (avatar !== user.avatar) {
        form.avatar = avatar;
      }
      await onSave(form);
    } catch (e) {
      setIsSending(false);
    }
  };

  const isBtnEnabled = () => {
    return false;
    // if (!enabledButton) return true;
    // return !Object.entries(dirtyInputs).find(([key, value]) => value);
  };

  const {
    values,
    dirtyInputs,
    errors,
    handleBlur,
    handleChange,
    handleSubmit
  } = useForm(submitHandler, requiredValues, user, {}, {});

  const renderDocumentsItems = () => {
    let items = [
      {
        label: 'Cédula/Pasaporte',
        icon: 'fas fa-id-card',
        name: 'fileIdenticiation',
        available: true
      },
      {
        label: 'Currículum',
        icon: 'fas fa-file-invoice',
        name: 'fileCV',
        available: true
      },
      {
        label: 'Prueba Psicológica',
        icon: 'fas fa-brain',
        name: 'filePsychoTest',
        available: true
      },
      {
        label: 'Carta de Buena Conducta',
        icon: 'fas fa-ice-cream',
        name: 'fileGoodConduct',
        available: true
      },
      {
        label: 'Foto 2x2',
        icon: 'fas fa-id-badge',
        name: 'filePhotoTwoByTwo',
        available: true
      },
      {
        label: 'Firma',
        icon: 'fas fa-signature',
        name: 'fileSignature',
        available: isAdmin() || isAdminReadOnly()
      },
      {
        label: 'Certificado Codia',
        icon: 'fas fa-certificate',
        name: 'fileCodiaCertificate',
        available: isAdmin() || isAdminReadOnly()
      }
    ];

    return items
      .filter((i) => i.available)
      .map((i) => (
        <ListGroupItem key={i.name} className="px-0">
          <Row className="align-items-center">
            <Col className="col-auto">
              <a
                onClick={(e) => {
                  if (!user[i.name]) e.preventDefault();
                }}
                style={{ height: '32px', width: '32px' }}
                href={user[i.name] ? getFileObject(user[i.name]) : '#'}
                target="_blank"
                className={`d-block ${
                  user[i.name] ? 'bg-primary' : 'bg-gray'
                } text-white d-flex justify-content-center align-items-center rounded`}
              >
                <i className={i.icon}></i>
              </a>
            </Col>
            <div className="col">
              <a
                onClick={(e) => {
                  if (!user[i.name]) e.preventDefault();
                }}
                style={{ height: '32px', width: '32px' }}
                href={user[i.name] ? getFileObject(user[i.name]) : '#'}
                target="_blank"
              >
                <h5 className="mb-0">{i.label}</h5>
              </a>
            </div>
            <div className="col">
              {user[i.name] ? (
                <Button
                  color="warning"
                  size="sm"
                  className="d-block ml-auto"
                  onClick={(e) => {
                    if (
                      !window.confirm(
                        '¿Seguro que quieres eliminar este documento?'
                      )
                    )
                      return;
                    e.preventDefault();
                    onDocumentDelete(i.name);
                  }}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : (
                <Button
                  color="default"
                  size="sm"
                  className="d-block ml-auto"
                  onClick={(e) => {
                    e.preventDefault();
                    initDocumentUpload(i.name);
                  }}
                >
                  <i className="fas fa-upload"></i>
                </Button>
              )}
            </div>
          </Row>
        </ListGroupItem>
      ));
  };

  return (
    <>
      <AlternativeHeader
        name={getName(values)}
        parentName="Usuarios"
        onBackClick={onClose}
      />

      <Container fluid className="mt--6">
        <Row>
          <Col className="order-xl-2" xl="4">
            <Card className="card-profile">
              <CardImg
                style={{
                  background: '#172b4d',
                  paddingBottom: '32px',
                  borderRadius: '1rem 1rem 0 0'
                }}
                alt="..."
                src={require('assets/img/profile-heading-white.png').default}
                top
              />

              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        avatarFieldRef.current.click();
                      }}
                    >
                      <span
                        style={{
                          width: '140px',
                          height: '140px'
                        }}
                      >
                        {(avatar || user.avatar) &&
                        avatar !== 'noProfileImage.jpg' &&
                        user.avatar !== 'noProfileImage.jpg' ? (
                          <img
                            style={{
                              objectFit: 'cover',
                              width: '140px',
                              height: '140px'
                            }}
                            className="rounded-circle"
                            alt={getName(values)}
                            src={getFileObject(avatar || user.avatar)}
                          />
                        ) : (
                          <img
                            style={{
                              objectFit: 'cover',
                              width: '140px',
                              height: '140px'
                            }}
                            className="rounded-circle"
                            alt={getName(values)}
                            src="noProfileImage.jpg"
                          />
                        )}
                      </span>
                    </a>
                    <input
                      hidden
                      ref={avatarFieldRef}
                      type="file"
                      onChange={onAvatarChange}
                    />
                  </div>
                </Col>
              </Row>

              <CardHeader className="text-center border-0 pt-6 pt-md-4 pb-0 pb-md-4">
                {/* <div className="d-flex justify-content-between"> */}
                {/*   <Button */}
                {/*     className="mr-4" */}
                {/*     color="info" */}
                {/*     href="#pablo" */}
                {/*     onClick={(e) => e.preventDefault()} */}
                {/*     size="sm" */}
                {/*   > */}
                {/*     Connect */}
                {/*   </Button> */}
                {/*   <Button */}
                {/*     className="float-right" */}
                {/*     color="default" */}
                {/*     href="#pablo" */}
                {/*     onClick={(e) => e.preventDefault()} */}
                {/*     size="sm" */}
                {/*   > */}
                {/*     Message */}
                {/*   </Button> */}
                {/* </div> */}
              </CardHeader>

              <CardBody className="pt-0">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center">
                      {/* <div> */}
                      {/*   <span className="heading">22</span> */}
                      {/*   <span className="description">Friends</span> */}
                      {/* </div> */}
                      {/* <div> */}
                      {/*   <span className="heading">10</span> */}
                      {/*   <span className="description">Photos</span> */}
                      {/* </div> */}
                      {/* <div> */}
                      {/*   <span className="heading">89</span> */}
                      {/*   <span className="description">Comments</span> */}
                      {/* </div> */}
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h5 className="h3">
                    {getName(values)}
                    <span className="font-weight-light">
                      {values.birthDay
                        ? `, ${moment().diff(values.birthDay, 'years')}`
                        : null}
                    </span>
                  </h5>
                  {values.city && values.country ? (
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin" />
                      {values.city}
                      {values.country ? `, ${values.country}` : null}
                    </div>
                  ) : null}

                  {values.position ? (
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24" />
                      {values.position}
                    </div>
                  ) : null}

                  {values.company ? (
                    <div>
                      <i className="ni education_hat" />
                      {values.company}
                    </div>
                  ) : null}

                  {values.website ? (
                    <div>
                      <i className="ni education_hat" />
                      {values.website}
                    </div>
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {user._id ? 'Editar' : 'Crear'} usuario
                    </h3>
                  </Col>
                  {/* <Col className="text-right" xs="4"> */}
                  {/*   <Button */}
                  {/*     color="primary" */}
                  {/*     href="#pablo" */}
                  {/*     onClick={(e) => e.preventDefault()} */}
                  {/*     size="sm" */}
                  {/*   > */}
                  {/*     Settings */}
                  {/*   </Button> */}
                  {/* </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">Información</h6>
                  <Row>
                    <Col lg="6">
                      <AppInput
                        label="Nombre"
                        id="firstName"
                        name="firstName"
                        type="text"
                        value={values.firstName || ''}
                        error={errors.firstName}
                        required
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <AppInput
                        label="Apellido"
                        id="lastName"
                        name="lastName"
                        type="text"
                        value={values.lastName || ''}
                        error={errors.lastName}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <AppInput
                        label="Correo Eléctronico"
                        id="email"
                        name="email"
                        type="email"
                        value={values.email || ''}
                        error={errors.email}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <AppInput
                        label="Rol"
                        id="role"
                        name="role"
                        type="select"
                        value={values.role}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      >
                        {Object.entries(UserRole).map(([k, v]) => (
                          <option
                            key={`role-${v}`}
                            value={v}
                            defaultChecked={values.role === v}
                          >
                            {v}
                          </option>
                        ))}
                      </AppInput>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <AppInput
                        label="Puesto"
                        id="position"
                        name="position"
                        type="select"
                        value={values.position || ''}
                        error={errors.position}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      >
                        {Object.entries(UserPosition).map(([k, v]) => (
                          <option
                            key={`role-${v}`}
                            value={v}
                            defaultChecked={values.role === v}
                          >
                            {v}
                          </option>
                        ))}
                      </AppInput>
                    </Col>

                    <Col lg="6">
                      <AppInput
                        label="Empresa"
                        id="company"
                        name="company"
                        type="text"
                        value={values.company || ''}
                        error={errors.company}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>

                    <Col lg="6">
                      <AppInput
                        label="Website"
                        id="website"
                        name="website"
                        type="text"
                        value={values.website || ''}
                        error={errors.website}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                  </Row>

                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">Contacto</h6>
                  <Row>
                    <Col md="12">
                      <AppInput
                        label="Dirección"
                        id="address"
                        name="address"
                        type="text"
                        value={values.address || ''}
                        error={errors.address}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <AppInput
                        label="Ciudad"
                        id="city"
                        name="city"
                        type="text"
                        value={values.city || ''}
                        error={errors.address}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                    <Col lg="4">
                      <AppInput
                        label="Teléfono"
                        id="phone"
                        name="phone"
                        type="text"
                        value={values.phone || ''}
                        error={errors.phone}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                    <Col lg="4">
                      <AppInput
                        label="Celular"
                        id="mobilePhone"
                        name="mobilePhone"
                        type="text"
                        value={values.mobilePhone || ''}
                        error={errors.mobilePhone}
                        onChange={(e) =>
                          handleChange(e, (currentValues) =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(currentValues, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                        onBlur={(e) =>
                          handleBlur(e, () =>
                            setEnabledButton(
                              JSON.stringify(
                                validate(values, requiredValues)
                              ) === '{}'
                            )
                          )
                        }
                      />
                    </Col>
                  </Row>

                  <Button
                    color="primary"
                    type="button"
                    size="sm"
                    disabled={isBtnEnabled()}
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                </Form>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="12">
                    <h3 className="mb-0">Documentos</h3>
                  </Col>

                  {/* <Col className="text-right" xs="4"> */}
                  {/*   <Button */}
                  {/*     color="primary" */}
                  {/*     href="#pablo" */}
                  {/*     onClick={(e) => e.preventDefault()} */}
                  {/*     size="sm" */}
                  {/*   > */}
                  {/*     Settings */}
                  {/*   </Button> */}
                  {/* </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <input
                  hidden
                  ref={documentFieldRef}
                  type="file"
                  onChange={handleDocumentFieldChange}
                />
                <ListGroup className="list" flush>
                  {renderDocumentsItems()}
                </ListGroup>
              </CardBody>
            </Card>

            <AppActivity resourceId={user._id} />
          </Col>
        </Row>

        <Card>
          <CardHeader>
            <h3 className="mb-0">Manipular documentos</h3>
          </CardHeader>
          <CardBody>
            <a
              href="#"
              download="example.png"
              onClick={(e) => saveImage(e, 'fileSignature')}
            >
              Actualizar firma
            </a>
            <br />
            <input
              type="range"
              min="50"
              max="500"
              value={imageSlideSize}
              className="slider"
              id="range-image-size"
              onChange={(e) =>
                changeImageSizeWithSlider(e.target.value, 'image_thumbnail')
              }
            />{' '}
            <br />
            <div ref={signatureCanvasRef} className="canvas-image-box">
              <img
                crossOrigin="anonymous"
                ref={signatureRef}
                src={getFileObject(user.fileSignature)}
                className="image_thumbnail"
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default AppUserView;
