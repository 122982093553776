import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Col,
  Row,
  UncontrolledTooltip,
  Spinner,
  Badge
} from 'reactstrap';
import moment from 'utils/moment';

import { getName, displayAmount } from 'utils';
import { NotificationKind, TransactionKind } from '../../constants';
import AccountsReceivableAPI from 'services/api/accountReceivable';
import NotificationsAPI from 'services/api/notifications';
import Header from 'components/Headers/AlternativeHeader';
import AppARShowTransactions from 'components/AccountsReceivable/AppARShowTransactions';
import AppARInvoiceForm from 'components/AccountsReceivable/AppARInvoiceForm';
import AppARPaymentForm from 'components/AccountsReceivable/AppARPaymentForm';
import AppARCreditNoteForm from 'components/AccountsReceivable/AppARCreditNoteForm';
import AppActivity from 'components/Activities/AppActivity';
import AppARInvoice from 'components/AccountsReceivable/AppARInvoice';
import AppARPayment from 'components/AccountsReceivable/AppARPayment';
import AppARCreditNote from 'components/AccountsReceivable/AppARCreditNote';
import AppARNotify from 'components/AccountsReceivable/AppARNotify';
import AppNotes from 'components/Notes/AppNotes';

function AccountsReceivableShow() {
  const { id: AccountReceivableId } = useParams();
  // const location = useLocation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [accountReceivable, setAccountReceivable] = useState(null);
  const [transactions, setTransactions] = useState([]);

  const [invoice, setInvoice] = useState(null);
  const [isViewingInvoice, setIsViewingInvoice] = useState(false);
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);
  const [isEditingInvoice, setIsEditingInvoice] = useState(false);

  const [payment, setPayment] = useState(null);
  const [isViewingPayment, setIsViewingPayment] = useState(false);
  const [isEditingPayment, setIsEditingPayment] = useState(false);

  const [creditNote, setCreditNote] = useState(null);
  const [isViewingCreditNote, setIsViewingCreditNote] = useState(false);
  const [isEditingCreditNote, setIsEditingCreditNote] = useState(false);

  const [milestone, setMilestone] = useState(null);

  const [isCreatingPayment, setIsCreatingPayment] = useState(false);
  const [isCreatingCreditNote, setIsCreatingCreditNote] = useState(false);

  const [milestoneToNotify, setMilestoneToNotify] = useState(null);

  const [selectedDate, setSelectedDate] = useState(moment().startOf('month'));

  useEffect(() => {
    async function fetchData() {
      try {
        const ar = await AccountsReceivableAPI.getOne(AccountReceivableId);
        if (!ar) history.push(`/cuentas-por-cobrar`);
        setAccountReceivable(ar);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log(e);
        /* handle error */
      }
    }
    fetchData();
  }, []);

  useEffect(() => fetchTransactions(), []);

  const fetchTransactions = async () => {
    try {
      const tmpTransactions = await AccountsReceivableAPI.getTransactions(
        AccountReceivableId
      );
      setTransactions(tmpTransactions);

      if (invoice)
        setInvoice(tmpTransactions.find((tT) => tT._id === invoice._id));
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  // const handleClose = async () => {
  //   try {
  //     const queryParams = new URLSearchParams(location.search);
  //     if (queryParams.has('c')) {
  //       queryParams.delete('c');
  //       history.replace({
  //         search: queryParams.toString()
  //       });
  //     }
  //     if (queryParams.has('id')) {
  //       queryParams.delete('id');
  //       history.replace({
  //         search: queryParams.toString()
  //       });
  //     }
  //     setAccountReceivable(null);
  //   } catch (e) {
  //     /* handle error */
  //     console.log(e);
  //   }
  // };

  // const handleAvatarChange = async (e) => {
  //   const file = e.target.files[0];
  //   const data = new FormData();
  //   data.append('file', file);

  //   try {
  //     const result = await fileAPI.create(data);
  //     if (customer._id) {
  //       await customerAPI.update(customer._id, { avatar: result.link });
  //       setCustomer(await customerAPI.getOne(customer._id));
  //     }

  //     setAvatar(result.link);
  //   } catch (e) {
  //     console.log(e);
  //     /* handle error */
  //   }
  // };

  //   const handleSave = async (payload) => {
  //     try {
  //       if (customer._id) {
  //         console.log(payload);
  //         await customerAPI.update(customer._id, payload);
  //         setCustomer(await customerAPI.getOne(customer._id));
  //         setCustomer(null);
  //       } else {
  //         await customerAPI.create(payload);
  //         await fetchItems();
  //         setCustomer(null);
  //       }
  //     } catch (e) {
  //       /* handle error */
  //       console.log(e);
  //     }
  //   };

  //   const handleDelete = async (id) => {
  //     if (!window.confirm('¿Seguro quieres eliminar este usuario?')) return;
  //     if (!window.confirm('¡Después de este punto, no hay vuelta atrás!')) return;
  //     try {
  //       await customerAPI.deleteOne(id);
  //       fetchItems();
  //     } catch (e) {
  //       /* handle error */
  //       console.log(e);
  //     }
  //   };

  const getPendingBalance = () => {
    let amount = 0.0;

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (transactions[i].kind === TransactionKind.Invoice) {
        amount -= transactions[i].total / 100;
      } else {
        amount += transactions[i].total / 100;
      }
    }

    return amount;
  };

  const getTotalInvoiced = () => {
    let amount = 0.0;

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (transactions[i].kind === TransactionKind.Invoice) {
        amount += transactions[i].total / 100;
      }
    }

    return amount;
  };

  const handleNewClick = () => {
    setIsCreatingInvoice(true);
  };

  const handleClose = () => {
    history.push(`/cuentas-por-cobrar`);
  };

  const handleItemClick = (payload) => {
    switch (payload.kind) {
      case TransactionKind.Invoice:
        setInvoice(payload);
        setIsViewingInvoice(true);
        break;
      case TransactionKind.Payment:
        setPayment(payload);
        setIsViewingPayment(true);
        break;
      case TransactionKind.CreditNote:
        setCreditNote(payload);
        setIsViewingCreditNote(true);
        break;
    }
  };

  const handleNewPaymentClick = (payload, mil) => {
    setInvoice(payload);
    if (mil) setMilestone(mil);
    setIsCreatingPayment(true);
  };

  const handleNewCreditNoteClick = (payload, mil) => {
    setInvoice(payload);
    if (mil) setMilestone(mil);
    setIsCreatingCreditNote(true);
  };

  const handleTransactionCreated = async () => {
    setIsCreatingInvoice(false);
    setIsEditingInvoice(false);
    setIsCreatingPayment(false);
    setIsEditingPayment(false);
    setIsCreatingCreditNote(false);
    setIsEditingCreditNote(false);
    setInvoice(null);
    setMilestone(null);
    setIsLoading(true);
    try {
      setAccountReceivable(
        await AccountsReceivableAPI.getOne(AccountReceivableId)
      );
      await fetchTransactions();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      /* handle error */
    }
  };

  const getAllMilestones = () => {
    if (!transactions) return [];
    return transactions
      .filter((t) => t.milestones && t.milestones.length > 0)
      .reduce(
        (a, b) => [...a, ...b.milestones.map((m) => ({ ...m, invoice: b }))],
        []
      )
      .filter((m) => !m.completed)
      .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
  };

  const getExpiredMilestones = (list) => {
    return list.filter((i) =>
      moment(i.date).startOf('day').isBefore(moment().startOf('day'))
    );
  };

  const getNextsMilestones = (list) => {
    return list.filter(
      (i) =>
        moment(i.date).isSameOrAfter(moment(selectedDate)) &&
        moment(i.date).isSameOrAfter(moment().startOf('day')) &&
        moment(i.date).isSameOrBefore(moment(selectedDate).endOf('month'))
    );
  };

  const handleInvoiceUpdate = async () => {
    setIsLoading(true);
    try {
      setAccountReceivable(
        await AccountsReceivableAPI.getOne(AccountReceivableId)
      );
      await fetchTransactions();
      setIsLoading(false);
      // setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      // setIsLoading(false);
      /* handle error */
    }
  };

  const handleItemEditClick = (payload) => {
    switch (payload.kind) {
      case TransactionKind.Invoice:
        setInvoice(payload);
        setIsViewingInvoice(false);
        setIsEditingInvoice(true);
        break;
      case TransactionKind.Payment:
        setInvoice(transactions.find((t) => t._id === payload.invoice._id));
        setPayment(payload);
        setIsViewingPayment(false);
        setIsEditingPayment(true);
        break;
      case TransactionKind.CreditNote:
        setInvoice(transactions.find((t) => t._id === payload.invoice._id));
        setCreditNote(payload);
        setIsViewingCreditNote(false);
        setIsEditingCreditNote(true);
        break;
    }
  };

  const handleItemDelete = async (id) => {
    setIsCreatingInvoice(false);
    setIsEditingInvoice(false);
    setIsCreatingPayment(false);
    setIsCreatingCreditNote(false);
    setInvoice(null);
    setMilestone(null);
    setIsLoading(true);
    try {
      await AccountsReceivableAPI.removeTransaction(accountReceivable._id, id);
      await fetchTransactions();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      /* handle error */
    }
  };

  const notifyCustomer = async (kind, payload) => {
    try {
      await NotificationsAPI.create({ kind, ...payload });
      setAccountReceivable(
        await AccountsReceivableAPI.getOne(AccountReceivableId)
      );
      await fetchTransactions();
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const nextMonth = () => {
    const newDate = moment(selectedDate).add('1', 'month').startOf('month');
    setSelectedDate(newDate);
  };

  const prevMonth = () => {
    const newDate = moment(selectedDate)
      .subtract('1', 'month')
      .startOf('month');
    setSelectedDate(newDate);
  };

  const setCurrentMonth = () => {
    setSelectedDate(moment().startOf('month'));
  };

  const displaySelectedDate = () => {
    return selectedDate.format('MMMM YYYY');
  };

  const displayInvoicedTotalChange = () => {
    let amount = 0.0;

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (
        transactions[i].kind === TransactionKind.Invoice &&
        moment(transactions[i].date).isSameOrAfter(moment().startOf('month'))
      ) {
        amount += transactions[i].total / 100;
      }
    }

    if (!amount)
      return (
        <span className="text-gray mr-2">
          <i className="fas fa-minus"></i>
        </span>
      );

    return (
      <span className="text-success mr-2">
        <strong>
          <i className="fa fa-arrow-up" /> {displayAmount(amount)}
        </strong>
      </span>
    );
  };

  const displayBalancePercentageChange = () => {
    let amount = 0.0;
    let change = 0.0;
    let difference = 0.0;

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (
        transactions[i].kind === TransactionKind.Invoice &&
        moment(transactions[i].date).isSameOrBefore(moment().startOf('month'))
      ) {
        amount -= transactions[i].total / 100;
      } else if (
        moment(transactions[i].date).isSameOrBefore(moment().startOf('month'))
      ) {
        amount += transactions[i].total / 100;
      }
    }

    difference = getPendingBalance() - amount;
    if (!difference || !amount)
      return (
        <span className="text-gray mr-2">
          <i className="fas fa-minus"></i>
        </span>
      );

    change = (difference / amount) * 100.0;
    return (
      <span className={`text-${change > 0 ? 'danger' : 'success'} mr-2`}>
        <strong>
          <i className={`fa fa-arrow-${change > 0 ? 'up' : 'down'}`} />{' '}
          {change.toFixed(2)}%
        </strong>
      </span>
    );
  };

  const displayNextPaymentDate = () => {
    const nextPayment = getAllMilestones().find((i) =>
      moment(i.date).isSameOrAfter(moment().startOf('day'))
    );

    if (!nextPayment)
      return (
        <span className="h2 font-weight-bold mb-0 text-gray">
          <i className="fas fa-minus"></i>
        </span>
      );
    return (
      <span className="h2 font-weight-bold mb-0">
        {moment(nextPayment.date).format('LL')}
      </span>
    );
  };

  const displayLastPaymentTimeAgo = () => {
    const lastPayment = transactions.find(
      (t) =>
        t.kind === TransactionKind.CreditNote ||
        t.kind === TransactionKind.Payment
    );

    if (!lastPayment)
      return (
        <span className="text-gray mr-2">
          <i className="fas fa-minus"></i>
        </span>
      );

    return <span className="mr-2">{moment(lastPayment.date).fromNow()}</span>;
  };

  const renderView = () => {
    if (isLoading) {
      return (
        <div
          style={{
            width: '100%',
            height: '80vh'
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <Spinner />
        </div>
      );
    }

    return (
      <>
        <Header
          name={
            <Link to={`/clientes?id=${accountReceivable.customer._id}`}>
              {getName(accountReceivable.customer)}{' '}
              {accountReceivable.customer.alias
                ? `(${accountReceivable.customer.alias})`
                : ''}
            </Link>
          }
          parentName="Cuentas"
          onBackClick={handleClose}
          onNewLabel="Nueva Factura"
          onNewClick={handleNewClick}
          onEdit
        />

        {isCreatingInvoice || isEditingInvoice ? (
          <AppARInvoiceForm
            accountReceivableId={accountReceivable._id}
            invoice={invoice}
            isCreatingInvoice={isCreatingInvoice}
            isEditingInvoice={isEditingInvoice}
            onClose={() => {
              setIsCreatingInvoice(false);
              setIsEditingInvoice(false);
            }}
            onSuccess={handleTransactionCreated}
          />
        ) : null}

        {isCreatingPayment || isEditingPayment ? (
          <AppARPaymentForm
            accountReceivableId={accountReceivable._id}
            transactions={transactions}
            invoice={invoice}
            payment={payment}
            milestone={milestone}
            isCreatingPayment={isCreatingPayment}
            isEditingPayment={isEditingPayment}
            onClose={() => {
              setInvoice(null);
              setMilestone(null);
              setIsCreatingPayment(false);
              setIsEditingPayment(false);
            }}
            onSuccess={handleTransactionCreated}
          />
        ) : null}

        {isCreatingCreditNote || isEditingCreditNote ? (
          <AppARCreditNoteForm
            accountReceivableId={accountReceivable._id}
            transactions={transactions}
            invoice={invoice}
            creditNote={creditNote}
            milestone={milestone}
            isCreatingCreditNote={isCreatingCreditNote}
            isEditingCreditNote={isEditingCreditNote}
            onClose={() => {
              setInvoice(null);
              setMilestone(null);
              setIsCreatingCreditNote(false);
              setIsEditingCreditNote(false);
            }}
            onSuccess={handleTransactionCreated}
          />
        ) : null}

        <AppARInvoice
          accountReceivableId={accountReceivable._id}
          transactions={transactions}
          invoice={invoice}
          isActive={isViewingInvoice}
          onClose={() => setIsViewingInvoice(false)}
          onSuccess={handleInvoiceUpdate}
          onEditClick={handleItemEditClick}
          onDeleteClick={handleItemDelete}
        />

        <AppARPayment
          accountReceivableId={accountReceivable._id}
          payment={payment}
          isActive={isViewingPayment}
          onClose={() => setIsViewingPayment(false)}
          onSuccess={handleTransactionCreated}
          onEditClick={handleItemEditClick}
          onDeleteClick={handleItemDelete}
        />

        <AppARCreditNote
          accountReceivableId={accountReceivable._id}
          creditNote={creditNote}
          isActive={isViewingCreditNote}
          onClose={() => setIsViewingCreditNote(false)}
          onEditClick={handleItemEditClick}
          onDeleteClick={handleItemDelete}
        />

        <AppARNotify
          accountReceivableId={accountReceivable._id}
          milestone={milestoneToNotify}
          isActive={!!milestoneToNotify}
          onClose={() => setMilestoneToNotify(null)}
        />

        <Container fluid className="mt--6">
          <Row>
            <Col lg="5" xl="4" className="order-lg-2">
              {getExpiredMilestones(getAllMilestones()).length > 0 ? (
                <Card>
                  <CardHeader>
                    <h5 className="h3 text-dark mb-0">Vencidos</h5>
                  </CardHeader>

                  <CardBody>
                    <ul className="calendar-list">
                      {getExpiredMilestones(getAllMilestones()).map(
                        (m, mIndex) => (
                          <li
                            key={`calendar-payment-pending-${mIndex}`}
                            className="calendar-list-item"
                          >
                            <span className="calendar-list-item__full-date">
                              <strong>{moment(m.date).format('L')}</strong>
                            </span>

                            <Link
                              id={`calendar-payment-amount-expired-${mIndex}`}
                              to="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleItemClick(m.invoice);
                              }}
                            >
                              {m.invoice.invoiceNumber}
                            </Link>

                            <UncontrolledTooltip
                              delay={0}
                              target={`calendar-payment-amount-expired-${mIndex}`}
                            >
                              {displayAmount(m.amount)}
                            </UncontrolledTooltip>

                            <span className="calendar-list-item__actions">
                              <button
                                onClick={() =>
                                  handleNewPaymentClick(m.invoice, m)
                                }
                              >
                                <i className="fas fa-hand-holding-usd"></i>
                              </button>
                              <button
                                onClick={() =>
                                  handleNewCreditNoteClick(m.invoice, m)
                                }
                              >
                                <i className="fas fa-file-invoice-dollar"></i>
                              </button>
                              <button
                                id={`calendar-list-expired-payment-${m._id}`}
                                onClick={() => {
                                  const lastReminder =
                                    m.expiredNotifiedAt &&
                                    m.expiredNotifiedAt[0]
                                      ? moment(
                                          m.expiredNotifiedAt[
                                            m.expiredNotifiedAt.length - 1
                                          ]
                                        ).fromNow()
                                      : 'nunca';

                                  if (
                                    !window.confirm(
                                      `Ultimo envio: ${lastReminder}\n\n¿Seguro que quieres notificar el cliente del pago vencido?`
                                    )
                                  ) {
                                    return;
                                  }

                                  notifyCustomer(
                                    NotificationKind.MilestoneStatus,
                                    {
                                      milestoneId: m._id
                                    }
                                  );
                                }}
                              >
                                <i className="fas fa-bell"></i>
                              </button>

                              <UncontrolledTooltip
                                delay={0}
                                target={`calendar-list-expired-payment-${m._id}`}
                              >
                                Notificar pago vencido
                              </UncontrolledTooltip>
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  </CardBody>
                </Card>
              ) : null}

              <Card>
                <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
                  <h5 className="h3 text-dark mb-0">Próximos</h5>

                  <div
                    className="calendar-actions_arrows"
                    style={{ fontSize: '16px' }}
                  >
                    <button onClick={prevMonth}>
                      <i className="fas fa-arrow-circle-left"></i>
                    </button>
                    <div onClick={setCurrentMonth} style={{ fontSize: '14px' }}>
                      <strong>{displaySelectedDate()}</strong>
                    </div>
                    <button onClick={nextMonth}>
                      <i className="fas fa-arrow-circle-right"></i>
                    </button>
                  </div>
                </CardHeader>

                <CardBody>
                  {getNextsMilestones(getAllMilestones()).length > 0 ? (
                    <ul className="calendar-list">
                      {getNextsMilestones(getAllMilestones()).map(
                        (m, mIndex) => (
                          <li
                            key={`calendar-payment-${mIndex}`}
                            className="calendar-list-item"
                          >
                            <span className="calendar-list-item__date">
                              <span>{moment(m.date).format('ddd')}</span>{' '}
                              {moment(m.date).format('DD')}
                            </span>

                            <Link
                              id={`calendar-payment-amount-${mIndex}`}
                              to="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleItemClick(m.invoice);
                              }}
                            >
                              {m.invoice.invoiceNumber}
                            </Link>

                            <UncontrolledTooltip
                              delay={0}
                              target={`calendar-payment-amount-${mIndex}`}
                            >
                              {displayAmount(m.amount)}
                            </UncontrolledTooltip>

                            <span className="calendar-list-item__actions">
                              <button
                                onClick={() =>
                                  handleNewPaymentClick(m.invoice, m)
                                }
                              >
                                <i className="fas fa-hand-holding-usd"></i>
                              </button>
                              <button
                                onClick={() =>
                                  handleNewCreditNoteClick(m.invoice, m)
                                }
                              >
                                <i className="fas fa-file-invoice-dollar"></i>
                              </button>
                              <button
                                id={`calendar-list-about-to-expire-payment-${m._id}`}
                                onClick={() => {
                                  const lastReminder =
                                    m.aboutToExpireNotifiedAt &&
                                    m.aboutToExpireNotifiedAt[0]
                                      ? moment(
                                          m.aboutToExpireNotifiedAt[
                                            m.aboutToExpireNotifiedAt.length - 1
                                          ]
                                        ).fromNow()
                                      : 'nunca';

                                  if (
                                    !window.confirm(
                                      `Ultimo envio: ${lastReminder}\n\n¿Seguro que quieres notificar el cliente del pago próximo a vencer?`
                                    )
                                  ) {
                                    return;
                                  }

                                  notifyCustomer(
                                    NotificationKind.MilestoneStatus,
                                    {
                                      milestoneId: m._id
                                    }
                                  );
                                }}
                              >
                                <i className="fas fa-bell"></i>
                              </button>

                              <UncontrolledTooltip
                                delay={0}
                                target={`calendar-list-about-to-expire-payment-${m._id}`}
                              >
                                Notificar pago próximo a vencer
                              </UncontrolledTooltip>
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  ) : (
                    <div className="text-center">
                      <p className="p-0 my-3">Esta cuenta no tiene hitos</p>
                    </div>
                  )}
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="h3 text-dark mb-0">Notificaciones</h5>
                </CardHeader>

                <CardBody>
                  <Row data-masonry='{"percentPosition": true }'>
                    <Col xs="6" sm="4">
                      <Badge
                        color={
                          accountReceivable.customer &&
                          accountReceivable.customer.notifications &&
                          accountReceivable.customer.notifications
                            .aRExpiredMilestone
                            ? 'success'
                            : 'secondary'
                        }
                        size="sm"
                      >
                        Pago vencido
                      </Badge>
                    </Col>
                    <Col xs="6" sm="4">
                      <Badge
                        className="mt-2 mt-sm-0"
                        color={
                          accountReceivable.customer &&
                          accountReceivable.customer.notifications &&
                          accountReceivable.customer.notifications
                            .aRMilestoneAboutToExpire
                            ? 'success'
                            : 'secondary'
                        }
                        size="sm"
                      >
                        Pago proximo a vencer
                      </Badge>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <AppNotes resourceId={accountReceivable._id} />
            </Col>

            <Col className="order-lg-1" lg="7" xl="8">
              <Row>
                <Col md="6" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Balance Pendiente
                      </CardTitle>

                      <span className="h2 font-weight-bold mb-0">
                        {displayAmount(getPendingBalance())}
                      </span>

                      <p className="mt-3 mb-0 text-sm">
                        {displayBalancePercentageChange()}
                        <br />
                        <span className="text-nowrap">Desde el último mes</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Total facturado
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {displayAmount(getTotalInvoiced())}
                      </span>
                      <p className="mt-3 mb-0 text-sm">
                        {displayInvoicedTotalChange()}

                        <br />
                        <span className="text-nowrap">Desde el último mes</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="4">
                  <Card className="card-stats">
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Próximo pago
                      </CardTitle>

                      {displayNextPaymentDate()}

                      <p className="mt-3 mb-0 text-sm">
                        {displayLastPaymentTimeAgo()}
                        <br />
                        <span className="text-nowrap">
                          Desde el último pago
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <AppARShowTransactions
                accountReceivable={accountReceivable}
                transactions={transactions}
                onNewClick={() => setIsCreatingInvoice(true)}
                onNewPaymentClick={handleNewPaymentClick}
                onNewCreditNoteClick={handleNewCreditNoteClick}
                onItemClick={handleItemClick}
                notifyCustomer={notifyCustomer}
              />

              <AppActivity resourceId={accountReceivable._id} />
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  return renderView();
}

export default AccountsReceivableShow;
