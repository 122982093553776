const minPasswordLength = 8;

export const validate = (values, requiredValues, name) => {
  const errors = {};

  if (requiredValues.includes('firstName')) {
    if (!values.firstName) {
      errors.firstName = 'El nombre(s) es requerido';
    }
  }

  if (requiredValues.includes('lastName')) {
    if (!values.lastName) {
      errors.lastName = 'El apellido(s) es requerido';
    }
  }

  if (name === 'password' || requiredValues.includes('password')) {
    if (!values.password) {
      errors.password = `La contraseña debe contener ${minPasswordLength} caracteres mínimo.`;
    } else if (values.password.length < minPasswordLength) {
      errors.password = `La contraseña debe contener ${minPasswordLength} caracteres mínimo.`;
    }
  }

  if (requiredValues.includes('email')) {
    if (
      !values.email ||
      (values.email &&
        values.email.length > 0 &&
        !/\S+@\S+\.\S+/.test(values.email))
    ) {
      errors.email = 'Introduzca una dirección de correo electrónico válida.';
    }
  }

  if (
    name === 'confirmPassword' ||
    requiredValues.includes('confirmPassword')
  ) {
    if (!values.confirmPassword) {
      errors.confirmPassword = 'La contraseña es requerida.';
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Debe ser idéntica a la contraseña.';
    }
  }

  if (requiredValues.includes('address')) {
    if (!values.address) {
      errors.address = 'La dirección es requerida';
    }
  }

  if (requiredValues.includes('city')) {
    if (!values.city) {
      errors.city = 'La cuidad es requerida';
    }
  }

  if (requiredValues.includes('phone')) {
    if (!values.phone) {
      errors.phone = 'El telefono es requerido';
    }
  }

  if (requiredValues.includes('label')) {
    if (!values.label) {
      errors.label = 'El campo es requerido';
    }
  }

  if (requiredValues.includes('invoiceNumber')) {
    if (!values.invoiceNumber) {
      errors.invoiceNumber = 'La copia de la factura es requerida';
    }
  }

  return errors;
};

export default validate;
