import React, { useState, useEffect, useContext, createContext } from 'react';
import authService from '../services/api/auth';
import { UserRole } from '../constants';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await authService.getUser();
        setUser(user || false);
        setIsLoading(false);
      } catch (e) {
        setUser(false);
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  const signin = async (email, password) => {
    try {
      const response = await authService.signin(email, password);
      setUser(response.user);
      return response.user;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const isAdmin = () => {
    return user && user.role === UserRole.Admin;
  };

  const isAdminReadOnly = () => {
    return user && user.role === UserRole.AdminReadOnly;
  };

  const isAccountant = () => {
    return user && user.role === UserRole.Accountant;
  };

  const isGeneralSupervisor = () => {
    return user && user.role === UserRole.GeneralSupervisor;
  };

  return {
    user,
    setUser,
    signin,
    isLoading,
    isAccountant,
    isGeneralSupervisor,
    isAdmin,
    isAdminReadOnly
  };
}
