import React, { useEffect, useRef, useState } from 'react';
import moment from 'utils/moment';
import {
  Card,
  Spinner,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Button
} from 'reactstrap';

import attachmentService from 'services/api/attachment';
import FileAPI from 'services/api/file';
import { useAuth } from 'hooks/use-auth';
import { formatFilename } from 'utils';
import AppInput from 'components/Forms/AppInput';
import { Link } from 'react-router-dom';
import { getFileObject } from 'utils';

function AppAttachments({ resourceType, resourceId }) {
  const { isAdmin, isAdminReadOnly } = useAuth();
  const [attachment, setAttachment] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [form, setForm] = useState(null);
  const rejectionCheckpointFileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!isAdmin() && !isAdminReadOnly()) return;

    async function fetchAttachments() {
      try {
        const { attachments } = await attachmentService.getAll(
          resourceType,
          resourceId
        );
        setAttachment(attachments);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log(e);
        /* handle error */
      }
    }

    fetchAttachments();
  }, []);

  const handleChange = (e) => {
    let newValue = e.target.value;
    if (e.target.type === 'checkbox') newValue = !form[e.target.name];

    setForm({
      ...form,
      [e.target.name]: newValue
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      // setVoucherFieldDirty(true);
      e.target.value = null;
    }
  };

  const handleSave = async (e) => {
    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    if (!form.name) {
      newErrors.name = 'es obligatorio';
    }

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    try {
      const tmpAttachment = {
        name: form.name,
        file: ''
      };

      if (file) {
        const fileName = formatFilename(file.name, 'documents-attachment');
        const fileSignedRequest = await FileAPI.signS3Upload(
          fileName,
          file.type
        );
        await FileAPI.uploadToS3(file, fileSignedRequest);
        await FileAPI.create({
          key: fileName
        });
        tmpAttachment.file = fileName;
      }

      await attachmentService.create(resourceType, resourceId, tmpAttachment);

      const { attachments } = await attachmentService.getAll(
        resourceType,
        resourceId
      );
      setAttachment(attachments);
      setForm(null);
      setFile(null);
      setIsSending(false);
    } catch (e) {
      setIsSending(false);
      /* handle error */
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('¿Seguro quieres eliminar este archivo?')) {
      return;
    }

    if (isSending) return;
    setIsSending(true);

    try {
      await attachmentService.deleteOne(resourceType, resourceId, id);

      const { attachments } = await attachmentService.getAll(
        resourceType,
        resourceId
      );
      setAttachment(attachments);

      setIsSending(false);
    } catch (e) {
      setIsSending(false);
      /* handle error */
    }
  };

  const renderAttachments = () => {
    if (isLoading)
      return (
        <div className="text-center">
          <Spinner size="sm" />
        </div>
      );

    if (!attachment || attachment.length === 0) {
      return <p className="text-muted text-center">No existen archivos</p>;
    }

    return (
      <div className="">
        {attachment.map((a) => (
          <div key={`attachment-${a._id}`} className="my-3">
            <div className="pt-0">
              <div className="d-flex justify-content-between">
                <div>
                  <span
                    style={{ fontSize: '12px' }}
                    className="text-muted font-weight-bold"
                  >
                    {a.name}
                  </span>
                </div>
                <div className="text-right">
                  <small className="text-muted">
                    {moment(a.createdAt).format('ll')}{' '}
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete(a._id);
                      }}
                    >
                      <i className="fas fa-trash ml-2" />
                    </Link>
                  </small>
                </div>
              </div>
              <h6 style={{ fontSize: '12px' }} className="mt-1 mb-0">
                <a href={getFileObject(a.file)} target="_blank">
                  {a.file.replace('documents-attachment/', '')}
                </a>
              </h6>
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (!resourceId) return null;

  if (!isAdmin() && !isAdminReadOnly()) return null;

  return (
    <Card>
      <CardHeader className="d-flex justify-between align-center">
        <h3 className="mb-0">Carpeta </h3>

        <a
          className="ml-auto"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setForm({});
          }}
        >
          Agregar
        </a>
      </CardHeader>
      <CardBody>{renderAttachments()}</CardBody>

      {form ? (
        <Modal
          fade={false}
          isOpen={form}
          size="md"
          className="modal-dialog-centered modal-secondary"
        >
          <ModalHeader>Nuevo archivo</ModalHeader>

          <ModalBody className="py-0">
            <Card>
              <CardBody>
                <Form>
                  <AppInput
                    label="Nombre"
                    id="name"
                    name="name"
                    type="text"
                    value={form.name || ''}
                    error={errors.name}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                  <FormGroup>
                    <label
                      htmlFor="voucher"
                      className="form-control-label d-flex justify-content-between"
                    >
                      Archivo
                      {!file ? (
                        <Button
                          className="d-block"
                          size="sm"
                          color="primary"
                          outline
                          onClick={(e) => {
                            e.preventDefault();
                            rejectionCheckpointFileInputRef.current.click();
                          }}
                        >
                          CARGAR
                        </Button>
                      ) : (
                        <Button
                          className="d-block"
                          size="sm"
                          color="danger"
                          outline
                          onClick={(e) => {
                            e.preventDefault();
                            setFile(null);
                          }}
                        >
                          ELIMINAR
                        </Button>
                      )}
                    </label>

                    {file ? (
                      <span>{file.name}</span>
                    ) : (
                      <span className="text-muted">
                        No has adjuntado un archivo
                      </span>
                    )}

                    <input
                      ref={rejectionCheckpointFileInputRef}
                      hidden
                      type="file"
                      onChange={handleFileChange}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            {isSending ? (
              <Button
                className="new-event--add"
                color="primary"
                size="sm"
                type="button"
              >
                <Spinner size="sm" />
              </Button>
            ) : (
              <Button
                className="new-event--add"
                color="primary"
                size="sm"
                type="button"
                onClick={handleSave}
              >
                Guardar
              </Button>
            )}

            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => {
                setForm(null);
                setFile(null);
              }}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </Card>
  );
}

export default AppAttachments;
