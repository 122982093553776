import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/use-auth';

export function withAuth(Component) {
  return (props) => {
    const history = useHistory();
    const { user, isLoading } = useAuth();

    useEffect(() => {
      if (!user && !isLoading) history.push('/auth/ingresar');
    }, [user, isLoading]);

    return isLoading || !user ? null : <Component {...props} />;
  };
}

export default withAuth;
