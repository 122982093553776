import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from 'hooks/use-auth';
import { formatFilename } from 'utils';
import userAPI from 'services/api/user';
import fileAPI from 'services/api/file';
import AppUserList from 'components/Users/AppUserList';
import AppUserForm from 'components/Users/AppUserForm';

const itemsPerPage = 10;

function Users() {
  const [isLoading, setIsLoading] = useState(true);
  const auth = useAuth();
  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [user, setUser] = useState(null);
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(location.search).get('page')) - 1 || 0
  );
  const [count, setCount] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const results = await userAPI.getAll(0, 0, itemsPerPage, '');
        setUsers(results.users);
        setCount(results.count);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        /* handle error */
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!user) {
      setAvatar(null);
      return;
    }
    if (user._id === auth.user._id) auth.setUser(user);
    // if (user._id)
    //   setUsers(users.map((u) => (u._id === user._id ? { ...user } : u)));
    if (user.avatar) setAvatar(user.avatar);
  }, [auth, user, users]);

  const fetchUsers = async (valueToSearch, goToPage) => {
    try {
      const results = await userAPI.getAll(
        page,
        goToPage || 0,
        itemsPerPage,
        valueToSearch
      );
      setPage(goToPage || 0);
      setUsers(results.users);
      setCount(results.count);
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const handleUserClick = async (id) => {
    try {
      setUser(await userAPI.getOne(id));
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleClose = async () => {
    try {
      setUser(null);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    const fileName = formatFilename(file.name, 'avatars');

    try {
      const signedRequest = await fileAPI.signS3Upload(fileName, file.type);
      await fileAPI.uploadToS3(file, signedRequest);
      await fileAPI.create({
        key: fileName
      });
      await userAPI.update(user._id, { avatar: fileName });
      fileAPI.deleteOneByKey(user.avatar);
      setUser(await userAPI.getOne(user._id));
      setAvatar(fileName);
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const handleDocumentFieldChange = async (e, name) => {
    const file = e.target.files[0];
    const fileName = formatFilename(file.name, 'documents-user');

    try {
      const signedRequest = await fileAPI.signS3Upload(fileName, file.type);
      await fileAPI.uploadToS3(file, signedRequest);
      await fileAPI.create({
        key: fileName
      });

      await userAPI.update(user._id, {
        [name]: fileName
      });
      setUser(await userAPI.getOne(user._id));

      e.target.value = null;
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const handleDocumentDelete = async (name) => {
    try {
      await fileAPI.deleteOneByKey(user[name]);
      await userAPI.update(user._id, { [name]: '' });
      setUser({ ...user, [name]: '' });
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const handleSave = async (payload) => {
    try {
      if (user._id) {
        if (Object.entries(payload).length > 0) {
          await userAPI.update(user._id, payload);
          await fetchUsers();
          setUser(await userAPI.getOne(user._id));
        }
      } else {
        await userAPI.create(payload);
        await fetchUsers();
        setUser(null);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('¿Seguro quieres eliminar este usuario?')) return;
    if (!window.confirm('¡Después de este punto, no hay vuelta atrás!')) return;
    try {
      await userAPI.deleteOne(id);
      await fetchUsers();
      setPage(0);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleNewClick = () => {
    setUser({});
  };

  const renderView = () => {
    if (!user) {
      return (
        <AppUserList
          isLoading={isLoading}
          users={users}
          count={count}
          page={page}
          itemsPerPage={itemsPerPage}
          onSearch={fetchUsers}
          onPageClick={fetchUsers}
          onUserClick={handleUserClick}
          onDelete={handleDelete}
          onNewClick={handleNewClick}
        />
      );
    }

    return (
      <AppUserForm
        user={user}
        avatar={avatar}
        onClose={handleClose}
        onSave={handleSave}
        onDocumentFieldChange={handleDocumentFieldChange}
        onDocumentDelete={handleDocumentDelete}
        onAvatarChange={handleAvatarChange}
      />
    );
  };

  return renderView();
}

export default Users;
