import React, { useEffect, useState } from 'react';

import certificationAPI from 'services/api/certification';
import AppCertificationList from 'components/Certifications/AppCertificationList';
import AppCertificationForm from 'components/Certifications/AppCertificationForm';
import AppCertificationView from 'components/Certifications/AppCertificationView';

function Certifications({ location, history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [certifications, setCertifications] = useState([]);
  const [certification, setCertification] = useState(null);
  const [page, setPage] = useState(
    parseInt(new URLSearchParams(location.search).get('page')) - 1 || 0
  );
  const [count, setCount] = useState(0);
  const [isViewing, setIsViewing] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => fetchCertifications(), []);

  useEffect(() => {
    if (!new URLSearchParams(location.search).get('id')) {
      handleClose();
    }
  }, [location.search]);

  useEffect(() => {
    if (!certification) {
      return;
    }

    if (certification._id) {
      setCertifications(
        certifications.map((u) =>
          u._id === certification._id ? { ...certification } : u
        )
      );
      if (!new URLSearchParams(location.search).get('id')) {
        const params = new URLSearchParams();
        params.append('id', certification._id);
        history.push({ search: params.toString() });
      }
    }
  }, [certification]);

  const fetchCertifications = async (pageToGo, valueToSearch) => {
    setIsLoading(true);
    try {
      const { certifications, count } = await certificationAPI.getAll(
        pageToGo >= 0 ? pageToGo : page,
        itemsPerPage,
        valueToSearch && valueToSearch.length > 0 ? valueToSearch : ''
      );
      setCertifications(certifications);
      setCount(count);

      const userId = new URLSearchParams(location.search).get('id');
      if (userId && userId !== 'undefined') {
        setCertification(await certificationAPI.getOne(userId));
        setIsViewing(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      /* handle error */
      console.log(e);
    }
  };

  const handleSearch = (valueToSearch) => {
    fetchCertifications(0, valueToSearch);
    setPage(0);
  };

  const handlePageClick = (pageToGo, value) => {
    setPage(pageToGo);
    fetchCertifications(pageToGo, value);
  };

  const handleCertificationClick = async (id, toView) => {
    try {
      setCertification(await certificationAPI.getOne(id));
      setIsViewing(!!toView);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleClose = async () => {
    try {
      setCertification(null);
      setIsViewing(false);

      if (new URLSearchParams(location.search).get('id')) {
        const params = new URLSearchParams();
        params.delete('id');
        history.push({ search: params.toString() });
      }
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleSave = async (payload) => {
    try {
      if (certification._id) {
        await certificationAPI.update(certification._id, payload);
        // if (new URLSearchParams(location.search).get('id')) {
        //   const params = new URLSearchParams();
        //   params.delete('id');
        //   history.push({ search: params.toString() });
        // }
        // setCertification(await certificationAPI.getOne(certification._id));
        setIsViewing(false);
        // setCertification(null);
        setPage(0);
        await fetchCertifications(0);
      } else {
        await certificationAPI.create(payload);
        await fetchCertifications(0);
        setCertification(null);
        setPage(0);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('¿Seguro quieres eliminar esta certificación?')) return;
    if (!window.confirm('¡Después de este punto, no hay vuelta atrás!')) return;
    try {
      await certificationAPI.deleteOne(id);
      fetchCertifications(0);
      setPage(0);
    } catch (e) {
      /* handle error */
      console.log(e);
    }
  };

  const handleNewClick = () => {
    setCertification({});
  };

  const renderView = () => {
    if (!certification) {
      return (
        <AppCertificationList
          isLoading={isLoading}
          certifications={certifications}
          count={count}
          page={page}
          itemsPerPage={itemsPerPage}
          onSearch={handleSearch}
          onPageClick={handlePageClick}
          onCertificationClick={handleCertificationClick}
          onDelete={handleDelete}
          onNewClick={handleNewClick}
        />
      );
    }

    if (isViewing) {
      return (
        <AppCertificationView
          certification={certification}
          onClose={handleClose}
          onEditClick={handleCertificationClick}
          onProductsUpdate={fetchCertifications}
        />
      );
    }

    return (
      <AppCertificationForm
        certification={certification}
        onClose={handleClose}
        onSave={handleSave}
        onViewClick={handleCertificationClick}
      />
    );
  };

  return renderView();
}

export default Certifications;
