import React, { useEffect, useState } from 'react';
import validate from './validate';

const useForm = (
  callback,
  requiredValues = [],
  defaultValues = {},
  defaultErrors = {},
  defaultDirtyInputs = {}
) => {
  const [values, setValues] = useState(defaultValues);
  const [dirtyInputs, setDirtyInputs] = useState(defaultDirtyInputs);
  const [errors, setErrors] = useState(defaultErrors);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback(values);
      setIsSubmitting(false);
    }
  }, [callback, errors, isSubmitting, values]);

  useEffect(() => {
    setValues({ ...defaultValues });
  }, [Object.keys(defaultValues || {}).length]);

  const resetForm = () => {
    setValues(defaultValues);
    setDirtyInputs(defaultDirtyInputs);
    setErrors(defaultErrors);
    setIsSubmitting(false);
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values, requiredValues));
    setIsSubmitting(true);
  };

  const handleChange = (event, changeCallback) => {
    const target = event.target;
    event.persist();

    let tmpValue = target.value;

    if (target.name === 'phone' && tmpValue) {
      tmpValue = tmpValue.replace(/[^0-9]/g, '');

      if (tmpValue.length > 15) {
        tmpValue = values[target.name];
      }
    }

    const formErrors = validate(
      { ...values, [target.name]: tmpValue },
      requiredValues,
      target.name
    );

    const currentErrors = {
      ...errors,
      [target.name]: formErrors[target.name]
    };

    const currentValues = {
      ...values,
      [target.name]:
        target.type === 'checkbox' ? !values[target.name] : tmpValue
    };

    setValues(currentValues);

    if (dirtyInputs[target.name]) {
      setErrors(currentErrors);
      if (changeCallback) {
        changeCallback(currentValues);
      }
    } else if (changeCallback) {
      changeCallback(currentValues);
    }
  };

  const handleBlur = (event, blurCallback) => {
    const target = event.target;
    event.persist();

    const formErrors = validate(
      { ...values, [target.name]: target.value },
      requiredValues,
      target.name
    );
    const currentErrors = {
      ...errors,
      [target.name]: formErrors[target.name]
    };

    setErrors(currentErrors);
    if (values[target.name] !== defaultValues[target.name]) {
      setDirtyInputs({ ...dirtyInputs, [target.name]: true });
    } else {
      setDirtyInputs({ ...dirtyInputs, [target.name]: false });
    }
    if (blurCallback) {
      blurCallback();
    }
  };

  return {
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    values,
    dirtyInputs,
    errors
  };
};

export default useForm;
