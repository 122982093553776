/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from 'reactstrap';

import { useAuth } from 'hooks/use-auth';
import { getFileObject } from 'utils';
import moment from 'utils/moment';
import NotificationsAPI from 'services/api/notifications';

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState([]);

  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(function () {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove('g-navbar-search-shown');
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-show');
      document.body.classList.add('g-navbar-search-hiding');
    }, 150);
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hiding');
      document.body.classList.add('g-navbar-search-hidden');
    }, 300);
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hidden');
    }, 500);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setNotifications(await NotificationsAPI.getAll());
      } catch (e) {
        /* handle error */
      }
    }
    fetchData();
  }, []);

  const handleNotificationClick = async (e, notification) => {
    try {
      await NotificationsAPI.markAsRead(notification._id);
    } catch (e) {
      /* handle error */
    }

    if (!notification.url) {
      e.preventDefault();
    }
  };

  const handleNotificationCompletedClick = async (e, notification) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await NotificationsAPI.markAsCompleted(notification._id);
      setNotifications(await NotificationsAPI.getAll());
    } catch (e) {
      /* handle error */
    }

    if (!notification.url) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Navbar
        className={classnames(
          'navbar-top navbar-expand border-bottom',
          { 'navbar-dark bg-default': theme === 'dark' },
          { 'navbar-light bg-secondary': theme === 'light' }
        )}
      >
        <Container>
          <Collapse navbar isOpen={true}>
            {/* <Form */}
            {/*   className={classnames( */}
            {/*     'navbar-search form-inline mr-sm-3', */}
            {/*     { 'navbar-search-light': theme === 'dark' }, */}
            {/*     { 'navbar-search-dark': theme === 'light' } */}
            {/*   )} */}
            {/* > */}
            {/*   <FormGroup className="mb-0"> */}
            {/*     <InputGroup className="input-group-alternative input-group-merge"> */}
            {/*       <InputGroupAddon addonType="prepend"> */}
            {/*         <InputGroupText> */}
            {/*           <i className="fas fa-search" /> */}
            {/*         </InputGroupText> */}
            {/*       </InputGroupAddon> */}
            {/*       <Input placeholder="Search" type="text" /> */}
            {/*     </InputGroup> */}
            {/*   </FormGroup> */}
            {/*   <button */}
            {/*     aria-label="Close" */}
            {/*     className="close" */}
            {/*     type="button" */}
            {/*     onClick={closeSearch} */}
            {/*   > */}
            {/*     <span aria-hidden={true}>×</span> */}
            {/*   </button> */}
            {/* </Form> */}

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    'pr-3 sidenav-toggler',
                    { active: sidenavOpen },
                    { 'sidenav-toggler-dark': theme === 'dark' }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              {/* <NavItem className="d-sm-none"> */}
              {/*   <NavLink onClick={openSearch}> */}
              {/*     <i className="ni ni-zoom-split-in" /> */}
              {/*   </NavLink> */}
              {/* </NavItem> */}
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link" color="" tag="a">
                  <i className="ni ni-bell-55" />

                  {notifications.filter((n) => !n.readAt).length > 0 ? (
                    <div className="notifications-bell">
                      {notifications.filter((n) => !n.readAt).length}
                    </div>
                  ) : null}
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-xl py-0 overflow-hidden"
                  right
                >
                  {/* <div className="px-3 py-3"> 
                  {/*   <h6 className="text-sm text-muted m-0"> 
                  {/*     You have <strong className="text-info">13</strong>{' '} 
                  {/*     notifications. 
                  {/*   </h6> 
                  {/* </div> */}

                  <ListGroup
                    flush
                    style={{ maxHeight: '491px', overflowY: 'auto' }}
                  >
                    {notifications.map((n) => {
                      return (
                        <ListGroupItem
                          key={n._id}
                          style={{
                            background: n.readAt ? '#f0f0f0' : ''
                          }}
                          className="list-group-item-action"
                          href={n.url ? n.url : '#'}
                          onClick={(e) => handleNotificationClick(e, n)}
                          tag="a"
                        >
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <button
                                onClick={(e) =>
                                  handleNotificationCompletedClick(e, n)
                                }
                                className="notification__mark-as-complete"
                              >
                                <i className="fas fa-check-double"></i>
                              </button>
                            </Col>
                            <div className="col ml--3">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <h4 className="mb-0 text-sm">{n.kind}</h4>
                                </div>
                                <div className="text-right text-muted">
                                  <small>{moment(n.createdAt).fromNow()}</small>
                                </div>
                              </div>
                              <p className="text-sm mb-0">{n.message}</p>
                            </div>
                          </Row>
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>

                  {/* <DropdownItem */}
                  {/*   className="text-center text-info font-weight-bold py-3" */}
                  {/*   href="#pablo" */}
                  {/*   onClick={(e) => e.preventDefault()} */}
                  {/* > */}
                  {/*   View all */}
                  {/* </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
              {/* <UncontrolledDropdown nav> */}
              {/*   <DropdownToggle className="nav-link" color="" tag="a"> */}
              {/*     <i className="ni ni-ungroup" /> */}
              {/*   </DropdownToggle> */}
              {/*   <DropdownMenu */}
              {/*     className="dropdown-menu-lg dropdown-menu-dark bg-default" */}
              {/*     right */}
              {/*   > */}
              {/*     <Row className="shortcuts px-4"> */}
              {/*       <Col */}
              {/*         className="shortcut-item" */}
              {/*         href="#pablo" */}
              {/*         onClick={(e) => e.preventDefault()} */}
              {/*         xs="4" */}
              {/*         tag="a" */}
              {/*       > */}
              {/*         <span className="shortcut-media avatar rounded-circle bg-gradient-red"> */}
              {/*           <i className="ni ni-calendar-grid-58" /> */}
              {/*         </span> */}
              {/*         <small>Calendar</small> */}
              {/*       </Col> */}
              {/*       <Col */}
              {/*         className="shortcut-item" */}
              {/*         href="#pablo" */}
              {/*         onClick={(e) => e.preventDefault()} */}
              {/*         xs="4" */}
              {/*         tag="a" */}
              {/*       > */}
              {/*         <span className="shortcut-media avatar rounded-circle bg-gradient-orange"> */}
              {/*           <i className="ni ni-email-83" /> */}
              {/*         </span> */}
              {/*         <small>Email</small> */}
              {/*       </Col> */}
              {/*       <Col */}
              {/*         className="shortcut-item" */}
              {/*         href="#pablo" */}
              {/*         onClick={(e) => e.preventDefault()} */}
              {/*         xs="4" */}
              {/*         tag="a" */}
              {/*       > */}
              {/*         <span className="shortcut-media avatar rounded-circle bg-gradient-info"> */}
              {/*           <i className="ni ni-credit-card" /> */}
              {/*         </span> */}
              {/*         <small>Payments</small> */}
              {/*       </Col> */}
              {/*       <Col */}
              {/*         className="shortcut-item" */}
              {/*         href="#pablo" */}
              {/*         onClick={(e) => e.preventDefault()} */}
              {/*         xs="4" */}
              {/*         tag="a" */}
              {/*       > */}
              {/*         <span className="shortcut-media avatar rounded-circle bg-gradient-green"> */}
              {/*           <i className="ni ni-books" /> */}
              {/*         </span> */}
              {/*         <small>Reports</small> */}
              {/*       </Col> */}
              {/*       <Col */}
              {/*         className="shortcut-item" */}
              {/*         href="#pablo" */}
              {/*         onClick={(e) => e.preventDefault()} */}
              {/*         xs="4" */}
              {/*         tag="a" */}
              {/*       > */}
              {/*         <span className="shortcut-media avatar rounded-circle bg-gradient-purple"> */}
              {/*           <i className="ni ni-pin-3" /> */}
              {/*         </span> */}
              {/*         <small>Maps</small> */}
              {/*       </Col> */}
              {/*       <Col */}
              {/*         className="shortcut-item" */}
              {/*         href="#pablo" */}
              {/*         onClick={(e) => e.preventDefault()} */}
              {/*         xs="4" */}
              {/*         tag="a" */}
              {/*       > */}
              {/*         <span className="shortcut-media avatar rounded-circle bg-gradient-yellow"> */}
              {/*           <i className="ni ni-basket" /> */}
              {/*         </span> */}
              {/*         <small>Shop</small> */}
              {/*      </Col> */}
              {/*   </Row> */}
              {/*  </DropdownMenu> */}
              {/*   </UncontrolledDropdown> */}
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span
                      style={{ width: '36px', height: '36px' }}
                      className="avatar avatar-sm rounded-circle"
                    >
                      <img
                        style={{
                          objectFit: 'cover',
                          width: '100%',
                          height: '100%'
                        }}
                        draggable="false"
                        onMouseDown={() => false}
                        alt="..."
                        src={
                          user &&
                          user.avatar &&
                          user.avatar !== 'noProfileImage.jpg'
                            ? getFileObject(user.avatar)
                            : '/noProfileImage.jpg'
                        }
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold user-select-none">
                        {user.firstName}
                        {user.lastName ? ` ${user.lastName}` : ''}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Bienvenido!</h6>
                  </DropdownItem>
                  {/* <DropdownItem */}
                  {/*   href="#pablo" */}
                  {/*   onClick={(e) => e.preventDefault()} */}
                  {/* > */}
                  {/*   <i className="ni ni-single-02" /> */}
                  {/*   <span>My profile</span> */}
                  {/* </DropdownItem> */}
                  {/* <DropdownItem */}
                  {/*   href="#pablo" */}
                  {/*   onClick={(e) => e.preventDefault()} */}
                  {/* > */}
                  {/*   <i className="ni ni-settings-gear-65" /> */}
                  {/*   <span>Settings</span> */}
                  {/* </DropdownItem> */}
                  {/* <DropdownItem */}
                  {/*   href="#pablo" */}
                  {/*   onClick={(e) => e.preventDefault()} */}
                  {/* > */}
                  {/*   <i className="ni ni-calendar-grid-58" /> */}
                  {/*   <span>Activity</span> */}
                  {/* </DropdownItem> */}
                  <DropdownItem href="mailto:seersolar@squiresoft.io">
                    <i className="ni ni-support-16" />
                    <span>Soporte</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    href={
                      process.env.NODE_ENV === 'development'
                        ? 'http://localhost:5000/api/signout'
                        : '/api/signout'
                    }
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark'
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light'])
};

export default AdminNavbar;
