import axios, { requestToken } from '../../utils/axios';
import toast from 'react-hot-toast';

const getAll = async (page, itemsPerPage, value, status) => {
  try {
    const response = await axios.get(
      `/api/projects?search=${JSON.stringify({
        page,
        itemsPerPage,
        value,
        status
      })}`,
      {
        cancelToken: requestToken()
      }
    );
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const getOne = async (id) => {
  try {
    const response = await axios.get(`/api/projects/${id}`);
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const create = async (payload) => {
  try {
    const response = await axios.post('/api/project-submissions', payload);
    toast.success(response.data.message, {
      style: {
        animation: 'none'
      }
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const update = async (id, payload) => {
  try {
    const response = await axios.patch(`/api/projects/${id}`, payload);
    toast.success(response.data.message, {
      style: {
        animation: 'none'
      }
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

const deleteOne = async (id) => {
  try {
    const response = await axios.delete(`/api/projects/${id}`);
    toast.success(response.data.message, {
      style: {
        animation: 'none'
      }
    });
    return response.data;
  } catch (e) {
    if (e && e.response && e.response.data) {
      if (e.response.data.errors) {
        e.response.data.errors.forEach((err) => {
          toast.error(err.msg, {
            style: {
              animation: 'none'
            }
          });
        });
      }

      if (e.response.data.message) {
        toast.error(e.response.data.message, {
          style: {
            animation: 'none'
          }
        });
      }
      return Promise.reject(e.response.data);
    }
    return Promise.reject(e);
  }
};

export default {
  getAll,
  getOne,
  create,
  update,
  deleteOne
};
