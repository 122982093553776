import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  ListGroupItem,
  ListGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col
} from 'reactstrap';
import html2canvas from 'html2canvas';

import { getName, formatFilename, getFileObject } from 'utils';
import { CustomerKind } from '../../constants';
import AccountsReceivableAPI from 'services/api/accountReceivable';
import customerAPI from 'services/api/customer';
import fileAPI from 'services/api/file';
import AlternativeHeader from 'components/Headers/AlternativeHeader';
import AppActivity from 'components/Activities/AppActivity';
import AppNotes from 'components/Notes/AppNotes';
import AppProjectCreateForm from 'components/Projects/AppProjectCreateForm';

function AppCustomerView({
  customer,
  onEditClick,
  onDocumentFieldChange,
  onDocumentDelete,
  onClose
}) {
  const history = useHistory();
  const documentFieldRef = useRef(null);

  const signatureCanvasRef = useRef(null);
  const signatureRef = useRef(null);

  const sealCanvasRef = useRef(null);
  const sealRef = useRef(null);

  const [generatedBase64, setGeneratedBase64] = useState(false);
  const [generatedSealBase64, setGeneratedSealBase64] = useState(false);

  const [documentToUploadName, setDocumentToUploadName] = useState('');

  const [imageSlideSize, setImageSlideSize] = useState(160);
  const [sealImageSlideSize, setSealImageSlideSize] = useState(160);

  const [initProject, setInitProject] = useState(false);

  const [isInitAccountReceivableLoading, setIsInitAccountReceivableLoading] =
    useState(false);
  const [initAccountReceivableModal, setInitAccountReceivableModal] =
    useState(false);

  const generateBase64 = async () => {
    try {
      if (
        customer.fileCustomerSignature &&
        signatureRef.current.complete &&
        signatureRef.current.naturalHeight !== 0 &&
        !generatedBase64
      ) {
        const imgCanvas = document.createElement('canvas');
        imgCanvas.width = signatureRef.current.width;
        imgCanvas.height = signatureRef.current.height;
        const ctx = imgCanvas.getContext('2d');
        ctx.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
        ctx.drawImage(signatureRef.current, 0, 0);
        signatureRef.current.src = imgCanvas.toDataURL('image/png');
        setGeneratedBase64(true);
      } else if (!generatedBase64) {
        setTimeout(generateBase64, 100);
      }
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const generateSealBase64 = async () => {
    console.log('hi');
    try {
      if (
        customer.fileCompanyStamp &&
        sealRef.current.complete &&
        sealRef.current.naturalHeight !== 0 &&
        !generatedSealBase64
      ) {
        const sealImgCanvas = document.createElement('canvas');
        sealImgCanvas.width = sealRef.current.width;
        sealImgCanvas.height = sealRef.current.height;
        const ctx = sealImgCanvas.getContext('2d');
        ctx.clearRect(0, 0, sealImgCanvas.width, sealImgCanvas.height);
        ctx.drawImage(sealRef.current, 0, 0);
        sealRef.current.src = sealImgCanvas.toDataURL('image/png');
        setGeneratedSealBase64(true);
      } else if (!generatedSealBase64) {
        setTimeout(generateSealBase64, 100);
      }
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  useEffect(() => {
    if (customer.fileCustomerSignature) {
      generateBase64();
    }
  }, [customer.fileCustomerSignature]);

  useEffect(() => {
    if (customer.fileCompanyStamp) {
      generateSealBase64();
    }
  }, [customer.fileCompanyStamp]);

  const handleLoadAccountReceivable = async (e) => {
    e.preventDefault();
    try {
      const result = await AccountsReceivableAPI.getByCustomer(customer._id);
      if (result) {
        history.push(`/cuentas-por-cobrar/${result._id}`);
        return;
      }
      setInitAccountReceivableModal(true);
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const handleCreateAccountReceivable = async (e) => {
    e.preventDefault();
    try {
      const result = await AccountsReceivableAPI.create({
        customer: customer._id
      });
      history.push(`/cuentas-por-cobrar/${result.id}`);
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const handleProjectCreated = async (projectId) => {
    history.push(`/proyectos?id=${projectId}`);
  };

  const initDocumentUpload = (name) => {
    setGeneratedBase64(false);
    setGeneratedSealBase64(false);
    setDocumentToUploadName(name);
    documentFieldRef.current.click();
  };

  const handleDocumentFieldChange = async (e) => {
    try {
      await onDocumentFieldChange(e, documentToUploadName);
      setDocumentToUploadName('');
    } catch (e) {
      console.log(e);
      /* handle error */
    }
  };

  const changeImageSizeWithSlider = (sideLength, elClass) => {
    var sideLengthStr = sideLength + 'px';
    var imageBoxes = document.getElementsByClassName(elClass);

    for (var i = 0; i < imageBoxes.length; i++) {
      imageBoxes[i].style.width = sideLengthStr;
    }

    if (elClass === 'seal_image_thumbnail') {
      setSealImageSlideSize(sideLength);
    } else {
      setImageSlideSize(sideLength);
    }
  };

  const saveImage = async (e, customerFileName) => {
    e.preventDefault();
    const currentFileName = customer[customerFileName];
    let currentCanvasRef = null;

    if (customerFileName === 'fileCustomerSignature') {
      currentCanvasRef = signatureCanvasRef;
    } else {
      currentCanvasRef = sealCanvasRef;
    }

    try {
      currentCanvasRef.current.style.border = 'none';
      currentCanvasRef.current.style.backgroundColor = 'transparent';
      const canvas = await html2canvas(currentCanvasRef.current, {
        backgroundColor: null
      });
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const file = await new Promise((resolve) =>
        canvas.toBlob(resolve, 'image/png')
      );

      const fileName = formatFilename(
        customer[customerFileName].replace('documents-customer', ''),
        'documents-customer'
      );

      const signedRequest = await fileAPI.signS3Upload(fileName, 'image/png');
      await fileAPI.uploadToS3(file, signedRequest);
      await fileAPI.create({
        key: fileName
      });

      await customerAPI.update(customer._id, {
        [customerFileName]: fileName
      });

      await fileAPI.deleteOneByKey(currentFileName);
    } catch (e) {
      /* handle error */
    }
  };

  const renderDocumentsItems = () => {
    let items = [
      {
        label: 'Cédula/Pasaporte',
        icon: 'fas fa-id-card',
        name: 'fileIdenticiation'
      },
      {
        label: 'Firma',
        icon: 'fas fa-signature',
        name: 'fileCustomerSignature'
      }
    ];

    if (customer.kind === CustomerKind.Company) {
      items = [
        ...items,
        {
          label: 'Registro Mercantil',
          icon: 'far fa-registered',
          name: 'fileMerchantRegistry'
        },
        {
          label: 'Acta de Asamblea',
          icon: 'fas fa-handshake',
          name: 'filePactAssembly'
        },
        { label: 'Sello', icon: 'fas fa-stamp', name: 'fileCompanyStamp' }
      ];
    }

    return items.map((i) => (
      <ListGroupItem key={i.name} className="px-0">
        <Row className="align-items-center">
          <Col className="col-auto">
            <a
              onClick={(e) => {
                if (!customer[i.name]) e.preventDefault();
              }}
              style={{ height: '32px', width: '32px' }}
              href={customer[i.name] ? getFileObject(customer[i.name]) : '#'}
              target="_blank"
              className={`d-block ${
                customer[i.name] ? 'bg-primary' : 'bg-gray'
              } text-white d-flex justify-content-center align-items-center rounded`}
            >
              <i className={i.icon}></i>
            </a>
          </Col>
          <div className="col">
            <a
              onClick={(e) => {
                if (!customer[i.name]) e.preventDefault();
              }}
              style={{ height: '32px', width: '32px' }}
              href={customer[i.name] ? getFileObject(customer[i.name]) : '#'}
              target="_blank"
            >
              <h5 className="mb-0">{i.label}</h5>
            </a>
          </div>
          <div className="col">
            {customer[i.name] ? (
              <Button
                color="warning"
                size="sm"
                className="d-block ml-auto"
                onClick={(e) => {
                  if (
                    !window.confirm(
                      '¿Seguro que quieres eliminar este documento?'
                    )
                  )
                    return;
                  e.preventDefault();
                  onDocumentDelete(i.name);
                }}
              >
                <i className="fas fa-trash"></i>
              </Button>
            ) : (
              <Button
                color="default"
                size="sm"
                className="d-block ml-auto"
                onClick={(e) => {
                  e.preventDefault();
                  initDocumentUpload(i.name);
                }}
              >
                <i className="fas fa-upload"></i>
              </Button>
            )}
          </div>
        </Row>
      </ListGroupItem>
    ));
  };

  return (
    <>
      <AlternativeHeader
        name={getName(customer)}
        parentName="Clientes"
        onBackClick={onClose}
        onNewLabel="Editar"
        onNewClick={() => onEditClick(customer._id)}
      />

      {initProject ? (
        <AppProjectCreateForm
          show={initProject}
          customerId={customer._id}
          onClose={() => {
            setInitProject(false);
          }}
          onSuccess={handleProjectCreated}
        />
      ) : null}

      <Modal
        fade={false}
        isOpen={initAccountReceivableModal}
        toggle={() => setInitAccountReceivableModal(false)}
        className="modal-dialog-centered modal-secondary"
      >
        <ModalHeader>
          <h6 className="modal-title" id="modal-title-default">
            {customer.firstName} no tiene cuenta por cobrar
          </h6>
        </ModalHeader>

        <ModalBody>
          <p>¿Esté cliente tiene facturas con balance pendiente?</p>
        </ModalBody>

        <ModalFooter>
          {isInitAccountReceivableLoading ? (
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              disabled
            >
              <Spinner size="sm" />
            </Button>
          ) : (
            <Button
              className="new-event--add"
              color="success"
              type="button"
              onClick={handleCreateAccountReceivable}
            >
              Si
            </Button>
          )}

          <Button
            className="new-event--add"
            color="transparent"
            type="button"
            onClick={() => setInitAccountReceivableModal(false)}
          >
            No
          </Button>

          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => setInitAccountReceivableModal(false)}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Container fluid className="mt--6">
        <Row>
          <Col className="order-lg-2" lg="4">
            <Card className="card-profile">
              <CardHeader className="text-center border-0 pt-4 pb-0">
                <div className="d-flex justify-content-between">
                  <Button
                    tag="a"
                    color="primary"
                    href={`/proyectos?v=${getName(customer)}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`/proyectos?v=${getName(customer)}`);
                    }}
                    size="sm"
                  >
                    Proyectos
                  </Button>

                  <Button
                    color="danger"
                    onClick={handleLoadAccountReceivable}
                    size="sm"
                  >
                    Por cobrar
                  </Button>
                </div>
              </CardHeader>

              <CardBody>
                <div className="text-center mb-3">
                  {customer.alias ? (
                    <h5 className="text-gray">{customer.alias}</h5>
                  ) : null}

                  <h5 className="h3">
                    {getName(customer)}
                    <span className="font-weight-light">
                      {customer.birthDay
                        ? `, ${moment().diff(customer.birthDay, 'years')}`
                        : null}
                    </span>
                  </h5>
                </div>

                <h5>Contacto</h5>
                <ul className="customer__info-list">
                  <li>
                    <i className="fas fa-envelope"></i>
                    {customer.email ? (
                      <a href={`mailto:${customer.email}`}>{customer.email}</a>
                    ) : (
                      '-'
                    )}
                  </li>
                  {customer.phone ? (
                    <li>
                      <i className="fas fa-phone"></i>
                      <a href={`tel:${customer.phone}`}>{customer.phone}</a>
                      <span className="ml-1">- {customer.phoneAlias}</span>
                    </li>
                  ) : (
                    <li>
                      <i className="fas fa-phone"></i> -
                    </li>
                  )}
                  {customer.phones.map((p, pIndex) => (
                    <li key={`phones-item-${pIndex}`}>
                      <i className="fas fa-phone"></i>
                      {p.phone ? <a href={`tel:${p.phone}`}>{p.phone}</a> : ''}
                      <span className="ml-1">- {p.label}</span>
                    </li>
                  ))}
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                    {customer.address || '-'}
                  </li>
                </ul>

                <h5>Información</h5>
                <ul className="customer__info-list">
                  <li>
                    <i className="fas fa-user-tag"></i>
                    {customer.kind || '-'}
                  </li>
                  <li>
                    <i className="fas fa-id-card"></i>
                    {customer.identification || '-'}
                  </li>
                  {customer.kind === CustomerKind.Company ? (
                    <li>
                      <i className="fas fa-landmark"></i>
                      {customer.rnc || '-'}
                    </li>
                  ) : null}
                </ul>

                {customer.position ? (
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Solution Manager - Creative Tim Officer
                  </div>
                ) : null}

                {customer.company ? (
                  <div>
                    <i className="ni education_hat mr-2" />
                    University of Computer Science
                  </div>
                ) : null}
              </CardBody>
            </Card>

            <Card className="card-profile">
              <CardHeader>
                <h5 className="h3 mb-0">Tareas</h5>
              </CardHeader>

              <CardBody>
                <ul className="list-unstyled">
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setInitProject(true);
                      }}
                    >
                      Crear proyecto
                    </Link>
                  </li>
                </ul>
              </CardBody>
            </Card>

            <AppNotes resourceId={customer._id} />
          </Col>

          <Col className="order-lg-1" lg="8">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Documentos</h3>
              </CardHeader>
              <CardBody>
                <input
                  hidden
                  ref={documentFieldRef}
                  type="file"
                  onChange={handleDocumentFieldChange}
                />
                <ListGroup className="list" flush>
                  {renderDocumentsItems()}
                </ListGroup>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h3 className="mb-0">Manipular documentos</h3>
              </CardHeader>
              <CardBody>
                <a
                  href="#"
                  download="example.png"
                  onClick={(e) => saveImage(e, 'fileCustomerSignature')}
                >
                  Actualizar firma
                </a>
                <br />
                <input
                  type="range"
                  min="50"
                  max="500"
                  value={imageSlideSize}
                  className="slider"
                  id="range-image-size"
                  onChange={(e) =>
                    changeImageSizeWithSlider(e.target.value, 'image_thumbnail')
                  }
                />{' '}
                <br />
                <div ref={signatureCanvasRef} className="canvas-image-box">
                  <img
                    crossOrigin="anonymous"
                    ref={signatureRef}
                    src={getFileObject(customer.fileCustomerSignature)}
                    className="image_thumbnail"
                  />
                </div>
              </CardBody>

              <CardBody>
                <a
                  href="#"
                  download="example.png"
                  onClick={(e) => saveImage(e, 'fileCompanyStamp')}
                >
                  Actualizar Sello
                </a>
                <br />
                <input
                  type="range"
                  min="50"
                  max="500"
                  value={sealImageSlideSize}
                  className="slider"
                  id="range-seal-image-size"
                  onChange={(e) =>
                    changeImageSizeWithSlider(
                      e.target.value,
                      'seal_image_thumbnail'
                    )
                  }
                />{' '}
                <br />
                <div ref={sealCanvasRef} className="canvas-seal-image-box">
                  <img
                    crossOrigin="anonymous"
                    ref={sealRef}
                    src={getFileObject(customer.fileCompanyStamp)}
                    className="seal_image_thumbnail"
                  />
                </div>
              </CardBody>
            </Card>

            <AppActivity resourceId={customer._id} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AppCustomerView;
