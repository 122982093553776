import React, { useEffect, useRef, useState } from 'react';
import {
  FormGroup,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Row,
  Col
} from 'reactstrap';

import { displayAmount, formatFilename, moment } from 'utils';
import AccountsReceivableAPI from 'services/api/accountReceivable';
import FileAPI from 'services/api/file';
import { TransactionKind } from '../../constants';
import AppCurrencyInput from 'components/Forms/AppCurrencyInput';
import AppInput from 'components/Forms/AppInput';

function AppARCreditNoteForm({
  accountReceivableId,
  milestone,
  transactions,
  isActive,
  onClose,
  onSuccess
}) {
  const [form, setForm] = useState({
    amount: '0.00',
    date: '',
    note: '',
    fileVoucher: null
  });
  const [errors, setErrors] = useState({});
  const [voucherFile, setVoucherFile] = useState(null);
  const voucherInputRef = useRef(null);
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (v) => {
    if (isSending) return;
    setIsSending(true);

    let newErrors = {};

    if (!form.date) {
      newErrors.date = 'Es obligatorio';
    }

    if (!getAmount()) {
      newErrors.amount = 'Debe ser mayor que 0';
    } else if (getNewPendingAmount() < 0) {
      newErrors.amount = 'Debe ser menor que el balance pendiente';
    }

    if (!voucherFile) {
      alert('Debes adjuntar un voucher');
      newErrors.voucherFile = true;
    }

    if (Object.entries(newErrors).length > 0) {
      setIsSending(false);
      setErrors(newErrors);
      return;
    }

    const tmpForm = {};

    try {
      const voucherFileName = formatFilename(voucherFile.name, 'credit-notes');
      const voucherSignedRequest = await FileAPI.signS3Upload(
        voucherFileName,
        voucherFile.type
      );
      await FileAPI.uploadToS3(voucherFile, voucherSignedRequest);
      await FileAPI.create({
        key: voucherFileName
      });
      tmpForm.fileVoucher = voucherFileName;

      await AccountsReceivableAPI.createTransaction(
        accountReceivableId,
        tmpForm
      );

      setIsSending(false);
      setForm({
        amount: '0.00',
        date: '',
        fileVoucher: null
      });
      setVoucherFile(null);
      onSuccess();
    } catch (e) {
      if (tmpForm.fileVoucher) {
        FileAPI.deleteOneByKey(tmpForm.fileVoucher);
      }

      setIsSending(false);
    }
  };

  const handleVoucherFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVoucherFile(file);
      e.target.value = null;
    }
  };

  const getAmount = () => {
    if (!form.amount) return 0.0;
    return parseFloat(form.amount.replace(/[^0-9.]/g, ''));
  };

  const getPendingAmount = () => {
    let amount = 0;

    for (let i = 0, len = transactions.length; i < len; i++) {
      if (transactions[i].invoice === milestone._id) {
        amount += transactions[i].total;
      }
    }

    return (milestone.total - amount) / 100;
  };

  const getNewPendingAmount = () => {
    if (!form.amount) return getPendingAmount();
    return parseFloat(getPendingAmount()) - getAmount();
  };

  const isExpiredMilestone = () => {
    return moment(milestone.date).isSameOrBefore(moment().startOf('day'));
  };

  const renderView = () => {
    if (!milestone) return null;

    return (
      <Modal
        fade={false}
        isOpen={isActive}
        size="md"
        className="modal-dialog-centered modal-secondary"
      >
        <ModalHeader>Notificar</ModalHeader>

        <ModalBody className="py-0">
          <ul>
            {isExpiredMilestone() ? (
              <li>
                <Button
                  color="default"
                  size="md"
                  className="w-100 d-block text-left"
                >
                  Fecha de pago vencida
                </Button>
              </li>
            ) : (
              <li>
                <Button
                  color="default"
                  size="md"
                  className="w-100 d-block text-left"
                >
                  Fecha próximo a vencer
                </Button>
              </li>
            )}
          </ul>
        </ModalBody>

        <ModalFooter>
          {/* {isSending ? ( */}
          {/*   <Button */}
          {/*     className="new-event--add" */}
          {/*     color="primary" */}
          {/*     type="button" */}
          {/*     disabled */}
          {/*   > */}
          {/*     <Spinner size="sm" /> */}
          {/*   </Button> */}
          {/* ) : ( */}
          {/*   <Button */}
          {/*     className="new-event--add" */}
          {/*     color="success" */}
          {/*     type="button" */}
          {/*     onClick={handleSubmit} */}
          {/*   > */}
          {/*     Guardar */}
          {/*   </Button> */}
          {/* )} */}

          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => {
              setForm({
                amount: '0.00',
                date: '',
                note: '',
                fileVoucher: null
              });
              setErrors({});
              setVoucherFile(null);
              onClose();
            }}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return renderView();
}

export default AppARCreditNoteForm;
